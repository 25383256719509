import React, {useEffect} from "react";

import { registerScrollEvent, onScroll } from "../../redux/animations/Action.js";
import { useDispatch, useSelector } from "react-redux";
import { userIsLoggedIn } from "../../utils.js";
import { Advertiser } from "./Advertiser.js"
import {FEATURE_ANALYTICS_FRONTEND} from "../../utils/Constants.js";
import Dashboard from "./Dashboard.js"
import Analytics from "../Analytics/index.js";
import { isEmpty } from "weed-js";

const Home = () => {
    const loggedInUser = useSelector(state => state.userReducer.loggedInUser.user);
    const dispatch = useDispatch();

    useEffect(() => {
        if (isEmpty(document)) {
            return;
        }
        const script = document.createElement("script");
        script.src = "https://js.stripe.com/v3/pricing-table.js";
        script.async = true;

        document.body.appendChild(script);
        dispatch(registerScrollEvent());
           
        window.addEventListener("scroll", onScroll);

        return () => {
            document.body.removeChild(script);
            window.removeEventListener("scroll", onScroll);
        }
    }, [true]);


    const features = isEmpty(loggedInUser) || isEmpty(loggedInUser.features) ? new Set() : new Set(loggedInUser.features);
    const frontend = features.has(FEATURE_ANALYTICS_FRONTEND) ? <Analytics/> : <Dashboard/>;

    return (<> 
        {userIsLoggedIn(loggedInUser) ? frontend : <Advertiser/>}
    </>);
}

export default Home;