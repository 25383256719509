import React from "react";
import {Line} from "react-chartjs-2";
import {Chart as ChartJS, registerables} from "chart.js";


ChartJS.register(...registerables);


export const options = {
    responsive: true,
    plugins: {
        legend: {
            position: "top"
        },
        title: {
            display: true,
            text: "Probability of a Churn (at 97% confidence)",
        },
    },
    scales: {
        y: {
            title: {
                text: "Probability [0, 1]",
                display: true
            }
        }
    }
};

const labels = ["Jan 1", "Jan 5", "Jan 10", "Jan 15", "Jan 20", "Jan 25", "Jan 30", "Feb 4"];

const ayValues = labels.map((label, idx) => {
    return {
        x: idx, y: idx * Math.random() / labels.length
    }
});
const byValues = labels.map((label, idx) => {
    return {
        x: idx, y: idx * Math.random() / labels.length
    }
});
const cyValues = labels.map((label, idx) => {
    return {
        x: idx, y: idx * Math.random() / labels.length
    }
});
const dyValues = labels.map((label, idx) => {
    return {
        x: idx, y: idx * Math.random() / labels.length
    }
});
const eyValues = labels.map((label, idx) => {
    return {
        x: idx, y: idx * Math.random() / labels.length
    }
});

export const data = {
    labels,
    datasets: [
        {
            label: "Customer 13579",
            data: ayValues,
            borderColor: 'rgb(255, 99, 132)',
            backgroundColor: 'rgb(255, 99, 132, .5)',
        },
        {
            label: "Customer 75913",
            data: byValues,
            borderColor: 'rgb(50, 20, 130)',
            backgroundColor: 'rgb(50, 20, 130, .5)',
        },
        {
            label: "Customer 00113",
            data: cyValues,
            borderColor: 'rgb(255, 0, 0)',
            backgroundColor: 'rgba(255, 0, 0, 0.5)',
        },
        {
            label: "Customer 88721",
            data: dyValues,
            borderColor: 'rgb(0, 255, 0)',
            backgroundColor: 'rgba(0, 255, 0, 0.5)',
        },
        {
            label: "Customer 13579",
            data: eyValues,
            borderColor: 'rgb(0, 0, 255)',
            backgroundColor: 'rgba(0, 0, 255, 0.5)'
        },
    ],
};


export const Churn = () => {
    return <div className={"chart"}>
        <div className={"churn"}>
            <Line options={options} data={data}/>
        </div>
        <div className={"chart-description"}>
            <h1>Customer Churn Probability</h1>
            <p>Our models predict customer churn with a 96% rate of true positives, and 4% false positives.</p>
            <p>This allows you to optimize your promotion campaigns, targeting your customers that are most likely to churn, with a waste of only ~4%.</p>
            <div className={"blue-dot-5"}></div>
            <div className={"orange-dot-5"}></div>
        </div>
    </div>;

}
