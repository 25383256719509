import React from "react";
import { FormControl, FormHelperText, InputLabel, MenuItem, Select } from "@mui/material";

const Dropdown = ({
    id, label, options, value, errors, style, onChange = (value) => {
    }, disabled
}) => {
    return <FormControl fullWidth={true} error={errors[id] !== "" && errors[id] !== undefined}>
        <InputLabel id={`${label}-${id}`}>{label}</InputLabel>
        <Select
            native={true}
            variant={"filled"}
            className={"canold-dropdown"}
            labelId={`${label}-${id}`}
            value={value}
            style={style}
            id={id}
            label={label}
            name={id}
            disabled={disabled}
            onChange={(change) => {
                onChange(change.target.value);
            }}>
            {options.map(option => {
                return <option
                    key={`${label}-${option.label}-${option.value}`}
                    value={option.value}>
                    {option.label}
                </option>;
            })}
        </Select>
        <FormHelperText>{errors[id]}</FormHelperText>
    </FormControl>
}

export default Dropdown;
