import React, {useState} from "react";

import {Grid, Tab, Tabs} from "@mui/material";
import RetailCostAndMargin from "../../../../components/reports/home/RetailCostAndMargin";
import {
    RETAIL_PROCUREMENT,
    RETAIL_COST_AND_MARGIN,
    RETAIL_TABS, ROUTES, TABS,
    RETAIL_EMPLOYEES,
} from "../../constants";
import {useNavigate, useSearchParams} from "react-router-dom";
import {getCookie, isEmpty} from "weed-js";


export const Retail = ({tab, startDate, endDate}) => {
    const [searchParams, setSearchParams] = useSearchParams();
    const whichTab = searchParams.get("tab");
    const [selectedTab, setSelectedTab] = useState(RETAIL_TABS[isEmpty(tab) ? whichTab : tab]);
    const navigate = useNavigate();
    const organizationId = getCookie("organizationId");

    const TabPanel = ({children, index}) => {
        if (index === selectedTab) {
            return <>{children}</>
        }
        return ""
    }
    const selectTab = (event, newValue) => {
        setSelectedTab(newValue);
        navigate(ROUTES[parseInt(newValue)].replace(":id", organizationId));
    }

    return <Grid container spacing={12} className={"report-body"}>
        <Grid item xs={12}>
            <Tabs value={selectedTab} onChange={selectTab}>
                <Tab label={RETAIL_COST_AND_MARGIN} value={RETAIL_TABS[RETAIL_COST_AND_MARGIN]} tabIndex={RETAIL_TABS[RETAIL_COST_AND_MARGIN]} id={"retail-cost-and-margin-tab"}/>
                <Tab label={RETAIL_PROCUREMENT} value={RETAIL_TABS[RETAIL_PROCUREMENT]} tabIndex={RETAIL_TABS[RETAIL_PROCUREMENT]} id={"retail-procurement-tab"}/>
                <Tab disabled={true} label={RETAIL_EMPLOYEES} value={RETAIL_TABS[RETAIL_EMPLOYEES]} tabIndex={RETAIL_TABS[RETAIL_EMPLOYEES]} id={"retail-employees-tab"}/>
            </Tabs>
            <TabPanel index={RETAIL_TABS[RETAIL_COST_AND_MARGIN]}>
                <RetailCostAndMargin organizationId={organizationId} startDate={startDate} endDate={endDate}/>
            </TabPanel>
            <TabPanel index={RETAIL_TABS[RETAIL_PROCUREMENT]}>
                Procurement
            </TabPanel>
            <TabPanel index={RETAIL_TABS[RETAIL_EMPLOYEES]}>
                Employees
            </TabPanel>
        </Grid>
    </Grid>
}

export default Retail;