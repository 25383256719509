import React, { useState, useEffect } from 'react';
import { useNavigate, Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { isNullOrUndefinedOrEmpty, userIsNotLoggedIn } from "../../../utils.js";
import { TableHead, TableCell, TableRow, TableContainer, Table, TableBody, Container } from "@mui/material";
import { fetchAlpineIQAccounts } from "../../../redux/models/integrations/Action.js";
import { selectOrganization } from "../../../redux/models/organizations/Action.js";


const List = () => {
    const abortController = new AbortController();

    const [alpineIQAccounts, setAlpineIQAccounts] = useState([]);

    const loggedInUser = useSelector(state => state.userReducer.loggedInUser.user);
    const organizations = useSelector(state => state.userReducer.loggedInUser.organizations);
    const selectedOrganization = useSelector(state => state.organizationReducer.selectedOrganization);

    const dispatch = useDispatch();
    const navigate = useNavigate();

    const maybeSwitchOrganization = (organization) => {
        if (selectedOrganization.id !== organization.id) {
            dispatch(selectOrganization(organization));
        }
    };

    useEffect(() => {
        if (!isNullOrUndefinedOrEmpty(organizations)) {
            dispatch(fetchAlpineIQAccounts(abortController)).then((fetchedAlpineIQAccounts) => {
                setAlpineIQAccounts(fetchedAlpineIQAccounts);
            });
            return () => {
                abortController.abort();
            }
        }
    }, [loggedInUser.id, organizations]);

    if (userIsNotLoggedIn(loggedInUser)) {
        navigate("/login");
        return "User is not logged in.";
    }

    const noIntegrations = <Link to="/integrations/alpineiq/setup">
        You have no AlpineIQ integrations. Go to Integrations, then Setup to add some or click here.
    </Link>;

    return <Container className={"workspace"}>
        <h1>Manage AlpineIQ Integrations</h1>
        <TableContainer>
            <Table>
                <TableHead>
                    <TableRow>
                        <TableCell>Organization Name</TableCell>
                        <TableCell>AlpineIQ Account Name</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {
                        isNullOrUndefinedOrEmpty(alpineIQAccounts) ? noIntegrations : alpineIQAccounts.sort((a, b) => {
                            return a.display_name.localeCompare(b.display_name);
                        }).map((alpineIQAccount) => {
                            const organization = organizations.find(o => alpineIQAccount.organization_id === o.id)
                            if (isNullOrUndefinedOrEmpty(organization)) {
                                return ""
                            }
                            return <TableRow key={alpineIQAccount.id}>
                                <TableCell>
                                    <Link to={`/integrations/alpineiq/${alpineIQAccount.id}/detail`} onClick={() => {
                                        maybeSwitchOrganization(organization);
                                    }}>
                                        {organization.name}
                                    </Link>
                                </TableCell>
                                <TableCell>
                                    <Link to={`/integrations/alpineiq/${alpineIQAccount.id}/detail`} onClick={() => {
                                        maybeSwitchOrganization(organization);
                                    }}>
                                        {alpineIQAccount.display_name}
                                    </Link>
                                </TableCell>
                            </TableRow>;
                        })
                    }
                </TableBody>
            </Table>
        </TableContainer>
    </Container>
};

export default List;