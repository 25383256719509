import {isEmpty} from "weed-js";

export class Partition {
    /*
    The Partition manages blocks of requested data so we don't have to make requests for subsets of data
    we've already requested.

    So if we send one query for e.g.

    1) 2024-01-01 until 2024-01-10 and another for
    2) 2024-01-21 until 2024-01-23

    When we query for the range
    3) 2024-01-01 until 2024-01-31

    There is no need to query for 1) and 2) again. A partition is created for each unique .fetch() query
    required to satisfy the users needs. Given a new query; we go through all the partitions and calculate
    the query with the least overhead to get the results we're looking for.

     */
    constructor(startDate, endDate, params) {
        this.startDate = startDate;
        this.endDate = endDate;
        this.params = params
    }


    optimize(query) {

    }
}


export class Fetcher {
    constructor(report, service, uri) {
        if(isEmpty(service)) {
            throw new Error(`${report.title}: Each report must have a service`);
        }
        if(isEmpty(uri)) {
            throw new Error(`${report.title}: Each report must have a uri`);
        }
        this._report = report;
        this._service = isEmpty(parent.parent) ? service : parent.service;
        this._uri = uri;
        this._pending = [];
        this._running = new Set()
        this._previousCalls = new Set();
        this._maxParallelRequests = 10;
        this._onProgress = [];
        this._progressUpdates = [];
        this._expectedTotal = 0;
        this._lastError = null;
        this._isLoading = false;
    }

    setExpectedTotal(total) {
        if(!isEmpty(this._report.parent)) {
            return this._report.parent.getFetcher().setExpectedTotal(total);
        }
        this._expectedTotal = total;
    }

    getExpectedTotal() {
        if(!isEmpty(this._report.parent)) {
            return this._report.parent.getFetcher().getExpectedTotal();
        }
        return this._expectedTotal;
    }

    getProgressUpdates() {
        if(!isEmpty(this._report.parent)) {
            return this._report.parent.getFetcher().getProgressUpdates();
        }
        return this._progressUpdates;
    }

    getCurrentTotal() {
        if(!isEmpty(this._report.parent)) {
            return this._report.parent.getFetcher().getCurrentTotal();
        }
        return this.getProgressUpdates().reduce((agg, up) => {
            return agg + up;
        }, 0);
    }

    getProgress() {
        if(!isEmpty(this._report.parent)) {
            this._report.parent.getFetcher().getProgress();
        }
        if(this.getExpectedTotal() === 0) {
            return 0;
        }
        const currentTotal = this.getCurrentTotal();
        const expectedTotal = this.getExpectedTotal();

        return Math.ceil(100 * currentTotal / expectedTotal);
    }

    getService() {
        return isEmpty(this._report.parent) ? this._service : parent.getService();
    }

    getUri() {
        return this._uri;
    }

    hashParams(params) {
        const entries = Object.entries(params).sort();
        return JSON.stringify(entries)
    }

    addCall(params) {
        if(!isEmpty(this._report.parent)) {
            return this._report.parent.getFetcher().addCall(params);
        }
        this._previousCalls.add(this.hashParams(params));
    }

    hasCalled(params) {
        if(!isEmpty(this._report.parent)) {
            return this._report.parent.getFetcher().hasCalled(params);
        }
        return this._previousCalls.has(this.hashParams(params));
    }

    isLoading() {
        return isEmpty(this._report.parent) ? this._isLoading :
               this._report.parent.isLoading();
    }

    isErrored() {
        return this._hasErrored;
    }

    getPending() {
        return this._pending;
    }

    getRunning() {
        return this._running;
    }

    addRunning(uri, params) {
        this.getRunning().add(uri + this.hashParams(params));
    }

    finishRunning(uri, params) {
        this.getRunning().delete(uri + this.hashParams(params))
    }

    runOnePending(onSuccess, onFailure, previousResponses = []) {
        let [uri, params] = [null, null];

        try {
            [uri, params] = this.getPending().shift();
        } catch(err) {
            // if(isEmpty(this.getRunning()) && isEmpty(this.getPending())) {
            //     onSuccess(this.handleLeafResponse(previousResponses));
            // }
            // return previousResponses;
            console.log("(success) Ending a pending request chain.");
            onSuccess(this.handleLeafResponse(previousResponses));
            return previousResponses;
        }

        this.addRunning(uri, params);

        console.log("Issuing a new pending request.");
        return this.getService().get(uri, params).then((innerResponse) => {
            previousResponses.push(innerResponse);

            this.mergePartialResponse(innerResponse);
            this.finishRunning(uri, params);

            // While there are still pending promises...
            if(!isEmpty(this.getPending())) {
                return this.runOnePending(onSuccess, onFailure, previousResponses);
            }

            if(isEmpty(this.getPending()) && isEmpty(this.getRunning())) {
                const final = this.handleLeafResponse(previousResponses);
                console.log("(success) Ending a pending request chain.");
                onSuccess(final);
            }

            return previousResponses;
        }).catch(err => {
            console.error("Got an error! ", err);
            this._lastError = err;
            console.log("(error) Ending a pending request chain.");
            return onFailure(this.updateStateForErrors(err));
        });
    }

    createPending(params) {
        const offset = parseInt(params.get("offset")) || 0;
        const limit = parseInt(params.get("limit"));
        const asHash = Object.fromEntries(params.entries());

        if(!this.hasCalled(asHash)) {
            this.addCall(asHash);
            this.getPending().push([this.getUri(), asHash]);
        }

        const newParams = new URLSearchParams(params);
        newParams.set("limit", limit.toString());
        newParams.set("offset", (offset + limit).toString());

        return [newParams, offset + limit];
    }

    fanOut(initialResponse) {
        let nRequests = 0;
        const count = initialResponse.data.count;
        const hasNext = !isEmpty(initialResponse) &&
                        !isEmpty(initialResponse.data) &&
                        !isEmpty(initialResponse.data.next);
        if(hasNext) {
            const next = hasNext ? new URL(initialResponse.data.next) : null;
            const params = new URLSearchParams(next.search);
            let [newParams, offset] = this.createPending(params);
            nRequests++;
            while(offset < count) {
                nRequests++;
                [newParams, offset] = this.createPending(newParams);
            }
        }

        if(nRequests === 0) {
            return new Promise((res, rej) => {
                return this.handleLeafResponse([initialResponse]).then(() => {
                    return res([initialResponse]);
                });
            });
        }


        let promises = [];
        for(let i = 0; i <
                       Math.min(this._maxParallelRequests, nRequests); i++) {
            console.log("Initiating a pending request chain...");
            promises.push(new Promise((res, rej) => {
                return this.runOnePending(res, rej, []);
            }));
        }

        console.log("Fanning out requests.");
        return Promise.all(promises).then(responseses => {
            const reduced = [[initialResponse]].concat(responseses).reduce(
                (agg, responses) => {
                    console.log("Concatenating a request/response chain....");
                    return agg.concat(responses);
                }, []);
            return reduced;
        });
    }

    handleInitialResponse(response) {
        return this.mergePartialResponse(response).fanOut(response);
    }

    addProgressUpdate(update) {
        if(!isEmpty(this._report.parent)) {
            this._report.parent.getFetcher().addProgressUpdate(update)
        }
        this._progressUpdates.push(update);
    }

    mergePartialResponse(response) {
        if(response.data === null || response.data === undefined) {
            console.warn("No data in response", response);
            return this.updateStateForErrors(null);
        }
        try {
            this._report.getTimeSeries().update(response.data.results, this._report);
            if(this.getExpectedTotal() === 0) {
                this.setExpectedTotal(response.data.count);
            }
            this.addProgressUpdate(response.data.results.length);
            this.getOnProgressCallbacks().map(f => f(this.getProgress()));
        } catch(err) {
            this.updateStateForErrors(err)
        }

        return this;
    }

    clearProgress() {
        this._progressUpdates = [];
        if(!isEmpty(this._report.parent)) {
            this._report.parent.getFetcher().clearProgress();
        }
    }

    handleLeafResponse(previousResponses) {
        console.log("==========================================================");
        console.log(">>>>>>>>>>>>>>>>> HANDLING LEAF RESPONSE!" +
                    " <<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<");
        console.log("==========================================================");
        this._report.setDefaultGrouping();

        let relatedModelPromises = [];
        this._report.getRelatedModels().map(rm => {
            relatedModelPromises.push(rm.fetch(this._report.getTimeSeries().dataPoints));
        });

        return Promise.all(relatedModelPromises).then(() => {
            console.log("Finished fetching related models.");
            this._lastFetch = new Date();
            this._hasErrored = false;
            this._lastError = null;
            this._pending = [];
            this.setExpectedTotal(0);
            this.clearProgress()
            return previousResponses;
        });

    }

    updateStateBeforeSending() {
        // this._report.getRelatedModels().forEach(pf => pf.reset());
        this._abortController = new AbortController();
        this._expectedTotal = 0;
        this._progressUpdates = []
        this._onProgress = [];
        return this;
    }

    getLastFetch() {
        if(isEmpty(this._report.parent)) {
            return this._lastFetch;
        }
        return this._report.parent.getLastFetch();

    }

    updateStateForErrors(err) {
        console.error(`${this._report.title}: Error handling initial http response`, err);
        if(!this._abortController.signal.aborted) {
            this._abortController.abort();
        }
        this._pending = [];
        this._hasErrored = true;
        this._lastError = err;
        this._isLoading = false;
        return this;
    }

    getLastError() {
        return this._lastError;
    }

    getInitialRequestParams() {
        return {
            ...this._report.getQuery().toParams()
        };
    }

    abort() {
        this._abortController.abort()
        this._isLoading = false;
    }

    addOnProgressCallback(callback) {
        if(!isEmpty(this._report.parent)) {
            this._report.parent.addOnProgressCallback(callback);
        } else {
            this.getOnProgressCallbacks().push(callback)
        }
    }

    getOnProgressCallbacks() {
        if(!isEmpty(this._report.parent)) {
            return this._report.parent.getOnProgressCallbacks();
        }
        return this._onProgress;

    }

    fetch(onProgress) {
        // TODO: This method will be responsible for deciding just what to
        // fetch given what has been fetched already. It will also be
        // responsible for deciding whether to fetch at all.
        if(this.isLoading()) {
            this.abort()
        }
        const params = this.getInitialRequestParams();
        this.addOnProgressCallback(onProgress);
        if(!isEmpty(this._report.parent)) {
            return new Promise((resolve, reject) => resolve(this._report));
        }
        if(this.hasCalled(params)) {
            return new Promise((resolve, reject) => resolve(this._report));
        }
        this._isLoading = true;
        this.addCall(params);
        this.updateStateBeforeSending();
        return new Promise((resolve, reject) => {
            this.addOnProgressCallback(onProgress);
            return this.getService().get(this.getUri(), params).then((response) => {
                return this.handleInitialResponse(response).then(() => {
                    this._isLoading = false;
                    this._lastError = null;
                    this._isLoading = false;
                    this.getOnProgressCallbacks().map(f => f(100));
                    return resolve(this._report);
                }).catch(err => {
                    console.log("Errored in fetch!")
                    console.error(err);
                    return reject(this.updateStateForErrors(err));
                });
            });
        });
    }
}