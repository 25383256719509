import { SET_ORGANIZATION_DETAIL_REQUEST_STATUS, NOT_STARTED } from "../../../constants.js";
import { isNullOrUndefinedOrEmpty } from "../../../../utils.js";

const defaultState = {
    requestStatus: {
        users: NOT_STARTED,
        groups: NOT_STARTED,
        organization: NOT_STARTED,
        organizationUsers: NOT_STARTED,
        organizationUserGroups: NOT_STARTED,
    }
};

const OrganizationDetailReducer = (state = defaultState, action) => {
    switch (action.type) {
        case SET_ORGANIZATION_DETAIL_REQUEST_STATUS:
            console.log(`(reducer) Setting ${action.requestType} to ${action.status}`);
            return {
                ...state,
                [action.requestType]: action.status
            }
        default:
            return state;
    }
}


export default OrganizationDetailReducer;