import * as React from "react"
import {useEffect} from "react";
import {useState} from "react";
import SubmitButton from "../elements/SubmitButton.js";
import TextInput from "../elements/TextInput.js";
import {useDispatch, useSelector} from "react-redux";
import Dropdown from "../elements/Dropdown.js";
import {isNullOrUndefinedOrEmpty, formatDate, uniqueById} from "../../utils.js";
import {useNavigate} from "react-router-dom";
import {fetchMetrcAccounts} from "../../redux/models/integrations/Action.js";
import {
    createDatabaseAccount,
    deleteDatabaseAccount,
    updateDatabaseAccount
} from "../../redux/models/integrations/Action.js";
import {selectOrganization} from "../../redux/models/organizations/Action.js";
import {enqueueSnackbar} from '../../redux/Actions.js';
import {ADMIN_ROLE, SUPERADMIN_ROLE} from "../../utils/Constants.js";
import {kickoffMigrations} from "../../redux/models/integrations/Action.js";
import {isEmpty} from "weed-js";
import Loading from "../elements/Loading";


const DatabaseAccount = ({
                             create = false,
                             enableEdit = false,
                             enableDelete = false,
                             onCreate = (newAccount) => {
                             },
                             onUpdate = (updatedAccount) => {
                             },
                             onDelete = (accountId) => {
                             },
                             ...props
                         }) => {
    const organizationUsers = useSelector(state => state.userReducer.loggedInUser.organizationUsers);
    const organizations = useSelector(state => state.userReducer.loggedInUser.organizations);
    const selectedOrganization = useSelector(state => state.organizationReducer.selectedOrganization);
    const [savingAccount, setSavingAccount] = useState(false);
    const [loading, setLoading] = useState(true);
    const [kickingOffMigration, setKickingOffMigration] = useState(false);
    const [metrcAccounts, setMetrcAccounts] = useState([]);
    const [showPasswords, setShowPasswords] = useState(false);
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [formState, setFormState] = useState({organization_id: selectedOrganization.id});
    const [selectedMetrcAccount, setSelectedMetrcAccount] = useState(null);
    const _selectMetrcAccount = (metrcAccountId) => {
        setSelectedMetrcAccount(metrcAccountId);
        setFormState({...formState, metrc_account_id: metrcAccountId});
    }
    const defaultErrors = {
        name: "",
        password: "",
        host: "",
        organization_id: "",
        metrc_account_id: ""
    };
    const [errors, setErrors] = useState(defaultErrors);

    useEffect(() => {
        const abortController = new AbortController();
        if (isEmpty(selectedOrganization) || isEmpty(selectedOrganization.id)) {
            return;
        }
        setLoading(true);
        dispatch(fetchMetrcAccounts({organization_id: selectedOrganization.id}, abortController.signal)).then((fetchedMetrcAccounts) => {
            if (!isEmpty(fetchedMetrcAccounts)) {
                setSelectedMetrcAccount(fetchedMetrcAccounts[0].id)
            }
            setMetrcAccounts(fetchedMetrcAccounts);
            setLoading(false);
        }).catch(err => {
            if (err !== ABORTED) {
                console.error(err);
            }
            setLoading(false);
        })
        return () => {
            abortController.abort();
            setLoading(false);
        }
    }, [selectedOrganization && selectedOrganization.id]);

    if (organizations.length === 0) {
        return <h1>You must add an organization (and metrc account) before you can add a Database.</h1>
    }

    const _selectOrganization = (organizationId) => {
        const organization = organizations.find((org) => parseInt(org.id) === parseInt(organizationId));
        dispatch(selectOrganization(organization));
        setFormState({...formState, organization_id: organizationId, metrc_account_id: -1});
    }

    const deleteButton = <div className="form-row submit">
        <SubmitButton onClick={(event) => {
            event.preventDefault();
            dispatch(deleteDatabaseAccount(formState.id || props.id)).then((response) => {
                onDelete(props.id);
            }).catch((errs) => {
                setErrors({...errors, ...errs});
            });
        }}>Delete</SubmitButton>
    </div>

    const editButton = <div className="form-row submit">
        <SubmitButton onClick={(event) => {
            event.preventDefault();
            dispatch(updateDatabaseAccount({...props, ...formState})).then((updatedDatabaseAccount) => {
                onUpdate(updatedDatabaseAccount);
                setErrors(defaultErrors);
            }).catch((errs) => {
                setErrors({...errors, ...errs});
            });
        }}>Save</SubmitButton>
    </div>

    const createButton = <div className={"form-row submit"}>
        <SubmitButton onClick={(click) => {
            click.preventDefault();
            setErrors({
                name: "",
                password: "",
                host: "",
                organization_id: "",
                metrc_account_id: ""
            });
            setSavingAccount(true);
            dispatch(createDatabaseAccount({
                ...props,
                ...formState,
                organization_id: selectedOrganization.id,
                metrc_account_id: selectedMetrcAccount
            })).then((databaseAccount) => {
                setSavingAccount(false);
                onCreate(databaseAccount);
                setKickingOffMigration(true);
                dispatch(kickoffMigrations(databaseAccount.id)).then(response => {
                    setKickingOffMigration(false);
                });
                setErrors(defaultErrors);
                setFormState({});
            }).catch((errs) => {
                setSavingAccount(false);
                setKickingOffMigration(false);
                if (!isNullOrUndefinedOrEmpty(errs)) {
                    if (errs.status === 401 || errs.status === 403) {
                        dispatch(enqueueSnackbar({
                            message: "You don't have permissions to create database accounts for this organization. Are you admin?",
                            options: {
                                variant: 'error',
                            },
                        }));
                    } else {
                        dispatch(enqueueSnackbar({
                            message: errs.error,
                            options: {
                                variant: 'error',
                            },
                        }));
                    }
                }
                setErrors({...errors, ...errs});
            });
        }}>Submit</SubmitButton>
    </div>

    const passwordType = showPasswords ? "text" : "password";

    const getIntegrationOrganization = () => {
        const integrationOrganization = organizations.find(org => org.id === selectedOrganization.id);
        if (integrationOrganization) {
            return integrationOrganization.id;
        }
        return null;
    };

    const getAdministeredOrgOptions = () => {
        return organizations.filter(org => {
            const orgUser = organizationUsers.find(ou => ou.organization_id === org.id);
            if (isEmpty(orgUser)) {
                return false;
            }
            return [SUPERADMIN_ROLE, ADMIN_ROLE].includes(orgUser.role);
        }).map((organization) => {
            return {
                label: organization.name,
                value: organization.id
            }
        });
    };

    if (loading) {
        return <Loading />
    }

    console.log("props", props, "formstate", formState, "value", formState["name"] || props["name"] || "");
    return <>
        <TextInput
            id={"database-user"}
            label="Database/User Name"
            formState={formState}
            errors={errors}
            setFormState={setFormState}
            autoComplete={"off"}
            name="name"
            value={formState["name"] || props["name"] || ""}
        />
        <TextInput
            {...props}
            id={"database-password"}
            name="password"
            label="Password"
            formState={formState}
            errors={errors}
            setFormState={setFormState}
            autoComplete={"off"}
            type={passwordType}
        />
        <TextInput
            {...props}
            id={"database-host"}
            name="host"
            label="Host"
            formState={formState}
            errors={errors}
            setFormState={setFormState}
            autoComplete={"off"}
        />
        <br/>
        <br/>
        <Dropdown
            {...props}
            id={"organization_id"}
            label={"Which organization is this for?"}
            onChange={_selectOrganization}
            errors={errors}
            value={getIntegrationOrganization()}
            options={getAdministeredOrgOptions()}
        />
        <br/>
        <br/>
        <Dropdown
            {...props}
            id={"metrc_account_id"}
            label={"Which metrc account is this for?"}
            onChange={_selectMetrcAccount}
            errors={errors}
            value={selectedMetrcAccount}
            options={[{label: "(None)", value: -1}, ...metrcAccounts.map((metrcAccount) => {
                return {
                    label: metrcAccount.display_name,
                    value: metrcAccount.id
                }
            })]}
        />
        <div className="form-row submit">
            {savingAccount ? <span>Saving your account...</span> : ""}
            {kickingOffMigration ? <span>Migrating your database...</span> : ""}
            {create ? createButton : ""}
            {enableEdit ? editButton : ""}
            {enableDelete ? deleteButton : ""}
        </div>
    </>
}


export default DatabaseAccount;