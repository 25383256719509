import React from "react";
import { Dialog, DialogActions, DialogContent, DialogTitle } from "@mui/material";
import SubmitButton from "../elements/SubmitButton.js";
import { useDispatch, useSelector } from "react-redux";
import { hidePopup } from "../../redux/notifications/Action.js";
import { isNullOrUndefinedOrEmpty } from "../../utils.js";


export const ConfirmationPopup = () => {

    const popup = useSelector(state => state.notificationsReducer.popup);
    const dispatch = useDispatch();

    const handleClose = (onConfirm) => {
        dispatch(hidePopup());
        if (!isNullOrUndefinedOrEmpty(onConfirm)) {
            if (typeof onConfirm === "function" || onConfirm instanceof Function) {
                onConfirm();
            }
        } else if (!isNullOrUndefinedOrEmpty(popup.onDismiss)) {
            if (typeof popup.onDismiss === "function" || popup.onDismiss instanceof Function) {
                popup.onDismiss();
            }
        }
    }

    return <Dialog open={popup.show} onClose={handleClose}>
        <DialogTitle>{popup.title}</DialogTitle>
        <DialogContent>
            {popup.message}
        </DialogContent>
        <DialogActions>
            <SubmitButton id={isNullOrUndefinedOrEmpty(popup.id) ? "submit" : popup.id} onClick={() => {
                handleClose(popup.onConfirm);
            }}>Ok</SubmitButton>
        </DialogActions>
    </Dialog>
}

export default ConfirmationPopup;
