import {
  ENQUEUE_SNACKBAR,
  CLOSE_SNACKBAR,
  REMOVE_SNACKBAR,
  POPUP_TYPE_INFORMATIONAL,
  POPUP_TYPE_CONFIRM,
  SSO_VERIFY,
  SSO_OPT_OUT,
  SSO_RESET_PASSWORD,
  SSO_SIGN_IN,
  SSO_ACCEPT_INVITATION,
  HIDE_NOTIFICATION,
  SSO_ERROR,
  SSO_DO_LOGIN,
  SSO_DO_LOGOUT
} from '../constants.js';
import ResetPassword from "../../components/forms/ResetPassword.js";
import { isNullOrUndefinedOrEmpty } from '../../utils.js';

const defaultState = {
  notifications: [],
  popup: {
    title: null,
    message: null,
    show: false,
    type: POPUP_TYPE_INFORMATIONAL,
    id: null,
    action: null
  }
};

const NotificationReducer = (state = defaultState, action) => {
  switch (action.type) {
    case ENQUEUE_SNACKBAR:
      return {
        ...state,
        notifications: [
          ...state.notifications,
          {
            key: action.key,
            ...action.notification,
          },
        ],
      };

    case CLOSE_SNACKBAR:
      return {
        ...state,
        notifications: state.notifications.map(notification => (
          (action.dismissAll || notification.key === action.key)
            ? { ...notification, dismissed: true }
            : { ...notification }
        )),
      };

    case REMOVE_SNACKBAR:
      return {
        ...state,
        notifications: state.notifications.filter(
          notification => notification.key !== action.key,
        ),
      };
    case SSO_VERIFY:
      return {
        ...state,
        popup: {
          "title": "E-mail Verified.",
          "id": "email-verified",
          "message": "Thanks for verifying your email! You can sign on normally now using the password you created. You can also get a single-signin link in your email any time you like by going to login and hitting 'forgot password' on the bottom left.",
          "show": true,
          "type": POPUP_TYPE_INFORMATIONAL
        }
      };
    case SSO_OPT_OUT:
      return {
        ...state,
        popup: {
          "title": "You've successfully opted out.",
          "id": "opt-out",
          "message": "You've opted out from essential communications. You will not be able to recover your account if you forget your password. You can change those settings back here.",
          "show": true,
          "type": POPUP_TYPE_INFORMATIONAL
        }
      };
    case SSO_RESET_PASSWORD:
      return {
        ...state,
        popup: {
          "title": "Reset Password.",
          "id": "reset-password",
          "message": <ResetPassword />,
          "show": true,
          "type": POPUP_TYPE_INFORMATIONAL,
          "action": action.action
        }
      }
    case SSO_SIGN_IN:
      return {
        ...state,
        popup: {
          "id": "sign-in",
          "title": "Signed in successfully.",
          "message": "Welcome back!",
          "show": true,
          "type": POPUP_TYPE_INFORMATIONAL,
        }
      };
    case SSO_DO_LOGIN:
      return {
        ...state,
        popup: {
          "id": "do-login",
          "title": "Not logged in.",
          "message": "Welcome back! Please log in to continue.",
          "show": true,
          "type": POPUP_TYPE_INFORMATIONAL,
        }
      };
    case SSO_DO_LOGOUT:
      return {
        ...state,
        popup: {
          "id": "do-logout",
          "title": "Successfully Logged Out.",
          "message": "We hope it was a productive session!",
          "show": true,
          "type": POPUP_TYPE_INFORMATIONAL,
        }
      };
    case SSO_ACCEPT_INVITATION:
      return {
        ...state,
        popup: {
          "id": "accept-invitation",
          "title": "Invitation accepted.",
          "message": "We look forward to working with you!",
          "show": true,
          "type": POPUP_TYPE_INFORMATIONAL,
        }
      };
    case SSO_ERROR:
      return {
        ...state,
        popup: {
          "id": "signon-error",
          "title": "Single Signon Error.",
          "message": action.message,
          "show": true,
          "type": POPUP_TYPE_INFORMATIONAL,
        }
      };
    case POPUP_TYPE_CONFIRM:
      return {
        ...state,
        popup: {
          "title": action.title,
          "message": action.message,
          "show": true,
          "type": POPUP_TYPE_CONFIRM,
          "onConfirm": isNullOrUndefinedOrEmpty(action.onConfirm) ? () => { } : action.onConfirm,
          "id": action.id,
          "action": action.action
        }
      };
    case HIDE_NOTIFICATION:
      return { ...state, popup: { ...state.popup, show: false } }
    default:
      return state
  }
}


export default NotificationReducer;
