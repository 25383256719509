import React, { useEffect, useState } from "react";
import { Container, Grid } from "@mui/material";
import { useNavigate } from "react-router-dom";
import LeaflogixAccount from "../../../components/forms/LeaflogixAccount.js";
import { useDispatch, useSelector } from "react-redux";
import {
    fetchLeaflogixAccounts,
    // setLeaflogixAccounts
} from "../../../redux/models/integrations/Action.js";
import { ABORTED } from "../../../redux/constants.js";
import { showInformationalPopup } from '../../../redux/notifications/Action.js';
import {
    updateAccountStatus
} from "../../../redux/user/Action.js";
import {isEmpty} from "weed-js"
import Loading from "../../../components/elements/Loading.js";


const Setup = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const [loading, setLoading] = useState(true);
    const selectedOrganization = useSelector(state => state.organizationReducer.selectedOrganization);
    const loggedInUser = useSelector(state => state.userReducer.loggedInUser.user);
    const accountStatus = useSelector(state => state.userReducer.loggedInUser.accountStatuses.find(a => {
        return isEmpty(selectedOrganization) ? {} : a.organization_id === selectedOrganization.id;
    }));

    useEffect(() => {
        const abortController = new AbortController();
        setLoading(true);
        dispatch(fetchLeaflogixAccounts({}, abortController)).then((fetchedLeaflogixAccounts) => {
            setLoading(false);
            // TODO: I think this was copy/pasted fromo the metrc setup. It is broken, there is no setLeaflogixAccounts exported from redux/models/integrations/Action.js
            // setLeaflogixAccounts(fetchedLeaflogixAccounts);
            //
        }).catch(err => {
            setLoading(false);
            if (err !== ABORTED) {
                console.error(err);
            }
        });
        return () => {
            abortController.abort()
        }
    }, [loggedInUser.id])

    if (loading) {
        return <Loading />
    }

    if (isEmpty(selectedOrganization)) {
        return <Container className={"workspace"}>
            <Grid container spacing={2}>
                <Grid item xs={6} className={"left-part"}>
                    You need to join an organization before you can set up an integration.
                </Grid>
            </Grid>
        </Container>
    }

    return <>
        <Container className={"workspace"}>
            <Grid container spacing={2}>
                <Grid item xs={6} className={"left-part"}>
                    <Grid container spacing={3}>
                        <Grid item xs={12}>
                            <h1 className="catchline">Enter your Leaflogix account details</h1>
                            <h2>Then we'll start pulling products and inventory so they're ready for you to sell</h2>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={6} className="right-part">
                    <Grid container spacing={2} className="leaflogix-form">
                        <Grid item xs={12}>
                            <LeaflogixAccount
                                create={true}
                                onCreate={(newAccount) => {
                                    if (isEmpty(accountStatus) || !accountStatus.has_integration) {  // First integration!
                                        dispatch(updateAccountStatus({ ...accountStatus, integration: true }));
                                    }
                                    navigate("/integrations/leaflogix/list");
                                }}
                                onUpdate={(updatedAccount) => {
                                    console.log("Updated")
                                }}
                                onDelete={(accountId) => {
                                    console.log("Deleted")
                                }}
                            />
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </Container>
    </>
}


export default Setup;
