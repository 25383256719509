import { INVITED_ROLE } from "./utils/Constants.js";
import { CREATE, DESTROY } from "./utils/Constants.js";
import Cookies from "js-cookie";

const convertListToObjectByAttribute = (lst, attr) => {
    let obj = {}
    lst.map((el) => {
        obj[el[attr]] = el;
        return el;
    });
    return obj;
}

export const aborted = (abortController) => {
    return abortController && abortController.signal && abortController.signal.aborted;
};

const currencyFormatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
});

const numberFormatter = new Intl.NumberFormat('en-US', {
    style: 'decimal',
});

const renameFrontendVariableForBackend = (frontendName) => {
    return frontendName.replaceAll(/([A-Z])([a-z])/g, "_$1$2").toLowerCase();
}

const hasPendingInvitations = (organizationUsers) => {
    return organizationUsers.reduce((accumulator, currentValue) => {
        return accumulator || currentValue.role === INVITED_ROLE;
    }, false)
}

function uniqueById(lst) {
    const unique = {};
    for (let i = 0; i < lst.length; i++) {
        const e = lst[i];
        unique[e.id] = e;
    }
    return Object.values(unique);
}


export function groupByAttribute(lst, attr) {
    const unique = {};
    for (let i = 0; i < lst.length; i++) {
        const e = lst[i];
        unique[e[attr]] = e;
    }
    return unique;
}

export function groupListByField(lst, attr) {
    const byAttribute = {};
    lst.forEach(element => {
        if (byAttribute[element[attr]]) {
            byAttribute[element[attr]].push(element);
        } else {
            byAttribute[element[attr]] = [element];
        }
    });
    return byAttribute;
}

function uniqueByAttribute(lst, attr) {
    return Object.values(groupByAttribute(lst, attr));
}

function uniqueByValue(lst) {
    return new Array(...(new Set(lst)));
}

function isNullOrUndefined(something) {
    if (something === null) {
        return true;
    } else if (something === undefined) {
        return true;
    }
    return false;
}

export const returnOrAbort = (response, responseIfAborted, abortController) => {
    return aborted(abortController) ? responseIfAborted : response;
}

function isNullOrUndefinedOrEmpty(something) {
    if (something === null) {
        return true;
    } else if (something === undefined) {
        return true;
    } else if (something === "") {
        return true;
    } else if (typeof something === "object" && Object.values(something).length === 0) {
        return true;
    }
    return false;
}

const allowOnlyNumbersOrDot = (event) => {
    if (!/[0-9]|\./.test(event.key)) {
        event.preventDefault();
    }
}

const castPercentageToDecimal = (value) => {
    if (value === undefined || value === null) {
        return value;
    }
    try {
        return parseFloat(value) / 100.;
    } catch (e) {
        console.error("Got a non-numeric percent value", value);
        return null;
    }
}

const formatNumber = (unformatted) => {
    if (unformatted === "---" || unformatted === null || unformatted === undefined) {
        return "---";
    }
    try {
        let formatted = parseFloat(unformatted).toFixed(2);
        if (isNaN(formatted)) {
            return unformatted;
        }
        return formatted;
    } catch (error) {
        console.error(error);
    }
    return unformatted;
};

const NO_VALUE = '---';

const randomChoice = (lst) => {
    return lst[Math.ceil(Math.random() * lst.length) - 1];
};


/**
 * This method allows us to concisely check for nested attributes.
 * Instead of writing :
 * customer ? customer.company ? customer.company.name ? customer.company.name : '---' : '---' : '---';
 * We can use the following method to write :
 * checkPathAndDisplay(customer, 'company.name', '---');
 * @param {Object} initialVariable the root object for which you want to display some nested field
 * @param {String} attributePath the nested field you want to display for your object, i.e 'company.parent_company.name'
 * @param {String} defaultReturnValue the value to return if any of the nested fields is null/undefined
 * @returns the desired nested field if it exists, the default value otherwise
 */
const checkPathAndDisplay = (initialVariable, attributePath, defaultReturnValue) => {
    let currentNode = initialVariable;
    if (isNullOrUndefined(currentNode)) {
        return defaultReturnValue;
    }
    const pathAsList = attributePath.split(".");
    for (let i = 0; i < pathAsList.length; i++) {
        currentNode = currentNode[pathAsList[i]];
        if (isNullOrUndefined(currentNode)) {
            return defaultReturnValue;
        }
    }

    return currentNode;
}

/**
 * This checks a hashmap's entry list is not empty
 * @param {*} hashMapWithlist the hashmap to check
 * @param {*} key the hashmap entry which should be a non empty list
 * @returns true or false depending on list being empty or not
 */
const checkHashMapListNotEmpty = (hashMapWithlist, key) => {
    return hashMapWithlist && hashMapWithlist[key] && hashMapWithlist[key].length >= 0;
}

const userIsNotLoggedIn = (loggedInUser) => {
    if (loggedInUser === undefined) {
        return true;
    } else if (loggedInUser === null) {
        return true;
    } else if (loggedInUser == {}) {
        return true;
    } else if (!loggedInUser) {
        return true;
    } else if (loggedInUser.id === null || loggedInUser.id === undefined) {
        return true;
    }
    return false;
}


const userIsLoggedIn = (loggedInUser) => {
    return !userIsNotLoggedIn(loggedInUser);
}

export const formatDate = (d) => {
    if (isNullOrUndefinedOrEmpty(d)) {
        return ""
    }
    const year = d.year().toString();
    const month = (d.month() + 1).toString().padStart(2, "0")
    const day = d.date().toString().padStart(2, "0")
    return `${year}-${month}-${day}`;
};


export const formatDateTime = (d) => {
    if (isNullOrUndefinedOrEmpty(d)) {
        return ""
    }
    const year = d.year().toString();
    const month = (d.month() + 1).toString().padStart(2, "0")
    const day = d.date().toString().padStart(2, "0")
    const hour = d.hour().toString().padStart(2, "0")
    const minute = d.minute().toString().padStart(2, "0")
    const second = d.second().toString().padStart(2, "0")
    return `${year}-${month}-${day}T${hour}:${minute}:${second}+00:00`;
}

const getTotalForField = (entries, field) => {
    const totalSold = entries.reduce((currentTotal, entry) => {
        return currentTotal + entry[field];
    }, 0);
    return totalSold;
};


const accountIsStillBeingSetUp = (accountStatus) => {
    if (accountStatus.has_integration === false) {
        return true;
    }
    return false;
};

const getAlteredGroupdIdAndAction = (selectedGroupIds, memberGroupIds) => {
    let difference = [];
    let action = null;
    if (selectedGroupIds.length > memberGroupIds.length) {
        difference = selectedGroupIds.filter(e => !memberGroupIds.includes(e));
        action = CREATE;
    } else {
        difference = memberGroupIds.filter(e => !selectedGroupIds.includes(e));
        action = DESTROY;
    }
    if (difference.length === 1) {
        return [difference[0], action];
    } else {
        console.error(`Expected to find a single altered groupd id but found ${difference.length}!`);
        return [null, null];
    }
};

const getCookieParams = () => {
    const cookieParams = {
        domain: process.env.REACT_APP_SSO_COOKIE_DOMAIN,
        secure: process.env.REACT_APP_SSO_COOKIE_SECURE === "True",
        sameSite: process.env.REACT_APP_SSO_COOKIE_SAMESITE,
        httpOnly: process.env.REACT_APP_SSO_COOKIE_HTTP_ONLY === "True",
        path: "/",
        expires: 365
    };
    return cookieParams;
}

const setCookie = (name, value) => {
    return Cookies.set(
        name,
        value,
        getCookieParams()
    )
}

const getCookie = (name) => {
    return Cookies.get(name)
}

const removeCookie = (name) => {
    return Cookies.remove(name, getCookieParams());
}

export {
    NO_VALUE,
    accountIsStillBeingSetUp,
    allowOnlyNumbersOrDot,
    castPercentageToDecimal,
    checkHashMapListNotEmpty,
    checkPathAndDisplay,
    convertListToObjectByAttribute,
    currencyFormatter,
    formatNumber,
    getAlteredGroupdIdAndAction,
    getCookie,
    getTotalForField,
    hasPendingInvitations,
    isNullOrUndefined,
    isNullOrUndefinedOrEmpty,
    numberFormatter,
    randomChoice,
    removeCookie,
    setCookie,
    uniqueByAttribute,
    uniqueById,
    uniqueByValue,
    userIsLoggedIn,
    userIsNotLoggedIn,
};
