import React from 'react';
import {useState} from "react";
import SubmitButton from "../elements/SubmitButton.js";
import TextInput from "../elements/TextInput.js";
import {useDispatch, useSelector} from "react-redux";
import ErrorPopup from "../notifications/ErrorPopup.js";
import {updateIDScanAccount, createIDScanAccount, deleteIDScanAccount} from "../../redux/models/integrations/Action.js";
import {isEmpty} from "weed-js";
import Dropdown from "../elements/Dropdown";
import {selectOrganization} from "../../redux/models/organizations/Action";
import InputAdornment from '@mui/material/InputAdornment/InputAdornment.js';
import IconButton from '@mui/material/IconButton/IconButton.js';
import {Visibility, VisibilityOff} from '@mui/icons-material';
import {ADMIN_ROLE, SUPERADMIN_ROLE} from "../../utils/Constants";

const IDScanAccount = ({
                           create = false,
                           enableEdit = false,
                           enableDelete = false,
                           onCreate = (newAccount) => {
                           },
                           onUpdate = (updatedAccount) => {
                           },
                           onDelete = (accountId) => {
                           },
                           ...idScanAccount
                       }) => {
    const dispatch = useDispatch();
    const organizationUsers = useSelector(state => state.userReducer.loggedInUser.organizationUsers);
    const organizations = useSelector(state => state.userReducer.loggedInUser.organizations);
    const selectedOrganization = useSelector(state => state.organizationReducer.selectedOrganization);
    const getIntegrationOrganization = () => {
        const integrationOrganization = organizations.find(org => org.id === selectedOrganization.id);
        if (integrationOrganization) {
            return integrationOrganization.id;
        }
        return null;
    };
    const getAdministeredOrgOptions = () => {
        return organizations.filter(org => {
            const orgUser = organizationUsers.find(ou => ou.organization_id === org.id);
            if (isEmpty(orgUser)) {
                return false;
            }
            return [SUPERADMIN_ROLE, ADMIN_ROLE].includes(orgUser.role);
        }).map((organization) => {
            return {
                label: organization.name,
                value: organization.id
            }
        });
    };
    const _selectOrganization = (organizationId) => {
        const organization = organizations.find((org) => parseInt(org.id) === parseInt(organizationId));
        dispatch(selectOrganization(organization));
    }
    const defaultErrors = {
        _message: "", display_name: "", token: "", username: "", password: ""
    }
    const defaultFormState = {
        display_name: idScanAccount.display_name || '',
        username: idScanAccount.username || '',
        password: idScanAccount.password || ''
    };
    const [errors, setErrors] = useState({...defaultErrors});
    const [formState, setFormState] = useState({...defaultFormState});
    const [showPasswords, setShowPasswords] = useState(false);
    const disabled = !create && !enableEdit;
    const passwordType = showPasswords ? "text" : "password";

    return <>
        {errors._message !== "" ? <ErrorPopup onClose={() => {
            setErrors({...errors, _message: ""});
        }} message={errors._message}/> : ""}
        {idScanAccount && idScanAccount.display_name ? <h2>{idScanAccount.display_name}</h2> : ""}
        <div className="form-row">
            <TextInput
                disabled={disabled}
                display_name={idScanAccount.display_name}
                errors={errors}
                formState={formState}
                label="Display Name"
                name="display_name"
                setFormState={setFormState}
            />
        </div>
        <div className="form-row mb-2">
            <TextInput
                disabled={disabled}
                errors={errors}
                formState={formState}
                username={idScanAccount.username}
                label="IdScan Username"
                name="username"
                setFormState={setFormState}
            />
            <TextInput
                disabled={disabled}
                errors={errors}
                formState={formState}
                password={formState.password}
                label="IdScan Password"
                name="password"
                setFormState={setFormState}
                InputProps={{
                    endAdornment: <InputAdornment position="end">
                        <IconButton onClick={() => {
                            setShowPasswords(!showPasswords)
                        }}>
                            {showPasswords ? <Visibility/> : <VisibilityOff/>}
                        </IconButton>
                    </InputAdornment>
                }}
                type={passwordType}
            />
        </div>
        <div className="form-row">
            <Dropdown
                id={"organization_id"}
                label={"Which organization is this for?"}
                onChange={_selectOrganization}
                errors={errors}
                value={getIntegrationOrganization()}
                options={getAdministeredOrgOptions()}
            />
        </div>
        <div className="form-row submit">
            {enableDelete ? <SubmitButton
                variant={"outlined"}
                color={"error"}
                disabled={(disabled || (isEmpty(formState) && isEmpty(idScanAccount)))}
                onSubmit={(event) => {
                    event.preventDefault();
                    dispatch(deleteIDScanAccount(idScanAccount.id)).then((response) => {
                        onDelete(idScanAccount.id);
                        setFormState({...defaultFormState})
                        navigate(ROUTES_INTEGRATIONS_IDSCAN_LIST);
                    }).catch((errs) => {
                        setErrors({...errors, ...errs});
                    });
                }}>Delete</SubmitButton> : ""}
            <SubmitButton
                disabled={disabled}
                onClick={(event) => {
                    event.preventDefault();
                    setErrors({...defaultErrors});
                    const action = create ? createIDScanAccount : updateIDScanAccount;
                    return dispatch(action({
                        ...idScanAccount,
                        ...formState,
                        organization_id: selectedOrganization.id
                    })).then((response) => {
                        onCreate(response);
                        setFormState({...response});
                        setErrors(defaultErrors);
                    }).catch((errs) => {
                        setErrors({...errors, ...errs});
                    });
                }}>Save</SubmitButton>
        </div>
    </>
}
export default IDScanAccount;