import {
    INCREMENT_SETUP_WIZARD_REQUESTS_IN_PROGRESS,
    DECREMENT_SETUP_WIZARD_REQUESTS_IN_PROGRESS,
} from "../../constants.js";
import { isNullOrUndefinedOrEmpty } from "../../../utils.js";
import Setup from '../../../views/integrations/Setup.js';

const defaultState = {
    pendingRequests: { total: 0 }
};

const SetupWizardReducer = (state = defaultState, action) => {
    switch (action.type) {
        case INCREMENT_SETUP_WIZARD_REQUESTS_IN_PROGRESS:
            if (state.pendingRequests[action.requestType] !== undefined) {
                return {
                    ...state,
                    pendingRequests: {
                        ...state.pendingRequests,
                        [action.requestType]: state.pendingRequests[action.requestType] + 1,
                        total: state.pendingRequests.total + 1
                    }
                };
            } else {
                return {
                    ...state,
                    pendingRequests: {
                        ...state.pendingRequests,
                        [action.requestType]: 1,
                        total: state.pendingRequests.total + 1
                    }
                };
            }
        case DECREMENT_SETUP_WIZARD_REQUESTS_IN_PROGRESS:
            if (state.pendingRequests[action.requestType] !== undefined) {
                return {
                    ...state,
                    pendingRequests: {
                        ...state.pendingRequests,
                        [action.requestType]: state.pendingRequests[action.requestType] - 1,
                        total: state.pendingRequests.total + 1
                    }
                };
            } else {
                return {
                    ...state,
                    pendingRequests: {
                        ...state.pendingRequests,
                        [action.requestType]: 0,
                        total: state.pendingRequests.total - 1
                    }
                };
            }
        default:
            return state;
    }
};


export default SetupWizardReducer;
