import React, {useState, useEffect} from "react";

import {Chart as ChartJS, registerables} from "chart.js";
import {isEmpty, Client, getCookie} from "weed-js";
import {Report} from "../observers/classes/Report.js";
import {Divide, Average, Subtract, Add, Calculation, F, Multiply, Sum, Count} from "../observers/classes/Calculation.js";
import {Field} from "../observers/classes/Field.js";
import {RelatedModel} from "../observers/classes/RelatedModel.js";
import {TimeRangeQuery, Rollups} from "../observers/classes/TimeRangeQuery.js";
import {Loading} from "../../elements/Loading.js";

import ObserverChart from "../observers/ObserverChart.js";
import {Grid} from "@mui/material";
import {useDispatch} from "react-redux";
import {fetchAlpineIQAccounts, fetchLeaflogixAccounts} from "../../../redux/models/integrations/Action";

ChartJS.register(...registerables);

const client = new Client();
const calculation = new Calculation(
    "Lifetime Spend",
    new F("lifetime_spend"),
    Sum
);

const alpineIQCustomerLifetimeValueReport = new Report(
    client.alpineiq.api.v1(),
    "/kpis/sale-orders/customer-lifetime-value/",
    "CustomerLifetimeValue",
    [
        new Field("first_purchase", {
            displayName: "First Purchase",
            cast: (d) => {
                const date = new Date(d);
                const year = date.getFullYear();
                const month = String(date.getMonth() + 1).padStart(2, '0');
                const day = String(date.getDate()).padStart(2, '0');
                return `${year}-${month}-${day}`;
            },
            filterable: true,
            timestamp: true
        }),
        new Field("lifetime_spend", {
            displayName: "Lifetime Spend",
            cast: (d) => parseFloat(d),
            observations: true
        })
    ],
    [],
    calculation,
    "This report gives customer lifetime spend."
)


const CustomerLifetimeValue = ({organizationId, startDate, endDate}) => {

    const dispatch = useDispatch();
    const [selectedAlpineIQAccounts, setSelectedAlpineIQAccounts] = useState([]);
    const [progress, setProgress] = useState(0);
    const [clv, setCLV] = useState({
        purchaseTotal: 0,
        purchaseCount: 0,
        totalLifespan: 0,
        totalCustomers: 0
    });

    useEffect(() => {
        dispatch(fetchAlpineIQAccounts({organization_id: organizationId})).then(fetchedAccounts => {
            setSelectedAlpineIQAccounts(fetchedAccounts)
            alpineIQCustomerLifetimeValueReport.query(
                startDate,
                endDate,
                null,
                {"alpineiq_account_id__in": fetchedAccounts.map(a => a.id).join(",")}
            );
        });
    }, [organizationId]);


    useEffect(() => {
        if (isEmpty(selectedAlpineIQAccounts) || alpineIQCustomerLifetimeValueReport.isLoading()) {
            return;
        }
        // TODO: When the organization changes we need to clear the previous results!
        alpineIQCustomerLifetimeValueReport.query(
            startDate,
            endDate,
            null,
            {"alpineiq_account_id__in": selectedAlpineIQAccounts.map(a => a.id).join(",")}
        );
        alpineIQCustomerLifetimeValueReport.fetch(setProgress).then(report => {
            setCLV((prevState, props) => {
                return report.getTimeSeries().dataPoints.reduce((previous, current) => {
                    const firstPurchase = new Date(current.first_purchase);
                    const lastPurchase = new Date(current.last_purchase);
                    const lifespan = (lastPurchase - firstPurchase) / 86400000;
                    return {
                        purchaseTotal: previous.purchaseTotal + current.lifetime_spend,
                        purchaseCount: previous.purchaseCount + current.purchases,
                        totalLifespan: previous.totalLifespan + lifespan,
                        totalCustomers: previous.totalCustomers
                    };
                }, {purchaseTotal: 0, purchaseCount: 0, totalLifespan: 0, totalCustomers: report.getTimeSeries().dataPoints.length});
            });
        });

    }, [organizationId, startDate, endDate, selectedAlpineIQAccounts.map(a => a.id).join(",")]);

    const averagePurchaseValue = (clv.purchaseTotal / (clv.purchaseCount || 1));
    const numberOfPurchases = clv.purchaseCount;
    const averageCustomerLifespan = clv.totalLifespan / (clv.totalCustomers || 1);

    if (!alpineIQCustomerLifetimeValueReport.getLastFetch() || alpineIQCustomerLifetimeValueReport.isLoading()) {
        return <Grid container spacing={12} className="graphs">
            <Grid item xs={12}>
                <Loading value={progress} fullscreen={false}/>
            </Grid>
        </Grid>
    }

    return <div>
        <Grid container spacing={2}>
            <Grid item xs={12}>
                <h2>Customer Lifetime Value</h2>
                <h3>Customer Lifetime Value: ${(clv.purchaseTotal / clv.totalCustomers).toFixed(2)}</h3>
                <p>Number of customers: {clv.totalCustomers}</p>
                <p>Number of purchases: {numberOfPurchases}</p>
                <p>Total Purchase Value: ${clv.purchaseTotal.toFixed(2)}</p>
                <p>Average purchase value: ${averagePurchaseValue.toFixed(2)}</p>
                <p>Average customer lifespan: {averageCustomerLifespan.toFixed(2)} days</p>
            </Grid>
        </Grid>
    </div>
}

export default CustomerLifetimeValue;
