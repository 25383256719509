import React, { useState, useEffect } from 'react';
import { useNavigate, Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { userIsNotLoggedIn } from "../../../utils.js";
import { TableHead, TableCell, TableRow, TableContainer, Table, TableBody, Container } from "@mui/material";
import { fetchIDScanAccounts } from "../../../redux/models/integrations/Action.js";
import { selectOrganization } from "../../../redux/models/organizations/Action.js";
import { isEmpty } from "weed-js";
import Loading from "../../../components/elements/Loading";


const List = () => {
    const abortController = new AbortController();

    const [idScanAccounts, setIDScanAccounts] = useState([]);

    const loggedInUser = useSelector(state => state.userReducer.loggedInUser.user);
    const organizations = useSelector(state => state.userReducer.loggedInUser.organizations);
    const selectedOrganization = useSelector(state => state.organizationReducer.selectedOrganization);
    const organizationById = Object.fromEntries(organizations.map(o => [o.id, o]));
    const [loading, setLoading] = useState(false);

    const dispatch = useDispatch();
    const navigate = useNavigate();

    useEffect(() => {
        if (!isEmpty(organizations)) {
            setLoading(true);
            dispatch(fetchIDScanAccounts(abortController)).then((fetchedIDScanAccounts) => {
                setLoading(false);
                setIDScanAccounts(fetchedIDScanAccounts);
            });
            return () => {
                setLoading(false);
                abortController.abort();
            }
        }
    }, [loggedInUser.id, organizations]);

    if (userIsNotLoggedIn(loggedInUser)) {
        navigate("/login");
        return "User is not logged in.";
    }

    const maybeSwitchOrganization = (organization) => {
        if (selectedOrganization.id !== organization.id) {
            dispatch(selectOrganization(organization));
        }
    }

    if (loading) {
        return <Loading />
    }

    const noIntegrations = <Link to="/integrations/idscan/setup">
        You have no IdScan integrations. Go to Integrations, then Setup to add some or click here.
    </Link>;

    return <Container className={"workspace"}>
        <h1>Manage IdScan Integrations</h1>
        <TableContainer>
            <Table>
                <TableHead>
                    <TableRow>
                        <TableCell>Organization Name</TableCell>
                        <TableCell>IdScan Account Name</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {
                        isEmpty(idScanAccounts) ? noIntegrations : idScanAccounts.sort((a, b) => {
                            const organizationA = organizationById[a.organization_id];
                            const organizationB = organizationById[b.organization_id];
                            const aName = isEmpty(organizationA) ? "" + a.display_name : organizationA.name + " " + a.display_name;
                            const bName = isEmpty(organizationB) ? "" + b.display_name : organizationB.name + " " + b.display_name;
                            return aName.localeCompare(bName);
                        }).map((idScanAccount, idx) => {
                            const organization = organizationById[idScanAccount.organization_id];
                            return <TableRow key={idScanAccount.id}>
                                <TableCell>
                                    <Link to={`/integrations/idscan/${idScanAccount.id}/detail`} onClick={() => {
                                        if (!isEmpty(organization)) {
                                            maybeSwitchOrganization(organization);
                                        }
                                    }}>
                                        {isEmpty(organization) ? "(orphan!)" : organization.name}
                                    </Link>
                                </TableCell>
                                <TableCell>
                                    <Link to={`/integrations/idscan/${idScanAccount.id}/detail`} onClick={() => {
                                        if (!isEmpty(organization)) {
                                            maybeSwitchOrganization(organization);
                                        }
                                    }}>
                                        {idScanAccount.display_name}
                                    </Link>
                                </TableCell>
                            </TableRow>;
                        })
                    }
                </TableBody>
            </Table>
        </TableContainer>
    </Container>
};

export default List;