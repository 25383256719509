import React from "react";
import {CircularProgress, LinearProgress} from "@mui/material";

export const Loading = ({ title = "Loading", value = null, fullscreen = true}) => {
    return <div className={"loading" + (fullscreen ? " fullscreen-loading" : "")}>
        <div className={"rotating-border"}>
            &nbsp;
        </div>
        <img src={`/public/canold-logo.webp`} alt={"canold logo"} className={"animate-logo"}/>
        {value !== null && value !== undefined ? <h3>{value}% complete</h3> : ""}
        <p>{title}</p>
    </div>

}

export default Loading;
