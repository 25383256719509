import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import SubmitButton from "../elements/SubmitButton.js";
import { useNavigate } from "react-router-dom";

import { fetchLogo, uploadFiles, setLogo } from "../../redux/user/Action.js";
import { isNullOrUndefinedOrEmpty } from '../../utils.js';
import { ADMIN_ROLE, SUPERADMIN_ROLE, ROUTES_ORGANIZATIONS_DETAIL, BANK, ROUTES_ORGANIZATIONS_DETAIL_BANK_ACCOUNTS } from '../../utils/Constants.js';
import { showInformationalPopup } from '../../redux/notifications/Action.js';
import {
    updateAccountStatus
} from "../../redux/user/Action.js";

const Logo = ({ organizationId, currentUser }) => {

    const [errors, setErrors] = useState({});
    const [uploadedLogo, setUploadedLogo] = useState(null);
    const [logoPreview, setLogoPreview] = useState(null);
    const organizations = useSelector(state => state.userReducer.loggedInUser.organizations);
    const userIsAdminOrHigher = isNullOrUndefinedOrEmpty(currentUser) ? false : [ADMIN_ROLE, SUPERADMIN_ROLE].includes(currentUser.role);
    const accountStatus = useSelector(state => state.userReducer.loggedInUser.accountStatuses.find(a => a.organization_id === organizationId));
    const navigate = useNavigate();

    let currentLogo = null;
    if (!isNullOrUndefinedOrEmpty(organizations)) {
        currentLogo = organizations.find(o => o.id === organizationId).logo;
    }
    const dispatch = useDispatch();

    useEffect(() => {
        if (!isNullOrUndefinedOrEmpty(currentLogo)) {
            setLogoPreview(currentLogo.logo);
            return;
        }
        const abortController = new AbortController();
        dispatch(fetchLogo(organizationId, abortController)).then(fetchedLogo => {
            dispatch(setLogo(fetchedLogo, organizationId));
        }).catch(err => {
            console.error(err);
        });
        return () => {
            abortController.abort()
        }
    }, [organizationId]);

    useEffect(() => {
        if (uploadedLogo) {
            const objectUrl = URL.createObjectURL(uploadedLogo);
            setLogoPreview(objectUrl);
            return () => {
                URL.revokeObjectURL(objectUrl)
            }
        }
    }, [uploadedLogo]);

    const handleImageChange = (e) => {
        setUploadedLogo(e.target.files[0]);
    };

    const doSubmit = (e) => {
        e.preventDefault();
        dispatch(uploadFiles(uploadedLogo, organizationId)).then(response => {
            setLogo(response);
            setLogoPreview(response.data.logo)
        }).catch(err => {
            setErrors(err);
            console.error(err);
        });
    };

    return (<>
        <br />
        <p>Your logo is displayed on sales quotes you send out to your clients.</p>
        <div className="form-row">
            {logoPreview && <img
                src={logoPreview}
                alt="Your organization logo"
                className="logo"
            />}
            {userIsAdminOrHigher && <div>
                <div>
                    <label htmlFor="logo">Upload a logo for your organization</label>
                    <br />
                    <input
                        type="file"
                        name="logo"
                        accept="image/jpeg,image/png,image/gif,image/webp"
                        onChange={(e) => handleImageChange(e)}
                    />
                    {errors.logo && (
                        <span className="alert-danger" tooltip>
                            {errors.logo}
                        </span>
                    )}
                </div>
                {errors.description && (
                    <span className="alert-danger" tooltip>
                        {errors.description}
                    </span>
                )}
                <br />
                <SubmitButton
                    disabled={uploadedLogo ? false : true}
                    onSubmit={(event) => {
                        doSubmit(event);
                    }
                    }>Upload Logo</SubmitButton>
            </div>}
        </div>
    </>)
}

export default Logo;
