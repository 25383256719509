import React from "react";
import { useState } from "react";
import { Dialog, DialogActions, DialogContent, DialogTitle } from "@mui/material";
import SubmitButton from "../elements/SubmitButton.js";
import { useDispatch, useSelector } from "react-redux";
import { hidePopup } from "../../redux/notifications/Action.js";
import { isNullOrUndefinedOrEmpty } from "../../utils.js";


export const InformationalPopup = () => {

    const popup = useSelector(state => state.notificationsReducer.popup);
    const [open, setOpen] = useState(false);
    const dispatch = useDispatch();

    const handleClose = () => {
        setOpen(false);
        dispatch(hidePopup());
    }

    const defaultAction = <SubmitButton id={isNullOrUndefinedOrEmpty(popup.id) ? "submit" : popup.id} onClick={(click) => {
        handleClose();
        if (!isNullOrUndefinedOrEmpty(popup.onConfirm)) {
            popup.onConfirm(click);
        }
    }}>Ok</SubmitButton>

    return <Dialog open={popup.show ? popup.show : open} onClose={handleClose}>
        <DialogTitle>{popup.title || popup.title}</DialogTitle>
        <DialogContent>
            {popup.message}
        </DialogContent>
        <DialogActions>
            {popup.action || defaultAction}
        </DialogActions>
    </Dialog>
}

export default InformationalPopup;
