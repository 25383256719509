import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import TextInput from "../elements/TextInput.js";
import { fetchAddresses, updateAddress, createAddress } from '../../redux/Actions.js';
import { updateBankAccount, updateOrganization } from "../../redux/user/Action.js";
import SubmitButton from "../elements/SubmitButton.js";
import { isNullOrUndefinedOrEmpty } from "../../utils.js";
import Dropdown from "../elements/Dropdown.js";
import { fetchStates, setStates } from "../../redux/models/states/Action.js";
import { setBankAccountAddress } from '../../redux/user/Action.js';
import Loading from '../elements/Loading.js';


const Address = ({ organization = null, bankAccount = null, disabled = false }) => {
    const dispatch = useDispatch();
    const [errors, setErrors] = useState({});
    const [updatedAddress, setUpdatedAddress] = useState({});
    const states = useSelector(state => state.stateReducer.states);
    const relatedObject = organization === null ? bankAccount : organization;
    const updateRelatedObject = organization === null ? updateBankAccount : updateOrganization;

    useEffect(() => {
        const abortController = new AbortController();

        if (isNullOrUndefinedOrEmpty(states)) {
            dispatch(fetchStates(abortController)).then((fetchedStates) => {
                console.log("Loaded states", fetchedStates);
                dispatch(setStates(fetchedStates));
            }).catch(e => {
                console.error(e);
            });
        }
        if (isNullOrUndefinedOrEmpty(relatedObject.address_id)) {
            setUpdatedAddress({});
        } else {
            dispatch(fetchAddresses(relatedObject.address_id, abortController)).then((fetchedAddress) => {
                setUpdatedAddress(fetchedAddress);
            }).catch(e => {
                console.error(e);
            });
        }
        return () => {
            abortController.abort()
        }
    }, [organization, bankAccount, states]);

    if (isNullOrUndefinedOrEmpty(states)) {
        return <Loading />
    }

    const defaultOption = { label: "Select One", value: -1 };
    const stateOptions = states.length > 0
        ?
        [defaultOption].concat(
            states.map((state) => {
                return { label: state.name, value: parseInt(state.id) }
            })) : [defaultOption];

    const selectedState = parseInt(updatedAddress.state_id || -1);
    return (
        <>
            <div className="form-row">
                <TextInput
                    name="street"
                    label="Address"
                    disabled={disabled}
                    formState={updatedAddress}
                    setFormState={setUpdatedAddress}
                    errors={errors}
                    setErrors={setErrors}
                />
                <TextInput
                    name="street2"
                    label="Address 2"
                    disabled={disabled}
                    formState={updatedAddress}
                    setFormState={setUpdatedAddress}
                    errors={errors}
                    setErrors={setErrors}
                />
            </div>
            <div className="form-row">
                <TextInput
                    name="city"
                    label="City"
                    disabled={disabled}
                    formState={updatedAddress}
                    setFormState={setUpdatedAddress}
                    errors={errors}
                    setErrors={setErrors}
                />
            </div>
            <Dropdown
                id={"state_id"}
                label={"Select a state"}
                disabled={disabled}
                options={stateOptions}
                value={selectedState}
                onChange={(stateId) => {
                    setUpdatedAddress({
                        ...updatedAddress,
                        state_id: parseInt(stateId)
                    });
                }}
                errors={errors}
            />
            <div className="form-row">
                <TextInput
                    name="zip"
                    label="Zip"
                    disabled={disabled}
                    formState={updatedAddress}
                    setFormState={setUpdatedAddress}
                    errors={errors}
                    setErrors={setErrors}
                />
            </div>
            
            {!disabled && <div id='organization-name-update-button'>
                <SubmitButton
                    id="save-address"
                    disabled={disabled}
                    onSubmit={(event) => {
                        event.preventDefault();
                        let action = updateAddress;
                        if (isNullOrUndefinedOrEmpty(updatedAddress.id)) {
                            action = createAddress;
                        }
                        dispatch(action(updatedAddress)).then((returnedAddress) => {
                            return dispatch(updateRelatedObject({
                                ...relatedObject,
                                address_id: returnedAddress.id
                            })).then((returnedRelatedObject) => {
                                if (bankAccount !== null) {
                                    dispatch(setBankAccountAddress(organization, relatedObject, returnedAddress));
                                }
                                setUpdatedAddress(returnedAddress);
                            });
                        }).catch((errs) => {
                            console.error(errs);
                            setErrors({ ...errors, ...errs });
                        });
                    }
                    }>Save Address</SubmitButton>
            </div>}
        </>
    )
}

export default Address
