import React, { useState, useEffect } from 'react';
import { useNavigate, Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { isNullOrUndefinedOrEmpty, userIsNotLoggedIn } from "../../../utils.js";
import { TableHead, TableCell, TableRow, TableContainer, Table, TableBody, Container } from "@mui/material";
import { fetchDatabaseAccounts } from "../../../redux/models/integrations/Action.js";
import { selectOrganization } from "../../../redux/models/organizations/Action.js";


const List = () => {
    const abortController = new AbortController();

    const [databaseAccounts, setDatabaseAccounts] = useState([]);

    const loggedInUser = useSelector(state => state.userReducer.loggedInUser.user);
    const organizations = useSelector(state => state.userReducer.loggedInUser.organizations);
    const selectedOrganization = useSelector(state => state.organizationReducer.selectedOrganization);

    const dispatch = useDispatch();
    const navigate = useNavigate();

    useEffect(() => {
        if (!isNullOrUndefinedOrEmpty(organizations)) {
            dispatch(fetchDatabaseAccounts(abortController)).then((fetchedDatabaseAccounts) => {
                setDatabaseAccounts(fetchedDatabaseAccounts);
            });
            return () => {
                abortController.abort();
            }
        }
    }, [loggedInUser.id, organizations]);

    if (userIsNotLoggedIn(loggedInUser)) {
        navigate("/login");
        return "User is not logged in.";
    }

    const maybeSwitchOrganization = (organization) => {
        if (selectedOrganization.id !== organization.id) {
            dispatch(selectOrganization(organization));
        }
    }

    const noIntegrations = <Link to="/integrations/database/setup">
        You have no database integrations. Go to Integrations, then Setup to add some or click here.
    </Link>;

    return <Container className={"workspace"}>
        <h1>Manage Database Integrations</h1>
        <TableContainer>
            <Table>
                <TableHead>
                    <TableRow>
                        <TableCell>Organization Name</TableCell>
                        <TableCell>Database Account Name</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {
                        isNullOrUndefinedOrEmpty(databaseAccounts) ? noIntegrations : databaseAccounts.sort((a, b) => {
                            return a.name.localeCompare(b.name);
                        }).map((databaseAccount) => {
                            const organization = organizations.find(o => databaseAccount.organization_id === o.id)
                            if (isNullOrUndefinedOrEmpty(organization)) {
                                return ""
                            }
                            return <TableRow key={databaseAccount.id}>
                                <TableCell>
                                    <Link to={`/integrations/database/${databaseAccount.id}/detail`} onClick={() => {
                                        maybeSwitchOrganization(organization);
                                    }}>
                                        {organization.name}
                                    </Link>
                                </TableCell>
                                <TableCell>
                                    <Link to={`/integrations/database/${databaseAccount.id}/detail`} onClick={() => {
                                        maybeSwitchOrganization(organization);
                                    }}>
                                        {databaseAccount.name}
                                    </Link>
                                </TableCell>
                            </TableRow>;
                        })
                    }
                </TableBody>
            </Table>
        </TableContainer>
    </Container>
};

export default List;