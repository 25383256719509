import React from "react";
import { Container, Grid } from "@mui/material";
import { useNavigate } from "react-router-dom";
import AlpineIQAccount from "../../../components/forms/AlpineIQAccount.js";
import { useSelector } from "react-redux";
import { isNullOrUndefinedOrEmpty } from "../../../utils.js";
import Loading from "../../../components/elements/Loading.js";


const Setup = () => {
    const navigate = useNavigate();

    const selectedOrganization = useSelector(state => state.organizationReducer.selectedOrganization);

    if (isNullOrUndefinedOrEmpty(selectedOrganization)) {
        return <Loading />
    }

    return <>
        <Container className={"workspace"}>
            <Grid container spacing={2}>
                <Grid item xs={6} className={"left-part"}>
                    <Grid container spacing={3}>
                        <Grid item xs={12}>
                            <h1 className="catchline">Enter your AlpineIQ account details</h1>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={6} className="right-part">
                    <Grid container spacing={2} className="metrc-form">
                        <Grid item xs={12}>
                            <AlpineIQAccount
                                create={true}
                                onCreate={(newAccount) => {
                                    navigate("/integrations/alpineiq/list");
                                }}
                                onUpdate={(updatedAccount) => {
                                    console.log("Updated")
                                }}
                                onDelete={(accountId) => {
                                    console.log("Deleted")
                                }}
                            />
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </Container>
    </>
}


export default Setup;
