import React from 'react'

const Cultivation = () => {
    return (
        <div>
            Cultivation
        </div>
    )
}

export default Cultivation