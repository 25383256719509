import { combineReducers } from "redux";

import notificationsReducer from "./notifications/Reducer.js";
import animationReducer from "./animations/Reducer.js";
import userReducer from "./user/Reducer.js";
import integrationReducer from "./models/integrations/Reducer.js";
import wholesaleReducer from "./wholesale/Reducer.js"
import stateReducer from "./models/states/Reducer.js";
import setupWizardReducer from "./components/SetupWizard/Reducer.js";
import organizationReducer from "./models/organizations/Reducer.js";
import views from "./views/Reducer.js";

export default combineReducers({
    animationReducer,
    integrationReducer,
    notificationsReducer,
    organizationReducer,
    setupWizardReducer,
    stateReducer,
    userReducer,
    views,
    wholesaleReducer
});
