import React from "react";
import {ScrollEventWrapper} from "../../components/elements/ScrollEventWrapper.js";


export const Title = ({name, text, subtext, actions}) => {

    return <div id={name} className={`full-bleed-title`}>
        <ScrollEventWrapper name={`${text} h1-left`} className="hr" addOnTrigger={["swoop-right"]}
                            addOffTrigger={["hidden"]}>
        </ScrollEventWrapper>
        <ScrollEventWrapper name={`${text} title`} className="full-bleed-content" addOnTrigger={["fade-in"]}
                            addOffTrigger={["hidden"]}>
            <h1 className={"full-bleed-text"}>{text}</h1>
            <h2 className={"full-bleed-subtitle"}>{subtext}</h2>
            <div className="actions">{actions}</div>
        </ScrollEventWrapper>
        <ScrollEventWrapper name={`${text} hr-right`} className="hr" addOnTrigger={["swoop-left"]}
                            addOffTrigger={["hidden"]}>
        </ScrollEventWrapper>
    </div>
}


export default Title;
