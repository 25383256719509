import React from "react";
import Button from "@mui/material/Button/Button.js";
import { isNullOrUndefinedOrEmpty } from "../../utils.js";

const DeleteButton = (props) => {
    return (
        <Button
            className="delete mr-2"
            disabled={props.disabled}
            id={!isNullOrUndefinedOrEmpty(props.id) ? props.id : "delete"}
            variant="contained"
            type="reset"
            color="error"
            onClick={props.onDelete}>
            {props.children}
        </Button>
    );
}

export default DeleteButton;