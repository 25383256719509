import {isEmpty} from "weed-js";

export class RelatedModel {

    constructor(service, uri, foreignKeyName, fields) {
        /*
        @param foreignKeyName: The attribute on the aggregated model that refers to the primay key of the related model.
            For example, if we're aggregating a Product, the foreign key name might be category_id, which refers to the
            primary key on the category table for which this product is a member.
        @

         */
        this.uri = uri;
        this.service = service;
        this.foreignKeyName = foreignKeyName;
        this.fields = fields
        this.primaryKeys = new Set();
        this.instancesById = {};

        this.fields.map(f => {
            f.model = this;
        })
    }

    clone() {
        return new RelatedModel(
            this.service,
            this.uri,
            this.foreignKeyName,
            this.fields.map(f => f.clone())
        );
    }

    fetch(timeseriesResults) {
        const newResults = new Set();

        if(timeseriesResults.length === 0) {
            console.warn("No timeseries results for which to fetch related models.");
            return new Promise((resolve, reject) => resolve([]));
        }

        for(let i = 0; i < timeseriesResults.length; i++) {
            const primaryKey = timeseriesResults[i][this.foreignKeyName];
            if(!this.primaryKeys.has(primaryKey)) {
                if(!isEmpty(primaryKey)) {
                    newResults.add(primaryKey)
                    this.primaryKeys.add(primaryKey);
                }
            }
        }
        if(newResults.size > 0) {
            return this.service.paginate(this.uri, {
                id__in: Array.from(newResults).join(",")
            }).then((instances) => {
                for(let i = 0; i < instances.length; i++) {
                    this.getProjection(instances[i]);
                }
                return instances;
            });
        } else {
            return new Promise((resolve, reject) => resolve([]));
        }
    }

    getProjection(instance) {
        const projection = this.fields.reduce((proj, df) => {
            proj[df.name] = instance[df.name]
            return proj;
        }, {});
        this.instancesById[instance.id] = projection;
    }

    reset() {
        return;
        this.primaryKeys = new Set();
    }

    add(timeseriesElement) {
        timeseriesElement[this.foreignKeyName] &&
        this.primaryKeys.add(timeseriesElement[this.foreignKeyName]);
    }
}

