import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import SubmitButton from "../elements/SubmitButton.js";
import { Button } from "@mui/material";
import TextInput from "../elements/TextInput.js";
import { inviteUser, createUser, enqueueSnackbar } from "../../redux/Actions.js";
import EmailInput from "../elements/EmailInput.js";
import { Dialog, DialogActions, DialogContent, DialogTitle, Grid } from "@mui/material";
import { AddCircleOutline as AddCircleOutlineIcon } from "@mui/icons-material";
import Dropdown from "../elements/Dropdown.js";
import { isNullOrUndefinedOrEmpty, userIsLoggedIn } from "../../utils.js";
import { fetchOrganizationUsers, fetchUsers, setOrganizationUsers } from "../../redux/user/Action.js";
import { showInformationalPopup } from "../../redux/notifications/Action.js";
import { ROUTES_ORGANIZATIONS_LIST, ADMIN_ROLE, SUPERADMIN_ROLE } from "../../utils/Constants.js";
import InputAdornment from '@mui/material/InputAdornment/InputAdornment.js';
import IconButton from '@mui/material/IconButton/IconButton.js';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import Loading from "../elements/Loading.js";


const CreateAccountForm = ({
    displayText = "Create Account",
    organizationId = "",
    onCreated = (user, organizationUser) => { }
}) => {
    const [open, setOpen] = React.useState(false);
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const loggedInUser = useSelector(state => state.userReducer.loggedInUser.user);
    const organizations = useSelector(state => state.userReducer.loggedInUser.organizations);
    const organizationUsers = useSelector(state => state.userReducer.loggedInUser.organizationUsers);
    const loggedInUserIsLoading = useSelector(state => state.userReducer.loading);

    const isAdditional = userIsLoggedIn(loggedInUser);
    const disabled = userIsLoggedIn(loggedInUser) && organizations.length === 0;

    const [selectedOrganization, setSelectedOrganization] = useState(organizations.find(o => o.id === organizationId));
    const [showPopup, setShowPopup] = useState(false);
    const [shouldCheckEmail, setShouldCheckEmail] = useState(false);
    const [showPasswords, setShowPasswords] = useState(false);
    const [loading, setLoading] = useState(false);

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    let initialState = {
        password: "",
        confirm_password: "",
        first_name: "",
        last_name: "",
        email: "",
        username: ""
    }
    if (isAdditional) {
        initialState["organization_id"] = organizationId;
    }

    const [formState, setFormState] = useState(initialState);
    const errorDefaults = {
        password: "",
        confirm_password: "",
        first_name: "",
        last_name: "",
        email: "",
        username: "",
        role: "",
        organization_id: "",
        user_id: ""
    };
    const [errors, setErrors] = useState(errorDefaults);

    if (loggedInUserIsLoading) {
        return <Loading />
    }

    const selectOrganization = (organizationId) => {
        const organization = organizations.find(o => {
            return o.id === parseInt(organizationId)
        });
        setSelectedOrganization(organization);
        setFormState({ ...formState, organization_id: organization.id });
    }

    const getOrganizationOptions = () => {
        if (isAdditional) {
            const invitationCandidates = organizations.filter(org => {
                const orgUser = organizationUsers.find(ou => {
                    return ou.organization_id === org.id
                });
                if (isNullOrUndefinedOrEmpty(orgUser)) {
                    return false;
                }
                const userIsAdminOrHigher = [ADMIN_ROLE, SUPERADMIN_ROLE].includes(orgUser.role);
                if (userIsAdminOrHigher && org.is_active) {
                    return true;
                }
                return false;
            });
            const options = [
                { label: "Choose an organization", value: "" },
                ...invitationCandidates.map(organization => {
                    return {
                        label: organization.name,
                        value: organization.id
                    };
                })];
            return <>
                <Grid item xs={12}>
                    <Dropdown
                        id={"organization_id"}
                        onChange={selectOrganization}
                        errors={errors}
                        value={selectedOrganization ? selectedOrganization.id : organizationId}
                        options={options}
                    />
                </Grid>
            </>
        } else if (disabled) {
            return <span>
                You have to
                <span className="clickable" onClick={() => { navigate(ROUTES_ORGANIZATIONS_LIST + "/?open=1") }}>add an organization</span>
                before you can invite other users.
            </span>
        }
    }

    if (showPopup) {
        dispatch(showInformationalPopup(
            {
                title: "Check Your E-Mail",
                message: "We've sent you an email to verify your account. Click the link and you'll be ready to get started!"
            }
        ));
    }


    const promptForPassword = () => {
        return <>
            <Grid item xs={6}>
                <TextInput name="password" label="Password" formState={formState}
                    errors={errors} setFormState={setFormState} setErrors={setErrors}
                    InputProps={{
                        endAdornment: (
                            <InputAdornment position="end">
                                <IconButton
                                    aria-label="toggle password visibility"
                                    onClick={() => setShowPasswords(!showPasswords)}
                                    onMouseDown={(event) => event.preventDefault()}
                                    edge="end"
                                >
                                    {showPasswords ? <VisibilityOff /> : <Visibility />}
                                </IconButton>
                            </InputAdornment>
                        )
                    }}
                    type={passwordType}
                />
            </Grid>
            <Grid item xs={6}>
                <TextInput name="confirm_password" label="Confirm Password"
                    formState={formState} errors={errors} setFormState={setFormState}
                    setErrors={setErrors}
                    InputProps={{
                        endAdornment: (
                            <InputAdornment position="end">
                                <IconButton
                                    aria-label="toggle password visibility"
                                    onClick={() => setShowPasswords(!showPasswords)}
                                    onMouseDown={(event) => event.preventDefault()}
                                    edge="end"
                                >
                                    {showPasswords ? <VisibilityOff /> : <Visibility />}
                                </IconButton>
                            </InputAdornment>
                        )
                    }}
                    type={passwordType}
                />
            </Grid>
        </>
    }

    const displaySignupForm = () => {
        return <form className="content-container">
            <input name="id" hidden defaultValue={formState.id} />
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <EmailInput name="email" label="Email Address" formState={formState} errors={errors} disabled={disabled}
                        setFormState={setFormState} setErrors={setErrors} />
                </Grid>
                <Grid item xs={6}>
                    <TextInput name="first_name" label="First Name" formState={formState} disabled={disabled}
                        errors={errors}
                        setFormState={setFormState} setErrors={setErrors} />
                </Grid>
                <Grid item xs={6}>
                    <TextInput name="last_name" label="Last Name" formState={formState} errors={errors} disabled={disabled}
                        setFormState={setFormState} setErrors={setErrors} />
                </Grid>
                {isAdditional ? "" : promptForPassword()}
                {getOrganizationOptions()}
            </Grid>
        </form>
    }

    const displayCheckYourEmail = () => {
        return <div className="check-your-email content-container">
            <h3>Check Your E-Mail</h3>
            <p>We've sent you an email to verify your account.</p>
            <p>Click the link and you'll be ready to get started!</p>
            <p>Click <span className="clickable" onClick={() => { setShouldCheckEmail(false) }}>here</span> if you made mistake filling out the form.</p>
        </div>
    }

    const displayLoading = () => {
        return <div className="content-container">
            <Loading title={"Please wait while we create your account"} />
        </div>
    }

    const passwordType = showPasswords ? "text" : "password";
    const notifyErrors = (errs) => {
        setErrors({ ...errors, ...errs });
        if (isAdditional) {
            dispatch(enqueueSnackbar({
                message: errs.error || "An error occured while inviting the user. Please try again.",
                options: {
                    variant: 'error',
                },
            }));

        } else {
            dispatch(enqueueSnackbar({
                message: errs.error || "An error occured while creating the user. Please try again.",
                options: {
                    variant: 'error',
                },
            }));
        }
    }

    const createNewUser = () => {
        dispatch(createUser(formState)).then((responseMessage) => {
            setShouldCheckEmail(true);
            setLoading(false);
        }).catch((errs) => {
            setLoading(false);
            notifyErrors(errs);
        });
    }

    const inviteNewUser = () => {
        dispatch(inviteUser(formState)).then((responseMessage) => {
            dispatch(fetchUsers({ username: formState.email })).then(fetchedUsers => {
                const createdUser = fetchedUsers[0];
                if (!isNullOrUndefinedOrEmpty(formState.organization_id) && !isNullOrUndefinedOrEmpty(selectedOrganization)) {
                    dispatch(fetchOrganizationUsers(selectedOrganization.id)).then(fetchedOrganizationUsers => {
                        dispatch(setOrganizationUsers(fetchedOrganizationUsers))
                        setLoading(false);
                        onCreated(createdUser, fetchedOrganizationUsers.find(u => u.user_id === createdUser.id && u.organization_id === selectedOrganization.id));
                    });
                }
                setLoading(false);
                setFormState(initialState);
            });
        }).catch((errs) => {
            setLoading(false);
            notifyErrors(errs)
        });
    }

    const passwordsMatch = () => {
        if (formState.password !== formState.confirm_password) {
            setErrors({ ...errors, confirm_password: "Passwords don't match." });
            return false;
        }
        return true;
    }

    const organizationIsSelected = () => {
        if (isAdditional && isNullOrUndefinedOrEmpty(selectedOrganization)) {
            setErrors({ ...errors, organization_id: "Please select an organization." });
            return false;
        }
        return true;
    }

    return (
        <>
            <div className="login-form">
                <Button onClick={handleClickOpen} id={isNullOrUndefinedOrEmpty(organizationId) ? "create-new-account" : `add-another-user-to-${organizationId}`}>
                    <AddCircleOutlineIcon className="pr-1" />
                    {displayText}
                </Button>
                <Dialog open={open} onClose={handleClose} className="create-account-dialog">
                    <DialogTitle>{displayText}</DialogTitle>
                    <DialogContent>
                        {loading ? displayLoading() : (shouldCheckEmail ? displayCheckYourEmail() : displaySignupForm())}
                    </DialogContent>
                    <DialogActions>
                        <Grid container spacing={1}>
                            <Grid xs={4} item>
                                {isAdditional ? "" : <Link to="/forgot-password">Forgot Password</Link>}
                            </Grid>
                            <Grid xs={8} item style={{ display: "flex" }} className="actions" justifyContent="right"
                                justifyItems="right" alignContent="right" alignItems="right">
                                <SubmitButton
                                    id={isAdditional ? "add-user-button" : "create-account-button"}
                                    className={shouldCheckEmail ? "hidden" : ""}
                                    disabled={userIsLoggedIn(loggedInUser) && organizations.length === 0 && loading}
                                    onClick={(event) => {
                                        event.preventDefault();

                                        if (!passwordsMatch() || !organizationIsSelected()) {
                                            return;
                                        }

                                        if (!isNullOrUndefinedOrEmpty(loggedInUser)) {
                                            formState.organization_id = selectedOrganization.id;
                                        }

                                        setLoading(true);

                                        if (isAdditional) {
                                            inviteNewUser();
                                        } else {
                                            createNewUser();
                                        }
                                    }}>{isAdditional ? "Invite" : "Save"}</SubmitButton>
                            </Grid>
                        </Grid>
                    </DialogActions>
                </Dialog>
            </div>
        </>
    );
};

export default CreateAccountForm;
