import React, {useState, useEffect} from "react";

import {Chart as ChartJS, registerables} from "chart.js";
import {isEmpty, Client, getCookie} from "weed-js";
import {Report} from "../observers/classes/Report.js";
import {Divide, Average, Subtract, Add, Calculation, F, Multiply, Sum} from "../observers/classes/Calculation.js";
import {Field} from "../observers/classes/Field.js";
import {RelatedModel} from "../observers/classes/RelatedModel.js";
import {TimeRangeQuery, Rollups} from "../observers/classes/TimeRangeQuery.js";

import ObserverChart from "../observers/ObserverChart.js";
import {Grid} from "@mui/material";
import {useDispatch} from "react-redux";
import {fetchLeaflogixAccounts} from "../../../redux/models/integrations/Action";

ChartJS.register(...registerables);

const client = new Client();
const margin = new Subtract(new F("unit_cost"), new F("unit_price"));
const calculation = new Calculation("Average Margin", margin, Average);

const dutchieMarginReport = new Report(
    client.dutchie.v1(),
    "/items/observations/",
    "Dutchie Margin Over Time",
    [
        new Field("quantity_sum", {displayName: "Item Quantity", cast: parseFloat}),
        new Field("total_discount_sum", {displayName: "Total Discount", cast: parseFloat}),
        new Field("total_price_sum", {displayName: "Total Price", cast: parseFloat}),
        new Field("unit_cost", {displayName: "Unit Cost", cast: parseFloat}),
        new Field("unit_price", {displayName: "Unit Price", cast: parseFloat}),
        new Field("unit_weight", {displayName: "Unit Weight", cast: parseFloat}),
        new Field("unit_weight_unit", {displayName: "Unit Weight Unit", cast: (value) => value.toString()}),
        new Field("observations", {observations: true}),
    ],
    [
        new RelatedModel(
            client.dutchie.v1(),
            "/employees/",
            "employee_id",
            [new Field("external_id", {
                displayName: "Employee ID",
                filterable: true,
                groupable: true,
                cast: parseInt
            })]),
        new RelatedModel(
            client.dutchie.v1(),
            "/products/",
            "product_id",
            [new Field("name", {displayName: "Product", filterable: true, groupable: true})]
        ),
        new RelatedModel(
            client.dutchie.v1(),
            "/vendors/",
            "vendor_id",
            [new Field("name", {displayName: "Vendor", filterable: true, groupable: true})]
        ),
    ],
    calculation,
    "This report gives the average cost - price of each item sold over the given time range. Select filters and groups to explore more.",
)

const dutchieMarginReport2 = dutchieMarginReport.clone(
    "Dutchie Margin Over Time",
    "This report gives the average cost - price of each item sold over the given time range. Select filters and groups to explore more.",
    new Calculation("Units Sold", new F("quantity_sum"), Sum)
);

const dutchieUnitsSoldReport = dutchieMarginReport.clone(
    "Dutchie Units Sold Over Time",
    "This report gives the total quantity of units sold by whatever breakdown you choose over the given time range.",
    new Calculation("Units Sold", new F("quantity_sum"), Sum)
);

const dutchieUnitsSoldReport2 = dutchieMarginReport.clone(
    "Dutchie Units Sold Over Time",
    "This report gives the total quantity of units sold by whatever breakdown you choose over the given time range.",
    new Calculation("Units Sold", new F("quantity_sum"), Sum)
);

const dutchieCostOverTimeReport = dutchieMarginReport.clone(
    "Dutchie Costs Over Time",
    "This report shows how your costs have changed over time. Apply filters and groups to explore",
    new Calculation("Cost", new Multiply(new F("quantity_sum"), new F("unit_cost")), Average)
);

const dutchieCostOverTimeReport2 = dutchieMarginReport.clone(
    "Dutchie Costs Over Time",
    "This report shows how your costs have changed over time. Apply filters and groups to explore",
    new Calculation("Cost", new Multiply(new F("quantity_sum"), new F("unit_cost")), Average)
);


const RetailCostAndMargin = ({organizationId, startDate, endDate}) => {

    const dispatch = useDispatch();
    const [selectedDutchieAccounts, setSelectedDutchieAccounts] = useState([]);
    const [rollup, setRollup] = useState(Rollups.DAILY);

    useEffect(() => {
        dispatch(fetchLeaflogixAccounts({organization_id: organizationId})).then(fetchedAccounts => {
            setSelectedDutchieAccounts(fetchedAccounts)
            dutchieMarginReport.query(
                startDate,
                endDate,
                rollup,
                {"dutchie_account_id__in": fetchedAccounts.map(a => a.id).join(",")}
            );
        });
    }, [organizationId]);

    useEffect(() => {
        if (isEmpty(selectedDutchieAccounts) || dutchieMarginReport.isLoading()) {
            return;
        }
        dutchieMarginReport.query(
            startDate,
            endDate,
            rollup,
            {"dutchie_account_id__in": selectedDutchieAccounts.map(a => a.id).join(",")}
        );
    }, [organizationId, startDate, endDate, rollup, selectedDutchieAccounts.map(a => a.id).join(",")]);

    return <div>
        <ObserverChart report={dutchieMarginReport} chartType={"bar"}/>
        <ObserverChart report={dutchieMarginReport2} chartType={"doughnut"}/>
        <ObserverChart report={dutchieUnitsSoldReport} chartType={"line"}/>
        <ObserverChart report={dutchieUnitsSoldReport2} chartType={"pie"}/>
        <ObserverChart report={dutchieCostOverTimeReport} chartType={"line"}/>
        <ObserverChart report={dutchieCostOverTimeReport2} chartType={"pie"}/>
    </div>
}

export default RetailCostAndMargin;
