import React from 'react';
import { useDispatch, useSelector } from "react-redux";
import { MyAccount as UserAccount } from "../components/forms/MyAccount.js";
import { isNullOrUndefinedOrEmpty } from "../utils.js";
import "./styles/MyAccount.css";
import { Container } from "@mui/material";
import { userLoggedIn } from "../redux/user/Action.js";
import Loading from '../components/elements/Loading.js';


const MyAccount = () => {
    console.log("Rendering MyAccount");
    const loggedInUser = useSelector(state => state.userReducer.loggedInUser.user);

    const dispatch = useDispatch();

    if (isNullOrUndefinedOrEmpty(loggedInUser)) {
        return <Container className="workspace">
            <Loading />
        </Container>
    }

    console.log("Rendering workspace");
    return <Container className="workspace">
        <h1>My Account</h1>
        <UserAccount
            {...loggedInUser}
            onUpdate={(updatedUser) => {
                dispatch(userLoggedIn({ user: updatedUser }));
            }}
        />
    </Container>
};

export default MyAccount;
