import React, {useState } from "react";
import ReactTable from "react-table-v6";
import { getTotalForField, isNullOrUndefinedOrEmpty, groupListByField } from "../../../utils.js";
import { currencyFormatter, numberFormatter } from "../../../utils.js";
import {isEmpty} from "weed-js";


const Leaderboard = ({salesReport}) => {

    const [sortOptions, setSortOptions] = useState([{ id: "total", desc: true }])
    const title = <h3>Sales Rep Leaderboard</h3>;

    const bySalesRepHeaders = [
        {
            Header: "Sales Rep",
            accessor: "sales_rep",
            id: "sales_rep"
        },
        {
            Header: "Total Quantity",
            accessor: "quantity",
            id: "quantity",
        },
        {
            Header: "Total Value",
            accessor: "total",
            id: "total",
        }
    ]

    if (isEmpty(salesReport)) {
        return "";
    }

    const salesBySalesRep = (report) => {
        if (isNullOrUndefinedOrEmpty(report)) {
            return [];
        }
        let aggregate = [];
        for (const [salesRep, entriesBySalesRep] of Object.entries(groupListByField(report, "sales_rep"))) {
            const totalQuantity = getTotalForField(entriesBySalesRep, "quantity");
            const totalSold = getTotalForField(entriesBySalesRep, "total");
            aggregate.push({ "sales_rep": salesRep, total: currencyFormatter.format(totalSold), quantity: numberFormatter.format(totalQuantity)});
        }
        return aggregate;
    };

    const bySalesRep = salesBySalesRep(salesReport) || [];

    return <div>
        {title}
        <ReactTable
            sorted={sortOptions}
            onSortedChange={(newSorted, column, shiftKey) => {
                setSortOptions(newSorted);
            }}
            showPaginationBottom={bySalesRep && bySalesRep.length > 10}
            getProps={() => { return { id: "sales-report-table" } }}
            columns={bySalesRepHeaders}
            sortOptions={[{ id: "total", desc: true }]}
            pageSize={bySalesRep.length}
            className="-striped -highlight mb-3"
            data={bySalesRep}
        />
    </div>

}

export default Leaderboard;