import {
    USER_LOGGED_IN,
    SET_LOGO,
    SET_BANK_ACCOUNTS,
    SET_BANK_ACCOUNT_ADDRESS,
    SET_USER_PROFILE,
    SET_ORGANIZATION_USERS,
    SET_ARRIVED_VIA_INVITATION,
    UPDATE_ACCOUNT_STATUS,
    ADD_ACCOUNT_STATUS,
    LOGGED_IN_USER_IS_LOADING
} from '../constants.js';
import { isNullOrUndefinedOrEmpty } from "../../utils.js";

const defaultState = {
    loggedInUser: {
        user: null,
        profile: null,
        organizationUsers: [],
        organizations: [],
        organizationUserGroups: [],
        accountStatuses: [],
        arrivedViaInvitation: null,
    },
    loading: false
};

const UserReducer = (state = defaultState, action) => {
    switch (action.type) {
        case LOGGED_IN_USER_IS_LOADING:
            return {
                ...state,
                loading: action.isLoading
            }
        case ADD_ACCOUNT_STATUS:
            return {
                ...state,
                loggedInUser: {
                    ...state.loggedInUser,
                    accountStatuses: [...state.loggedInUser.accountStatuses, action.accountStatus]
                }
            }
        case SET_ARRIVED_VIA_INVITATION:
            return {
                ...state,
                loggedInUser: {
                    ...state.loggedInUser,
                    arrivedViaInvitation: true
                }
            }
        case SET_LOGO:
            return {
                ...state,
                loggedInUser: {
                    ...state.loggedInUser,
                    organizations: state.loggedInUser.organizations.map((organization) => {
                        if (parseInt(organization.id) === parseInt(action.organizationId)) {
                            organization.logo = action.logo;
                        }
                        return organization;
                    })
                }
            }
        case UPDATE_ACCOUNT_STATUS:
            return {
                ...state,
                loggedInUser: {
                    ...state.loggedInUser,
                    accountStatuses: state.loggedInUser.accountStatuses.map((accountStatus) => {
                        if (accountStatus.organization_id === action.accountStatus.organization_id) {
                            return action.accountStatus;
                        }
                        return accountStatus;
                    })
                }
            }
        case USER_LOGGED_IN:
            const newState = {
                ...state,
                loggedInUser: {
                    user: action.user,
                    profile: !isNullOrUndefinedOrEmpty(action.profile) ? action.profile : state.loggedInUser.profile,
                    organizationUsers: !isNullOrUndefinedOrEmpty(action.organizationUsers) ? action.organizationUsers : state.loggedInUser.organizationUsers,
                    organizations: !isNullOrUndefinedOrEmpty(action.organizations) ? action.organizations : state.loggedInUser.organizations,
                    organizationUserGroups: !isNullOrUndefinedOrEmpty(action.organizationUserGroups) ? action.organizationUserGroups : state.loggedInUser.organizationUserGroups,
                    accountStatuses: !isNullOrUndefinedOrEmpty(action.accountStatuses) ? action.accountStatuses : state.loggedInUser.accountStatuses
                }
            };
            return newState;
        case SET_ORGANIZATION_USERS:
            return {
                ...state,
                loggedInUser: {
                    ...state.loggedInUser,
                    organizationUsers: action.organizationUsers,
                }
            };

        case SET_USER_PROFILE:
            return {
                ...state,
                loggedInUser: {
                    ...state.loggedInUser,
                    profile: action.profile,
                }
            };

        case SET_BANK_ACCOUNTS:
            return {
                ...state,
                loggedInUser: {
                    ...state.loggedInUser,
                    organizations: state.loggedInUser.organizations.map((organization) => {
                        if (parseInt(organization.id) === parseInt(action.organization.id)) {
                            organization.bankAccounts = action.bankAccounts;
                        }
                        return organization;
                    })
                }
            };
        case SET_BANK_ACCOUNT_ADDRESS:
            const organizations = isNullOrUndefinedOrEmpty(state.loggedInUser.organizations) ? [] : state.loggedInUser.organizations.map((organization) => {
                if (isNullOrUndefinedOrEmpty(organization.bankAccounts)) {
                    return organization;
                }
                organization.bankAccounts = organization.bankAccounts.map(ba => {
                    if (ba.id === action.bankAccount.id) {
                        ba.address_id = action.address.id;
                    }
                    return ba;
                });
                return organization;
            });
            return {
                ...state,
                loggedInUser: {
                    ...state.loggedInUser,
                    organizations: organizations
                }
            };

        default:
            return state;
    }
};


export default UserReducer;
