import { JSONPath } from "./jsonpath.js";

export class Segment {
    constructor(name, indices) {
        this.name = name;
        this.indices = indices || [];
    }

    static toPath(segments) {
        const path = new JSONPath(
            segments.reduce(
                (stringSegments, segment) => {
                    const stringIndices = segment.indices.length > 0 ? "[" + segment.indices.join("][") + "]" : "";
                    const stringSegment = segment.name + stringIndices;
                    stringSegments.push(stringSegment);
                    return stringSegments;
                },
                []
            ).join(".")
        );
        return path;
    }

    static fromPath(path) {
        if (path.value === undefined) {
            console.warn("You asked to get segments from a path which is undefined. This is probably an error! Returning []");
            return [];
        }
        const asString = path.value.split(".");
        const re = /\[(\d+)\]/;
        return asString.map(stringSegment => {
            if (stringSegment === undefined) {
                console.warn("String segment was unexpectedly undefined. Returning an empty segment. This will probably cause later errors.");
                return new Segment();
            }
            const split = stringSegment.split(re);
            const name = split[0];
            const indexes = split.slice(1, -1)
            const segmentIndexes = indexes.reduce((indexes, maybeIndex) => {
                const idx = parseInt(maybeIndex);
                if (!isNaN(idx)) {
                    indexes.push(idx);
                }
                return indexes;
            }, []);
            return new Segment(name, segmentIndexes);
        });
    }
}