import React from "react";
import DeleteButton from "../elements/DeleteButton.js";
import SubmitButton from "../elements/SubmitButton.js";
import { ADMIN_ROLE, INVITED_ROLE, MEMBER_ROLE } from "../../utils/Constants.js";
import { deleteOrganizationUser, fetchOrganizations } from "../../redux/user/Action.js";
import { useDispatch, useSelector } from "react-redux";
import { updateUserRole, userLoggedIn } from "../../redux/user/Action.js";
import { showConfirmationPopup } from "../../redux/notifications/Action.js";
import { isNullOrUndefinedOrEmpty } from "../../utils.js";
import { enqueueSnackbar } from "../../redux/Actions.js";
import { uniqueByAttribute, getCookie, setCookie, removeCookie } from "../../utils.js";
import { ORGANIZATION_COOKIE } from "../../redux/constants.js";


export const OrganizationButtons = ({ organization }) => {

    const dispatch = useDispatch();

    const loggedInUser = useSelector(state => state.userReducer.loggedInUser.user);
    const organizationUsers = useSelector(state => state.userReducer.loggedInUser.organizationUsers);
    const organizations = useSelector(state => state.userReducer.loggedInUser.organizations);

    const onRoleUpdate = (organization, newRole) => {
        setCookie(ORGANIZATION_COOKIE, organization.id);
        dispatch(
            userLoggedIn({
                user: loggedInUser,
                organizationUsers: organizationUsers.map(ou => {
                    if (ou.organization_id === organization.id) {
                        return { ...ou, role: newRole }
                    }
                    return ou
                }),
                organizations: uniqueByAttribute([...organizations, organization], "id")
            })
        );
    };

    const onOrganizationLeave = (organizationId) => {
        dispatch(enqueueSnackbar({
            message: `You have left ${organization.name}.`,
            options: { variant: "success" }
        }));
        dispatch(userLoggedIn({
            user: loggedInUser,
            organizationUsers: organizationUsers.filter(ou => {
                return ou.organization_id !== organizationId;
            }),
            organizations: organizations
        }));
        if (parseInt(getCookie(ORGANIZATION_COOKIE)) === parseInt(organizationId)) {
            removeCookie(ORGANIZATION_COOKIE);
        }
    };

    const invitationButtons = () => {
        return (
            <div className={"invitation-buttons"}>
                <div id={`accept-invitation-to-${organization.id}`}>
                    <SubmitButton
                        onSubmit={() => {
                            dispatch(updateUserRole(
                                organizationUsers.find(ou => ou.organization_id === organization.id).id,
                                MEMBER_ROLE,
                                "Invitation accepted."
                            )).then(() => {
                                dispatch(fetchOrganizations([organization.id])).then(newOrganization => {
                                    onRoleUpdate(organization, MEMBER_ROLE);
                                })
                            });

                        }}>Accept</SubmitButton>
                </div>
                <div id={`reject-invitation-to-${organization.id}`}>
                    <DeleteButton
                        onDelete={() => {
                            dispatch(deleteOrganizationUser(
                                organizationUsers.find(ou => ou.organization_id === organization.id).id
                            )).then(() => {
                                onOrganizationLeave(organization.id);
                            });
                        }}>Reject</DeleteButton>
                </div>
            </div>
        )
    };

    const leaveOrganizationButton = () => {
        return (
            <DeleteButton
                id={`leave-${organization.id}`}
                onDelete={() => {
                    console.log("Running onDelete");
                    dispatch(
                        showConfirmationPopup(
                            "Leave Organization",
                            `Are you sure you want to leave ${organization.name}?`,
                            () => {
                                dispatch(deleteOrganizationUser(
                                    organizationUsers.find(ou => ou.organization_id === organization.id).id
                                )).then(() => {
                                    onOrganizationLeave(organization.id);
                                });
                            },
                            () => { },
                            `leave-${organization.id}-confirm`
                        )
                    )
                }}>Leave</DeleteButton>
        )
    };

    const getOrganizationButtons = () => {
        const organizationUser = organizationUsers.find(ou => {
            return ou.organization_id === organization.id
        });
        if (isNullOrUndefinedOrEmpty(organizationUser)) {
            return <p>You are no longer a member of this organization.</p>
        }
        const role = organizationUser.role;
        switch (role) {
            case INVITED_ROLE: {
                return invitationButtons();
            }
            case MEMBER_ROLE: {
                return leaveOrganizationButton();
            }
            case ADMIN_ROLE: {
                return leaveOrganizationButton();
            }
            default: {
                return <></>;
            }
        }
    };

    return getOrganizationButtons();
}


export default OrganizationButtons