import { isEmpty } from './utils.js';

export const SUPERADMIN_ROLE = 'superadmin';
export const ADMIN_ROLE = 'admin';
export const MEMBER_ROLE = 'member';
export const INVITED_ROLE = 'invited';

export const userCanSeeOrganization = (userOrganizations, organization) => {
    let canSee = false;
    if (isEmpty(organization)) {
        return false;
    }
    userOrganizations.map((uo) => {
        if (uo.organization_id === organization.id) {
            if (uo.role === ADMIN_ROLE || uo.role === SUPERADMIN_ROLE || uo.role === MEMBER_ROLE) {
                canSee = true
            }
        }
    });
    return canSee;
}

export const memberIsAdmin = (user, organizationUsers, organization) => {
    if (isEmpty(user) || isEmpty(organization)) {
        return false;
    }
    if (user.is_superuser === true) {
        return true;
    }
    const admin = organizationUsers.find(ou => {
        if (ou.user_id !== user.id) {
            return false;
        }
        if (ou.organization_id !== organization.id) {
            return false;
        }
        if (ou.role !== ADMIN_ROLE && ou.role !== SUPERADMIN_ROLE) {
            return false;
        }
        return true;
    });
    return !isEmpty(admin);
}

export const memberIsSuperAdmin = (user, organizationUsers, organization) => {
    if (isEmpty(user)) {
        return false;
    }
    if (user.is_superuser === true) {
        return true;
    }
    const admin = organizationUsers.find(ou => {
        if (ou.user_id !== user.id) {
            return false;
        }
        if (ou.organization_id !== organization.id) {
            return false;
        }
        if (ou.role !== SUPERADMIN_ROLE) {
            return false;
        }
        return true;
    });
    return !isEmpty(admin);
}
