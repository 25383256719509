import axios from "axios";
import {getCookie, isEmpty, randomChoice} from "../utils.js";

export const ABORTED = "ABORTED";


const buildBackend = (subDomain, tld, prefix) => {
    const baseURL = getBaseUrl(subDomain, tld, prefix);
    let instance = axios.create({
        baseURL: baseURL,
        headers: {
            "Accept": "application/json",
            "Content-Type": "application/json",
        },
    });

    instance.interceptors.response.use(function (response) {
        return response;
    }, function (error) {
        if (error.response === undefined) {
            return Promise.reject(error);
        }
        if (error.response.status === 401 || error.response.status === 403) {
            console.error("User is not authenticated or not authorized");
            return Promise.reject(error);
        } else {
            return Promise.reject(error);
        }
    });

    instance.defaults.xsrfHeaderName = "X-CSRFToken";
    instance.defaults.xsrfCookieName = "csrftoken2";
    instance.defaults.withCredentials = true;

    return instance;
};


export const getBaseUrl = (subDomain, tld, apiPrefix) => {
    const domain = getDomain(subDomain, tld);
    return `${process.env.REACT_APP_HTTP_SCHEME}://${domain}${apiPrefix}`;
};

export const getDomain = (subDomain, tld) => {
    return subDomain === null || subDomain === "" ? tld : `${subDomain}.${tld}`;
};

export const getSubdomains = (subDomainsArg) => {
    if (isEmpty(subDomainsArg)) {
        return [null];
    }
    const subDomains = JSON.parse(subDomainsArg);
    if (subDomains.length === 0) {
        subDomains.push(null);
    }
    return subDomains;
};

class Backend {
    constructor(subDomain, tld, prefix) {
        this.instance = buildBackend(subDomain, tld, prefix);
    }

    get = (url, params, abortController) => {
        const options = {
            params: params,
        }
        if (abortController) {
            options.signal = abortController.signal
        }
        return this.instance.get(url, options);
    }

    post = (url, data, options) => {
        return this.instance.post(url, data, options);
    }

    put = (url, data, options) => {
        return this.instance.put(url, data, options);
    }

    patch = (url, data, options) => {
        return this.instance.patch(url, data, options);
    }

    delete = (url, options) => {
        const response = this.instance.delete(url, options);
        return response;
    }

    paginate = (url, params, abortController) => {
        let hooks = [];
        if (!isEmpty(params) && !isEmpty(params.hooks)) {
            hooks = params.hooks;
            delete params["hooks"];
        }
        return this.get(url, params, abortController).then(response => {
            if (isEmpty(response)) {
                console.warn("Got an empty response for url", url, "params", params);
                return [];
            }
            if (response.data.next) {
                return this.paginate(response.data.next, {}, abortController).then(innerResults => {
                    hooks.forEach(hook => {
                        hook.callback(response.data.results);
                    })
                    return response.data.results.concat(innerResults);
                });
            }
            hooks.forEach(hook => {
                hook.callback(response.data.results);
            })
            return response.data.results;
        });
    }
}


class BackendBaseClass {

    constructor(subDomains, prefix) {
        this.subDomains = subDomains;
        this.prefix = prefix;
    }

    getBackendTLD() {
        throw new Error("Not implemented");
    }

    getFrontendTLD() {
        throw new Error("Not implemented");
    }

    getFrontendBaseUrl() {
        return getBaseUrl("", this.getFrontendTLD(), "");
    }

    getBackendBaseURL() {
        return randomChoice(this.subDomains.map((subDomain) => {
            return getBaseUrl(subDomain, this.getBackendTLD(), this.prefix);
        }));
    }

    v1() {
        return randomChoice(this.subDomains.map((subDomain) => {
            return new Backend(subDomain, this.getBackendTLD(), `${this.prefix}/v1`);
        }));
    }

    v2() {
        return randomChoice(this.subDomains.map((subDomain) => {
            return new Backend(subDomain, this.getBackendTLD(), `${this.prefix}/v2`);
        }));
    }

    v3() {
        return randomChoice(this.subDomains.map((subDomain) => {
            return new Backend(subDomain, this.getBackendTLD(), `${this.prefix}/v3`);
        }));
    }

}


class APIClient extends BackendBaseClass {

    getBackendTLD() {
        return getCookie("TEST_API_TLD") || process.env.REACT_APP_API_TLD;
    }

    getFrontendTLD() {
        return getCookie("TEST_FRONTEND_TLD") || process.env.REACT_APP_FRONTEND_TLD;
    }

}


class DALClient extends BackendBaseClass {

    getBackendTLD() {
        return getCookie("TEST_API_TLD") || process.env.REACT_APP_DAL_TLD || "";
    }

}


class SSOClient extends BackendBaseClass {

    getBackendTLD() {
        return getCookie("TEST_SSO_TLD") || process.env.REACT_APP_SSO_TLD;
    }

    getFrontendTLD() {
        return process.env.REACT_APP_SSO_FRONTEND
    }

    getLoginURL() {
        return this.getSSOFrontendBaseUrl() + "?a=7";
    }

    getLogoutURL() {
        return this.getSSOFrontendBaseUrl() + "?a=6";
    }

}


class DutchieClient extends BackendBaseClass {

    getBackendTLD() {
        return getCookie("TEST_DUTCHIE_TLD") || process.env.REACT_APP_DUTCHIE_TLD || "localhost:4200";
    }

}

class AlpineIQClient extends BackendBaseClass {

    getBackendTLD() {
        return getCookie("TEST_ALPINEIQ_TLD") || process.env.REACT_APP_ALPINEIQ_TLD || "localhost:8080";
    }

}


class MetrcClient extends BackendBaseClass {

    getBackendTLD() {
        return getCookie("TEST_METRC_TLD") || process.env.REACT_APP_METRC_TLD || "localhost:8888";
    }

}

const apiSubdomains = getSubdomains(process.env.REACT_APP_API_SUBDOMAINS);
const dalSubdomains = getSubdomains(process.env.REACT_APP_DAL_SUBDOMAINS || process.env.REACT_APP_API_SUBDOMAINS);
const ssoSubDomains = getSubdomains(process.env.REACT_APP_SSO_API_SUBDOMAINS);


export class Client {

    constructor() {
        this.api = new APIClient(apiSubdomains, "/api");
        this.dal = new DALClient(dalSubdomains, "/dal");
        this.dutchie = new DutchieClient([null], "/api");
        this.alpineiq = {
            api: new AlpineIQClient([null], "/api"),
            dal: new AlpineIQClient([null], "/dal")
        };
        this.metrc = new MetrcClient([null], "/api");
        this.sso = {
            api: new SSOClient(ssoSubDomains, "/api"),
            dal: new SSOClient(ssoSubDomains, "/dal")
        }
    }

    static aborted(abortController) {
        return abortController && abortController.signal && abortController.signal.aborted;
    }

    static getSuccessMessage = (response, fallback) => {
        if (isEmpty(response) || isEmpty(response.data)) {
            return isEmpty(fallback) ? "Success" : fallback;
        }
        if (!isEmpty(response.data.detail)) {
            return response.data.detail;
        }
        if (!isEmpty(response.data.message)) {
            return response.data.message;
        }
        return isEmpty(fallback) ? "Success" : fallback;
    }

    static getErrorMessage = (err, abortController) => {
        /*
        Returns [non-field-errors, field-errors]
         */
        if (isEmpty(err) || isEmpty(err.response) || isEmpty(err.response.data)) {
            return ["An unexpected error occurred. Please try again later.", {}];
        }
        if (Client.aborted(abortController)) {
            return [ABORTED, {}];
        }
        if (err.response.status < 400) {
            return [null, {status: err.response.status}];
        }
        const response = err.response;
        const data = {...response.data, status: response.status};
        if (!isEmpty(data.detail)) {
            return [data.detail, {...data}];
        } else if (!isEmpty(data.message)) {
            return [data.message, {...data}];
        } else if (!isEmpty(data.non_field_errors)) {
            return [data.non_field_errors, {...data}];
        } else if (response.status === 401) {
            return ["You must be logged in to do that.", {...data}];
        } else if (response.status === 403) {
            return ["You don't have privileges to do that.", {...data}];
        }
        console.warn("No error message found for response/error: ", response, err);
        return ["An unexpected error occurred. Please try again later.", {...data}];
    }
};

export default new Client();
