import React, {useEffect, useState} from "react";

import {Chart as ChartJS, registerables} from "chart.js";
import {Client, isEmpty} from "weed-js";
import {Report} from "../observers/classes/Report.js";
import {F, Calculation, Divide, Sum, Multiply} from "../observers/classes/Calculation.js";
import {Field} from "../observers/classes/Field.js";
import {RelatedModel} from "../observers/classes/RelatedModel.js";
import {Rollups} from "../observers/classes/TimeRangeQuery.js";

import ObserverChart from "../observers/ObserverChart.js";
import {useDispatch} from "react-redux";
import {fetchAlpineIQAccounts} from "../../../redux/models/integrations/Action";

ChartJS.register(...registerables);

const client = new Client();

const REDEMPTION = "redemption";
const SALE = "sale";
const ADJUSTMENT = "adjustment";
const EXPIRATION = "expiration";


const calculation = new Calculation(
    "Percent of Revenue Over Time",
    new Multiply(100, new Divide(
        new F("participating"), new F("total"))
    ),
    Sum
);

const dutchie_accounts = new RelatedModel(
    client.sso.dal.v1(),
    "/leaflogix-accounts/",
    "dutchie_account_id",
    [
        new Field("display_name", {
            displayName: "Store",
            filterable: true,
            groupable: true
        })
    ]
)

const alpineIQPercentOfRevenueReport = new Report(
    client.alpineiq.api.v1(),
    "/kpis/sale-orders/percent-of-revenue/",
    "Percent of Engaged Revenue Over Time",
    [
        new Field("timestamp", {
            displayName: "Date", cast: (d) => {
                const date = new Date(d);
                const year = date.getFullYear();
                const month = String(date.getMonth() + 1).padStart(2, '0');
                const day = String(date.getDate()).padStart(2, '0');
                return `${year}-${month}-${day}`;
            }, timestamp: true, observations: true, filterable: true
        }),
        new Field(
        "participating", {
            displayName: "Participating in the Loyalty Program",
            cast:        (o) => {
                return o;
            },
            filterable:  false,
            groupable:   false
        }),
        new Field("total", {
            displayName: "Total Revenue", cast: (o) => {
                return o;
            },
            filterable:  false,
            groupable:   false
        })
    ],
    [dutchie_accounts],
    calculation,
    "This report gives the percent of redemptions over time. It answers what percent of the redemptions in this time window occurred on a given date.",
)


const PercentOfRevenue = ({organizationId, startDate, endDate}) => {

    const dispatch = useDispatch();
    const [selectedAlpineIQAccounts, setSelectedAlpineIQAccounts] = useState([]);
    const [rollup, setRollup] = useState(Rollups.DAILY);
    const [loadingAlpineIQAccounts, setLoadingAlpineIQAccounts] = useState(false);

    useEffect(() => {
        if(loadingAlpineIQAccounts) {
            return;
        }
        dispatch(fetchAlpineIQAccounts({organization_id: organizationId})).then(fetchedAccounts => {
            setSelectedAlpineIQAccounts(fetchedAccounts)
            alpineIQPercentOfRevenueReport.query(startDate, endDate, null, {"alpineiq_account_id__in": fetchedAccounts.map(a => a.id).join(",")});
        });
    }, [organizationId]);


    useEffect(() => {
        if(loadingAlpineIQAccounts || isEmpty(selectedAlpineIQAccounts) ||
           alpineIQPercentOfRevenueReport.isLoading()) {
            return;
        }
        // TODO: When the organization changes we need to clear the previous
        // results!
        alpineIQPercentOfRevenueReport.query(startDate, endDate, null, {
            "alpineiq_account_id__in": selectedAlpineIQAccounts.map(a => a.id).join(",")
        });
    }, [
                  alpineIQPercentOfRevenueReport.isLoading(), organizationId,
                  startDate, endDate, rollup,
                  selectedAlpineIQAccounts.map(a => a.id).join(",")
              ]);


    console.log("Report", alpineIQPercentOfRevenueReport.getTimeSeries());

    console.log("---------------------Will render <ObserverChart>---------------------");
    return <div>
        <ObserverChart
            report={alpineIQPercentOfRevenueReport}
            chartType={"bar"}/>
    </div>
}

export default PercentOfRevenue;
