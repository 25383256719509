import React, {useEffect, useState} from 'react';
import "../../views/styles/Login.css";

import Button from '@mui/material/Button/Button.js';
import Dialog from '@mui/material/Dialog/Dialog.js';
import DialogActions from '@mui/material/DialogActions/DialogActions.js';
import DialogContent from '@mui/material/DialogContent/DialogContent.js';
import DialogTitle from '@mui/material/DialogTitle/DialogTitle.js';
import {useDispatch, useSelector} from "react-redux";
import {useNavigate} from "react-router-dom";
import {Grid} from "@mui/material";
import EmailInput from "../elements/EmailInput.js";
import TextInput from "../elements/TextInput.js";
import {Link} from "react-router-dom";
import {enqueueSnackbar} from "../../redux/Actions.js";
import SubmitButton from "../elements/SubmitButton.js";
import CreateAccountForm from "./CreateAccount.js";
import {
    fetchOrganizations,
    fetchOrganizationUserGroupsForUser,
    fetchOrganizationUsers,
    fetchUserProfile,
    login,
    userLoggedIn
} from "../../redux/user/Action.js";
import Loading from "../elements/Loading.js";


const Login = () => {
    const [open, setOpen] = useState(false);
    const [loading, setLoading] = useState(false);
    const [formState, setFormState] = useState({
        email: "",
        password: ""
    });
    const [errors, setErrors] = useState({
        email: "",
        password: ""
    })
    const dispatch = useDispatch();

    const organizations = useSelector(state => state.userReducer.loggedInUser.organizations);
    const organizationUsers = useSelector(state => state.userReducer.loggedInUser.organizationUsers);

    const navigate = useNavigate();

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const handleLogin = (click) => {
        setLoading(true);
        click.preventDefault();
        const abortController = new AbortController();
        console.log("Logging in with email and password", formState.email, formState.password);
        dispatch(login(formState.email, formState.password)).then((currentUser) => {
            return Promise.all([
                dispatch(fetchUserProfile(currentUser, abortController)),
                dispatch(fetchOrganizationUserGroupsForUser(currentUser, abortController)),
                dispatch(fetchOrganizationUsers(null, currentUser.id, abortController)),
                dispatch(fetchOrganizations(null, abortController))
            ]).then(response => {
                const [fetchedUserProfile, fetchedOrganizationUserGroups, fetchedOrganizationUsers, fetchedOrganizations] = response;
                console.log("Logged in");
                dispatch(userLoggedIn({
                    user: currentUser,
                    organizationUsers: fetchedOrganizationUsers,
                    organizations: fetchedOrganizations,
                    organizationUserGroups: fetchedOrganizationUserGroups,
                    profile: fetchedUserProfile,
                }));
                setLoading(false)
                handleClose();
                navigate("/")
            }).catch((errs) => {
                console.log("Got errors ", errs);
            });
        }).catch((errs) => {
            console.log("Got errors ", errs);
            setLoading(false)
            dispatch(enqueueSnackbar({
                message: "Login failed. Check your email and password and try again.",
                options: {
                    variant: "error",
                },
            }));
            if (errs && errs.data) {
                setErrors({...errors, ...errs.data});
            } else {
                console.log(errs);
                setErrors({...errors, email: "Login failed. Check your email and password and try again."});
            }
        });
    }

    const onKeyPress = (event) => {
        if (!open) {
            return;
        }
        if (event.keyCode === 13) {
            handleLogin(event);
        }
    }

    useEffect(() => {
        window.addEventListener("keypress", onKeyPress);
        return () => {
            window.removeEventListener("keypress", onKeyPress);
        }
    });

    return (
        <div className="login-form">
            <Button id="login-button" variant="outlined" onClick={handleClickOpen} className="open-dialog">Log
                in</Button>
            <Dialog open={open} onClose={handleClose}>
                <DialogTitle>Log in</DialogTitle>
                <DialogContent>
                    <form className="login-form" onSubmit={handleLogin}>
                        <Grid container spacing={2}>
                            <Grid xs={2} item></Grid>
                            <Grid xs={8} item>
                                <EmailInput
                                    className={loading ? "hidden" : ""}
                                    name="email"
                                    label="Email Address"
                                    formState={formState}
                                    errors={errors}
                                    setFormState={setFormState}
                                    setErrors={setErrors}/>
                            </Grid>
                            <Grid xs={2} item></Grid>
                            <Grid xs={2} item></Grid>
                            <Grid item xs={8}>
                                <div id="login-loading" className={loading ? "" : "hidden"}>
                                    <Loading fullscreen={false} title={"Let's get to it! Logging you in..."}/>
                                </div>
                                <TextInput
                                    className={loading ? "hidden" : ""}
                                    setErrors={setErrors}
                                    type="password"
                                    name="password"
                                    label="Password"
                                    formState={formState}
                                    errors={errors}
                                    setFormState={setFormState}/>
                            </Grid>
                        </Grid>
                    </form>
                </DialogContent>
                <DialogActions>
                    <Grid container spacing={2}>
                        <Grid xs={4} item>
                            <Link onClick={(event) => {
                                setOpen(false);
                            }} to="/forgot-password">Forgot Password</Link>
                        </Grid>
                        <Grid xs={8} item style={{display: "flex"}} className="actions" justifyContent="right"
                              justifyItems="center" alignContent="center" alignItems="center">
                            <CreateAccountForm/>
                            <span> &nbsp; or &nbsp;</span>
                            <SubmitButton displayName="Login" onSubmit={handleLogin}>Login</SubmitButton>
                        </Grid>
                    </Grid>
                </DialogActions>
            </Dialog>
        </div>
    );
}


export default Login;
