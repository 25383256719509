import React, { useState } from "react";
import ReactTable from "react-table-v6";
import { getTotalForField, isNullOrUndefinedOrEmpty, groupListByField, numberFormatter, currencyFormatter } from "../../../utils.js";
import {isEmpty} from "weed-js";


const SalesByRepAndCustomer = ({ salesReport }) => {

  const [sortOptions, setSortOptions] = useState([{ id: "total", desc: true }])
  const title = <h3>Units Sold by Sales Rep and Customer</h3>;

  const bySalesRepAndCustomerHeaders = [
    {
      Header: "Sales Rep",
      accessor: "sales_rep",
    },
    {
      Header: "Customer",
      accessor: "customer",
    },
    {
      Header: "Total Quantity",
      accessor: "quantity",

    },
    {
      Header: "Total Value",
      accessor: "total",
    }
  ]

  const salesBySalesRepAndCustomer = (report) => {
    if (isNullOrUndefinedOrEmpty(report)) {
      return [];
    }
    let aggregate = [];
    for (let [salesRep, entriesBySalesRep] of Object.entries(groupListByField(report, "sales_rep"))) {
      for (let [customer, entriesByCustomer] of Object.entries(groupListByField(entriesBySalesRep, "customer"))) {
        aggregate.push({
          sales_rep: salesRep,
          customer: customer,
          quantity: numberFormatter.format(getTotalForField(entriesByCustomer, "quantity")),
          total: currencyFormatter.format(getTotalForField(entriesByCustomer, "total")),
        });
      }
    }
    return aggregate;
  };


  if (isEmpty(salesReport)) {
    return "";
  }

  const bySalesRepAndCustomer = salesBySalesRepAndCustomer(salesReport) || [];

  return <div>
    {title}
    <ReactTable
      sorted={sortOptions}
      onSortedChange={(newSorted, column, shiftKey) => {
        setSortOptions(newSorted);
      }}
      showPaginationBottom={false}
      getProps={() => { return { id: "sales-report-table" } }}
      columns={bySalesRepAndCustomerHeaders}
      pageSize={bySalesRepAndCustomer.length}
      className="-striped -highlight mb-3"
      data={bySalesRepAndCustomer}
    />
  </div>

}

export default SalesByRepAndCustomer;
