import React from "react";
import { useEffect, useState } from "react";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import DatabaseAccount from "../../../components/forms/DatabaseAccount.js";
import { Container, Grid, Tab, Tabs } from "@mui/material";
import { fetchDatabaseAccounts } from "../../../redux/models/integrations/Action.js";
import {isEmpty} from "weed-js";
import {ABORTED } from "../../../redux/constants.js";
import Loading from "../../../components/elements/Loading.js";
import {memberIsAdmin, memberIsSuperAdmin} from "weed-js";
import {
    AUTHENTICATION,
    ROUTES_INTEGRATIONS_DATABASE_DETAIL,
    ROUTES_INTEGRATIONS_DATABASE_LIST
} from "../../../utils/Constants.js";


const Detail = ({ tab }) => {
    const params = useParams();
    const dispatch = useDispatch();
    const databaseAccountId = parseInt(params.id)
    const [searchParams, setSearchParams] = useSearchParams();
    const navigate = useNavigate();

    const [databaseAccounts, setDatabaseAccounts] = useState([]);

    const loggedInUser = useSelector(state => state.userReducer.loggedInUser.user || {})
    const organizationUsers = useSelector(state => state.userReducer.loggedInUser.organizationUsers);
    const [isLoading, setIsLoading] = useState(true); // useSelector(state => state.userReducer.loading);
    const selectedOrganization = useSelector(state => state.organizationReducer.selectedOrganization);

    const TabPanel = ({ children, index }) => {
        if (index === selectedTab) {
            return <>{children}</>
        }
        return ""
    }

    useEffect(() => {
        const abortController = new AbortController();
        setIsLoading(true);
        dispatch(fetchDatabaseAccounts({}, abortController)).then((fetchedDatabaseAccounts) => {
            setDatabaseAccounts(fetchedDatabaseAccounts);
            setIsLoading(false);
        }).catch(err => {
            setIsLoading(false);
            if (err !== ABORTED) {
                console.error(err);
            }
        });
        return () => {
            abortController.abort();
        }
    }, [loggedInUser.id]);

    const tabs = {
        null: 1,
        undefined: 1,
        [AUTHENTICATION]: 1,
    }

    const whichTab = searchParams.get("tab") || tab;
    const databaseAccount = databaseAccounts.find(ma => parseInt(ma.id) === databaseAccountId);
    const [selectedTab, setSelectedTab] = useState(tabs[whichTab]);
    const selectTab = (event, newValue) => {
        setSelectedTab(newValue);
        if (newValue === tabs[AUTHENTICATION]) {
            navigate(ROUTES_INTEGRATIONS_DATABASE_DETAIL.replace(":id", databaseAccountId.toString()))
        }
    }

    if (isLoading) { // isEmpty(databaseAccount)) {
        return <Container className={"database-account-detail workspace"}>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <Loading fullscreen={true}/>
                </Grid>
            </Grid>
        </Container>
    }

    if (isEmpty(databaseAccount)) {
        return <Container className={"database-account-detail workspace"}>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <h1>View/Edit</h1>
                </Grid>
                <Grid item xs={12}>
                    <h2>(Not found)</h2>
                </Grid>
            </Grid>
        </Container >
    }

    const isAdmin = memberIsAdmin(loggedInUser, organizationUsers, selectedOrganization)
    const isSuper = memberIsSuperAdmin(loggedInUser, organizationUsers, selectedOrganization)
    const enableForm = isAdmin || isSuper;

    return <Container className="database-account-detail workspace">
        <Grid container spacing={2}>
            <Grid item xs={12}>
                <h1>View/Edit</h1>
            </Grid>
        </Grid>
        <Tabs value={selectedTab} onChange={selectTab}>
            <Tab label="Authentication" value={1} tabIndex={1} />
        </Tabs>
        <TabPanel index={1}>
            <DatabaseAccount
                create={false}
                enableDelete={enableForm}
                enableEdit={enableForm}
                onDelete={(id) => {
                    setDatabaseAccounts((state) => {
                        return state.filter(ma => {return parseInt(ma.id) !== id})
                    });
                    navigate(ROUTES_INTEGRATIONS_DATABASE_LIST);
                }}
                {...databaseAccount}
            />
        </TabPanel>
    </Container >
}


export default Detail;