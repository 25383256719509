import { getFromApi, getFromDal } from "../../Actions.js";
import { SET_STATES } from "../../constants.js";


export const fetchStates = (abortController) => {
    return dispatch => dispatch(getFromDal("/states/", {}, abortController)).then((response) => {
        return response;
    });
};

export const fetchActiveStates = (abortController) => {
    return dispatch => dispatch(getFromApi("/states/active/", {}, abortController)).then((response) => {
        return response;
    });
};

export const setStates = (states) => {
    return dispatch => {
        return dispatch({
            type: SET_STATES,
            states: states
        })
    }
}