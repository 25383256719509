import React, { useState } from "react";
import TextInput from "../../elements/TextInput.js";
import SubmitButton from "../../elements/SubmitButton.js";
import { deactivateOrganization, updateOrganization } from "../../../redux/user/Action.js";
import DeleteButton from "../../elements/DeleteButton.js";
import Address from "../../forms/Address.js";
import { useDispatch, useSelector } from "react-redux";
import { showConfirmationPopup } from "../../../redux/notifications/Action.js";
import { memberIsAdmin, memberIsSuperAdmin } from "../../../utils/Roles.js";


const Profile = ({ organization, onOrganizationUpdate, members, onOrganizationDelete }) => {

    const [errors, setErrors] = useState({});
    const [formState, setFormState] = useState({ ...organization });
    const loggedInUser = useSelector(state => state.userReducer.loggedInUser.user);
    const organizationUsers = useSelector(state => state.userReducer.loggedInUser.organizationUsers);

    const isAdmin = memberIsAdmin(loggedInUser, organizationUsers, organization);
    const isSuperAdmin = memberIsSuperAdmin(loggedInUser, organizationUsers, organization);
    const disabled = !isAdmin && !isSuperAdmin;

    const dispatch = useDispatch();

    return <form className="form">
        <input name="id" hidden defaultValue={organization.id} />
        <div>
            <TextInput
                id='organization-name'
                name="name"
                label="Name"
                formState={formState}
                setFormState={setFormState}
                errors={errors}
                setErrors={setErrors}
                disabled={disabled}
            />
            <DeleteButton
                id="delete-organization"
                disabled={members.length !== 1 || disabled}
                onDelete={
                    () => {
                        dispatch(showConfirmationPopup(
                            "Delete Organization",
                            `Are you sure you want to delete ${organization.name}?`,
                            () => {
                                dispatch(deactivateOrganization(organization.id)).then(() => {
                                    onOrganizationDelete(organization);
                                });
                            },
                            () => { },
                            "confirm-delete-organization"
                        ));
                    }}
            >Delete Organization</DeleteButton>
            <SubmitButton
                id="update-organization-name"
                disabled={disabled}
                onSubmit={(event) => {
                    event.preventDefault();
                    dispatch(updateOrganization(formState)).then(returnedOrganization => {
                        onOrganizationUpdate(returnedOrganization);
                    }).catch((errs) => {
                        setErrors({ ...errors, ...errs });
                    });
                }
                }>Update Name</SubmitButton>
        </div>
        <Address disabled={disabled} organization={organization} />
    </form>
}


export default Profile;
