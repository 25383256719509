import {isEmpty} from "weed-js";


export class Timeseries {

    constructor(dataPoints) {
        this.dataPoints = dataPoints;
        this.lastUpdated = new Date();

        this._filtered = {}
        // this.filter();
    }

    getFiltered(report) {
        if(!this._filtered.hasOwnProperty(report.getFilters().key())) {
            this.filter();
        }
        return this._filtered[report.getFilters().key()];
    }

    getXValues(type, report) {
        /*
        Returns the entire domain regardless of filtering. This keeps x-axis consistent.
         */
        if(report.isAggregate(type)) {
            const xValues = this.getFiltered(report).map(point => {
                return report.getGroups().key(point) || report.calculation.name;
            });
            const unique = new Set(xValues);
            return Array.from(unique).sort();
        }
        const domain = Array.from(this.dataPoints.reduce((domain, point) => {
            domain.add(report.getTimestampField().run(point));
            return domain;
        }, new Set()));

        domain.sort()
        return domain;
    }

    getYValues(points, report) {
        return points.map(ts => report.calculation.run(ts));
    }

    filter(report) {
        this._filtered[report.getFilters().key()] = this.dataPoints.filter(
            point => !report.getFilters().shouldSkip(point)
        )
        return this;
    }

    reset(report) {
        this._filtered = {}
        this.filter(report);
        return this;
    }

    group(type, report) {
        const groups = report.getGroups();
        const domain = this.getXValues(type, report);
        const dateToIndex = Object.fromEntries(domain.map((date, idx) => {
            return [date, idx];
        }));
        return this.getFiltered(report).reduce((grouped, point) => {
            const key = groups.key(point) || "(none)";
            const date = report.getTimestampField().run(point);
            const idx = dateToIndex[date];

            if(!grouped.hasOwnProperty(key)) {
                grouped[key] =
                    domain.map(date => report.calculation.getAggregate());
            }

            grouped[key][idx].run(point)
            return grouped;
        }, {})
    }

    aggregate(type, report) {
        console.log("Aggregating");
        const groups = report.getGroups();
        const xValues = this.getXValues(type, report);
        const grouped = this.getFiltered(report).reduce((grouped, point) => {
            const key = groups.key(point) || report.calculation.name;

            if(!grouped.hasOwnProperty(key)) {
                grouped[key] = report.calculation.getAggregate();
            }

            grouped[key].run(point)

            return grouped;
        }, {})

        return xValues.map(x => grouped[x]);
    }

    setCardinality(report) {
        report.fields().forEach(field => {
            field.cardinality = 0
        });
        return this.dataPoints.reduce((cardinality, point) => {
            return report.fields().reduce((card, field) => {
                if(!field.groupable) {
                    return card;
                }
                const value = field.run(point)
                if(!card.hasOwnProperty(field.displayName)) {
                    card[field.displayName] = new Set();
                }
                if(value !== null && value !== undefined) {
                    if(!card[field.displayName].has(value)) {
                        card[field.displayName].add(value);
                        field.cardinality += 1;
                    }
                }
                return card;
            }, cardinality);
        }, {});
    }

    update(dataPoints, report) {
        this.dataPoints = this.dataPoints.concat(dataPoints);
        this.reset(report);
        this.lastUpdated = new Date();
        try {
            this.setCardinality(report);
        } catch(e) {
            console.error(e);
        }
        return this;
    }
}

export default Timeseries;