import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import DeleteButton from "../elements/DeleteButton.js";
import SubmitButton from "../elements/SubmitButton.js";
import TextInput from "../elements/TextInput.js";
import Address from "../forms/Address.js";
import { createBankAccount, deleteBankAccount, updateBankAccount, setBankAccounts } from "../../redux/user/Action.js";
import { isNullOrUndefinedOrEmpty } from "../../utils.js";
import Confirm from "../elements/Confirm.js";
import { useNavigate } from "react-router-dom";
import { showInformationalPopup } from '../../redux/notifications/Action.js';
import {
    updateAccountStatus
} from "../../redux/user/Action.js";
import { ROUTES_INTEGRATIONS_METRC_SETUP } from '../../utils/Constants.js';


const BankAccount = ({ bankAccount, organization, setSelectedBankAccount, userIsAdmin }) => {
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const organizations = useSelector(state => state.userReducer.loggedInUser.organizations);
    const bankAccounts = organizations.find(o => o.id === organization.id).bankAccounts;

    const [updatedBankAccount, setUpdatedBankAccount] = useState({});
    const [errors, setErrors] = useState({});
    const isNewAccount = bankAccount.id === null || bankAccount.id === undefined;

    const [confirmationMessage, setConfirmationMessage] = useState(null);
    const [openConfirmation, setOpenConfirmation] = useState(false)

    const disabled = !userIsAdmin || isNullOrUndefinedOrEmpty(bankAccount.id) && isNullOrUndefinedOrEmpty(updatedBankAccount.id);
    const defaultBankAccount = { ...bankAccount, organization_id: organization.id };
    const accountStatus = useSelector(state => state.userReducer.loggedInUser.accountStatuses.find(a => a.organization_id === organization.id));

    useEffect(() => { }, [bankAccount, organization]);

    return (
        <>
            <h2 id={`bank-account-${updatedBankAccount.id}`}>{isNewAccount ? 'New Bank Account' : updatedBankAccount.label}</h2>
            <div className="form-row">
                <TextInput
                    disabled={!userIsAdmin}
                    name="label"
                    label="Display Name"
                    formState={{ ...defaultBankAccount, ...updatedBankAccount }}
                    setFormState={setUpdatedBankAccount}
                    errors={errors}
                    setErrors={setErrors}
                />
                <TextInput
                    disabled={!userIsAdmin}
                    name="bank_name"
                    label="Bank Name"
                    formState={{ ...defaultBankAccount, ...updatedBankAccount }}
                    setFormState={setUpdatedBankAccount}
                    errors={errors}
                    setErrors={setErrors}
                />
            </div>

            <div className="form-row">
                <TextInput
                    disabled={!userIsAdmin}
                    id="account-number"
                    name="account_number"
                    label="Bank Account Number"
                    formState={{ ...defaultBankAccount, ...updatedBankAccount }}
                    setFormState={setUpdatedBankAccount}
                    errors={errors}
                    setErrors={setErrors}
                />
            </div>
            <div className="form-row">
                <TextInput
                    disabled={!userIsAdmin}
                    id="routing-number"
                    name="routing_number"
                    label="Routing Number"
                    formState={{ ...defaultBankAccount, ...updatedBankAccount }}
                    setFormState={setUpdatedBankAccount}
                    errors={errors}
                    setErrors={setErrors}
                />
            </div>
            {userIsAdmin && <>
            <div className="form-row ">
                <SubmitButton
                    id="create-or-update-bank-account"
                    onSubmit={(event) => {
                        event.preventDefault();
                        const action = isNewAccount ? createBankAccount : updateBankAccount;
                        dispatch(action({ ...defaultBankAccount, ...updatedBankAccount }))
                            .then((returnedBankAccount) => {
                                const updatedAccounts = [
                                    returnedBankAccount,
                                    ...bankAccounts.filter(ba => ba.id !== returnedBankAccount.id)
                                ];
                                dispatch(setBankAccounts(organization, updatedAccounts));
                                setUpdatedBankAccount({});
                                setSelectedBankAccount((state) => {
                                    return { ...state, returnedBankAccount }
                                })
                            })
                            .catch((errs) => {
                                setErrors({ ...errors, ...errs });
                            });
                    }}
                >{"Save Account"}</SubmitButton>
                {!isNewAccount &&
                    <div id='bank-account-delete-button-container'>
                        <DeleteButton
                            id="bank-account-delete-button"
                            onDelete={() => {
                                setConfirmationMessage(`Are you sure you want to delete ${defaultBankAccount.label} ?`)
                                setOpenConfirmation(true);
                            }}
                        >Delete Account</DeleteButton>
                    </div>
                }
            </div>
            {openConfirmation ? <Confirm
                id="delete-bank-account"
                title={"Delete Bank Account"}
                message={confirmationMessage}
                onConfirm={() => {
                    setOpenConfirmation(false);
                    dispatch(deleteBankAccount(bankAccount.id)).then(() => {
                        dispatch(setBankAccounts(
                            organization,
                            bankAccounts.filter(ba => ba.id !== bankAccount.id)
                        ));
                    });
                }}
                onCancel={() => {
                    setOpenConfirmation(false);
                }}
            /> : ""}
            <br />
            <hr />
            {disabled ? <p>(Save a bank account to add an address)</p> : ""}
            </>}
            <Address
                bankAccount={{ ...defaultBankAccount, ...updatedBankAccount }}
                disabled={disabled} />
        </>
    )
};

export default BankAccount;
