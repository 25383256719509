import React from "react";
import {Dialog, DialogActions, DialogContent, DialogTitle, Grid} from "@mui/material";
import {useState} from "react";
import SubmitButton from "./SubmitButton.js";
import DeleteButton from "./DeleteButton.js";


export const Confirm = ({title, message, onConfirm=() => {}, onCancel=()=>{}, id="submit"}) => {

    const [open, setOpen] = useState(true);

    const handleClose = () => {
        setOpen(false);
    }

    return <Dialog open={open} onClose={handleClose}>
        <DialogTitle>{title}</DialogTitle>
        <DialogContent>
            {message}
        </DialogContent>
        <DialogActions>
            <DeleteButton 
                id={`${id}-cancel`}
                onDelete={() => {
                setOpen(false)
                onCancel();
                return true;
            }}>
                Cancel
            </DeleteButton>
            <SubmitButton 
                id={`${id}-confirm`}
                onClick={() => {
                setOpen(false);
                onConfirm();
            }}>
                Confirm
            </SubmitButton>
        </DialogActions>
    </Dialog>
}

export default Confirm;