import React, {useState, useEffect} from "react";

import {Chart as ChartJS, registerables} from "chart.js";
import {isEmpty, Client, getCookie} from "weed-js";
import {Report} from "../observers/classes/Report.js";
import {Divide, Average, Subtract, Add, Calculation, F, Multiply, Sum, Count} from "../observers/classes/Calculation.js";
import {Field} from "../observers/classes/Field.js";
import {RelatedModel} from "../observers/classes/RelatedModel.js";
import {TimeRangeQuery, Rollups} from "../observers/classes/TimeRangeQuery.js";

import ObserverChart from "../observers/ObserverChart.js";
import {Grid} from "@mui/material";
import {useDispatch} from "react-redux";
import {fetchAlpineIQAccounts, fetchLeaflogixAccounts} from "../../../redux/models/integrations/Action";

ChartJS.register(...registerables);

const client = new Client();
const calculation = new Calculation(
    "% Engaged Over this Period",
    new F("engaged"),
    Count
);

const alpineIQEngagementReport = new Report(
    client.alpineiq.api.v1(),
    "/kpis/timeline/engagement/",
    "Engagement Over Time",
    [
        new Field("timestamp", {
            displayName: "Engagement Date",
            cast: (d) => {
                const date = new Date(d);
                const year = date.getFullYear();
                const month = String(date.getMonth() + 1).padStart(2, '0');
                const day = String(date.getDate()).padStart(2, '0');
                return `${year}-${month}-${day}`;
            },
            timestamp: true,
            observations: true
        }),
    ],
    [
        new RelatedModel(
            client.alpineiq.dal.v1(),
            "/timeline-event-types/",
            "type_id",
            [
                new Field("name", {displayName: "Engagement Type", filterable: true, groupable: true}),
            ]
        )
    ],
    calculation,
    "This report gives the percent of engagements over time. It answers what percent of the engagements in this time window occurred on a given date.",
)


const Engagement = ({organizationId, startDate, endDate}) => {

    const dispatch = useDispatch();
    const [selectedAlpineIQAccounts, setSelectedAlpineIQAccounts] = useState([]);
    const [rollup, setRollup] = useState(Rollups.DAILY);

    useEffect(() => {
        dispatch(fetchAlpineIQAccounts({organization_id: organizationId})).then(fetchedAccounts => {
            setSelectedAlpineIQAccounts(fetchedAccounts)
            alpineIQEngagementReport.query(
                startDate,
                endDate,
                null,
                {"alpineiq_account_id__in": fetchedAccounts.map(a => a.id).join(",")}
            );
        });
    }, [organizationId]);


    useEffect(() => {
        if (isEmpty(selectedAlpineIQAccounts) || alpineIQEngagementReport.isLoading()) {
            return;
        }
        // TODO: When the organization changes we need to clear the previous results!
        alpineIQEngagementReport.query(
            startDate,
            endDate,
            null,
            {"alpineiq_account_id__in": selectedAlpineIQAccounts.map(a => a.id).join(",")}
        );
    }, [organizationId, startDate, endDate, rollup, selectedAlpineIQAccounts.map(a => a.id).join(",")]);


    return <div>
        <ObserverChart report={alpineIQEngagementReport} chartType={"bar"}/>
    </div>
}

export default Engagement;
