import React from "react";
import {Churn} from "./Churn.js";
import {Velocity} from "./Velocity.js";
import {SalesOverTime} from "./SalesOverTime.js";
import {ScrollEventWrapper} from "../../../components/elements/ScrollEventWrapper.js";
import {Chart as ChartJS, registerables} from "chart.js";


ChartJS.register(...registerables);


export const Charts = () => {
    return <div className={"chart-demo"}>
        <ScrollEventWrapper name="churn" addOnTrigger={["swoop-right"]}>
            <Churn/>
        </ScrollEventWrapper>
        <ScrollEventWrapper name="velocity" addOnTrigger={["swoop-left"]}>
            <Velocity/>
        </ScrollEventWrapper>
        <ScrollEventWrapper name="sales" addOnTrigger={["swoop-right"]}>
            <SalesOverTime/>
        </ScrollEventWrapper>
    </div>
}

export default Charts;


