import React, {useEffect, useState} from 'react';
import {Container, Grid, Tab, Tabs} from "@mui/material";
import {useDispatch, useSelector} from "react-redux";
import {useNavigate, useParams, useSearchParams} from "react-router-dom";
import Logo from "../../components/organization-management/Logo.js";
import BankAccounts from "../../components/organization-management/tabs/BankAccounts.js";
import Membership from "../../components/organization-management/tabs/Membership.js";
import Loading from "../../components/elements/Loading.js";
import Profile from "../../components/organization-management/tabs/Profile.js";
import {
    fetchGroups,
    fetchOrganization,
    fetchOrganizationUserGroups,
    fetchOrganizationUsers,
    fetchUsers
} from "../../redux/user/Action.js";
import {userLoggedIn} from "../../redux/user/Action.js";
import {selectOrganization} from "../../redux/models/organizations/Action.js";
import {groupByAttribute, userIsNotLoggedIn, isNullOrUndefinedOrEmpty} from "../../utils.js";
import {
    NOT_STARTED,
    COMPLETE,
    ROUTES_ORGANIZATIONS_LIST,
    LOGO,
    PROFILE,
    BANK,
    ROLES,
    ROUTES_ORGANIZATIONS_DETAIL,
    ROUTES_ORGANIZATIONS_DETAIL_LOGO,
    ROUTES_ORGANIZATIONS_DETAIL_BANK_ACCOUNTS,
    ROUTES_ORGANIZATIONS_DETAIL_USERS_AND_ROLES
} from '../../utils/Constants.js';

import {userCanSeeOrganization} from '../../utils/Roles.js';
import "../styles/OrganizationManagement.css";
import {isEmpty} from "weed-js";


const Detail = ({tab}) => {
    const {id: _organizationId} = useParams();
    const organizationId = parseInt(_organizationId);
    const [searchParams, setSearchParams] = useSearchParams();

    const tabs = {
        null: 1,
        undefined: 1,
        [PROFILE]: 1,
        [ROLES]: 2,
        [BANK]: 3,
        [LOGO]: 4
    }
    const routes = {
        1: ROUTES_ORGANIZATIONS_DETAIL.replace(":id", organizationId),
        2: ROUTES_ORGANIZATIONS_DETAIL_USERS_AND_ROLES.replace(":id", organizationId),
        3: ROUTES_ORGANIZATIONS_DETAIL_BANK_ACCOUNTS.replace(":id", organizationId),
        4: ROUTES_ORGANIZATIONS_DETAIL_LOGO.replace(":id", organizationId)
    }

    const whichTab = searchParams.get("tab");
    const [selectedTab, setSelectedTab] = useState(tabs[isNullOrUndefinedOrEmpty(tab) ? whichTab : tab]);

    const loggedInUser = useSelector(state => state.userReducer.loggedInUser.user);
    const organizationUsers = useSelector(state => state.userReducer.loggedInUser.organizationUsers);
    const organizations = useSelector(state => state.userReducer.loggedInUser.organizations);
    const organizationUserGroups = useSelector(state => state.userReducer.loggedInUser.organizationUserGroups);
    const selectedOrganization = useSelector(state => state.organizationReducer.selectedOrganization);
    const accountStatus = useSelector(state => state.userReducer.loggedInUser.accountStatuses.find(a => a.organization_id === organizationId));

    const [requestStatus, setRequestStatus] = useState(NOT_STARTED);
    const [notFound, setNotFound] = useState(false);
    const [notAuthorized, setNotAuthorized] = useState(false);

    // Need to use organizationReducer.selectedOrganization as the organization here.
    const [detail, setDetail] = useState({
        members: [],
        groups: [],
        users: [],
        organizationUsers: [],
        organizationUserGroups: []
    });

    const dispatch = useDispatch();
    const navigate = useNavigate();

    const selectTab = (event, newValue) => {
        setSelectedTab(newValue);
        navigate(routes[parseInt(newValue)]);
    }

    const marshallGroupsToUser = (user, throughId) => {
        const organizationUserGroups = detail.organizationUserGroups.filter(OUG => {
            return OUG.organization_user_id === throughId;
        });
        const userGroups = organizationUserGroups.map(OUG => {
            const group = detail.groups.find(group => group.id === OUG.group_id);
            return {
                id: group.id,
                name: group.name,
                organizationUserGroupId: OUG.id,
            }
        });
        return {
            ...user,
            groups: userGroups,
        };
    };

    const setMembers = () => {
        // TODO: Are organization-users and users 1:1? If not then this actually needs to be LISTS by user ids, not a single element:
        if (isEmpty(detail.users) || isEmpty(detail.groups) || isEmpty(detail.organizationUsers)) {
            return;
        }
        const organizationUsersByUserId = groupByAttribute(
            detail.organizationUsers, "user_id"
        );
        const members = detail.users.map(user => {
            const organizationUser = organizationUsersByUserId[user.id];
            if (isEmpty(organizationUser)) {
                return null;
            }
            const organizationUserId = organizationUser.id;
            const member = {
                ...marshallGroupsToUser(user, organizationUserId),
                throughId: organizationUserId,
                role: organizationUsersByUserId[user.id].role
            };
            return member;
        });
        setDetail(state => {
            return {
                ...state,
                members: members.filter(member => !isEmpty(member))
            }
        });
    }

    useEffect(() => {
        const abortController = new AbortController();

        if (userIsNotLoggedIn(loggedInUser)) {
            return;
        }

        if (!isEmpty(organizationId) && (isEmpty(selectedOrganization) || selectedOrganization.id !== organizationId)) {
            const organization = organizations.find(organization => parseInt(organization.id) === organizationId);
            if (!isEmpty(organization)) {
                dispatch(selectOrganization(organization));
                return;
            }
        }

        setNotFound(false);
        Promise.all([
            dispatch(fetchOrganization(organizationId || selectedOrganization.id, abortController)),
            dispatch(fetchOrganizationUsers(organizationId || selectedOrganization.id, null, abortController)),
            dispatch(fetchUsers({organizations__id: organizationId || selectedOrganization.id}, abortController)),
            dispatch(fetchGroups(abortController)),
            dispatch(fetchOrganizationUserGroups(organizationId || selectedOrganization.id, abortController)),
        ]).then(responses => {
            const [
                fetchedOrganization,
                fetchedOrganizationUsers,
                fetchedUsers,
                fetchedGroups,
                fetchedOrganizationUserGroups
            ] = responses;
            setRequestStatus(COMPLETE);
            dispatch(selectOrganization(fetchedOrganization));
            setDetail(state => {
                return {
                    ...state,
                    organizationUsers: fetchedOrganizationUsers,
                    users: fetchedUsers,
                    groups: fetchedGroups,
                    organizationUserGroups: fetchedOrganizationUserGroups
                }
            });
        }).catch(e => {
            if (!isNullOrUndefinedOrEmpty(e.detail) && e.detail === "Not found.") {
                setNotFound(true);
            } else {
                console.log("GOT AN ERROR", e);
                console.error(e)
            }
        });

        return () => {
            abortController.abort();
        }

    }, [
        loggedInUser && loggedInUser.id,
        organizationId,
        selectedOrganization && selectedOrganization.id,
        accountStatus
    ]);

    useEffect(() => {
        setMembers();
    }, [detail.users, detail.organizationUserGroups, detail.groups, detail.organizationUsers]);


    if (userIsNotLoggedIn(loggedInUser)) {
        navigate("/login");
        return "User is not logged in.";
    }

    if (notFound) {
        return <Container className="workspace">
            <h1 id={"organization-management-title"}>This organization does not exist or you do not belong to it.</h1>
        </Container>
    }

    const TabPanel = ({children, index}) => {
        if (index === selectedTab) {
            return <>{children}</>
        }
        return ""
    }

    if (requestStatus !== COMPLETE) {
        return <Container className="workspace">
            <Loading/>
        </Container>
    }

    if (selectedOrganization.is_active === false) {
        return <Container className="workspace">
            <h1 id="organization-management-title">That organization is not yet active.</h1>
        </Container>
    }
    if (userIsNotLoggedIn(loggedInUser)) {
        return <Container className="workspace">
            <h1 id="organization-management-title">You must log in to view organization detail</h1>
        </Container>
    }
    if (isNullOrUndefinedOrEmpty(selectedOrganization) || !userCanSeeOrganization(organizationUsers, selectedOrganization)) {
        return <Container className="workspace">
            <h1 id="organization-management-title">This organization does not exist or you do not belong to it.</h1>
            <p>Maybe you haven't accepted the invitation?</p>
        </Container>
    }
    if (selectedOrganization.id !== organizationId) {  // If the user chooses an organization to which they do not have access; the selected organization will be something other than the ID selected.
        return <Container className="workspace">
            <h1 id="organization-management-title">This organization does not exist or you do not belong to it.</h1>
            <p>Maybe you haven't accepted the invitation?</p>
        </Container>
    }

    return <Container className={"workspace"}>
        <Grid container spacing={2}>
            <Grid item xs={12}>
                <h1>View/Edit</h1>
                <h2 id="organization-name-title">
                    {selectedOrganization.name}
                </h2>
                <Tabs value={selectedTab} onChange={selectTab}>
                    <Tab label="Profile" value={1} tabIndex={1} id="organization-profile-tab"/>
                    <Tab label="Logo" value={4} tabIndex={4} id="organization-logo-tab"/>
                    <Tab label="Users/Roles" value={2} tabIndex={2} id="organization-users-and-roles-tab"/>
                    <Tab label="Bank Accounts" value={3} tabIndex={3} id="organization-bank-account-tab"/>
                </Tabs>
                <TabPanel index={1}>
                    <Profile
                        organization={selectedOrganization}
                        onOrganizationUpdate={(updatedOrganization) => {
                            dispatch(userLoggedIn({
                                user: loggedInUser,
                                organizationUsers: organizationUsers,
                                organizations: organizations.map(o => o.id === updatedOrganization.id ? updatedOrganization : o),
                                organizationUserGroups: organizationUserGroups
                            }));
                            dispatch(selectOrganization(updatedOrganization));
                        }}
                        members={detail.members}
                        onOrganizationDelete={(deletedOrganization) => {
                            // Update records of this organization to indicate it has been removed.
                            dispatch(userLoggedIn({
                                user: loggedInUser,
                                organizationUsers: organizationUsers.filter(ou => ou.organization_id !== deletedOrganization.id),
                                organizations: organizations.filter(o => o.id !== deletedOrganization.id),
                                organizationUserGroups: organizationUserGroups.filter(oug => oug.organization_id !== deletedOrganization.id)
                            }))
                            navigate(ROUTES_ORGANIZATIONS_LIST);
                        }}
                    />
                </TabPanel>
                <TabPanel index={2}>
                    <Membership
                        organization={selectedOrganization}
                        members={detail.members}
                        setMembers={(members) => {
                            setDetail((state) => {
                                return {...state, members: members}
                            });
                        }}
                        groups={detail.groups}
                    />
                </TabPanel>
                <TabPanel index={3}>
                    <BankAccounts organization={selectedOrganization}/>
                </TabPanel>
                <TabPanel index={4}>
                    <Logo
                        organizationId={selectedOrganization.id}
                        currentUser={detail.members.find(member => member.id === loggedInUser.id)}
                    />
                </TabPanel>
            </Grid>
        </Grid>
    </Container>

};

export default Detail;
