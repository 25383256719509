import React, {useRef, useEffect} from "react";
import {Chart} from "react-chartjs-2";
import {
    Chart as ChartJS,
    registerables
} from "chart.js";


ChartJS.register(...registerables);


export const options = {
    responsive: true,
    plugins: {
        legend: {
            position: "top"
        },
        title: {
            display: true,
            text: "Projected Residuals @ 97% Confidence"
        },
    },
};

const labels = ["January", "February", "March", "April", "May", "June", "July", "August"];

const baseline = labels.map((label, idx) => {
    if (idx > 5) {
        return 0;
    }
    return .3 * idx + Math.random()
});

const projected = baseline.map((base, idx) => {
    if (idx > 5) {
        return .3 * idx + Math.random();
    }
    return base;
});

export const data = {
    labels,
    datasets: [
        {
            type: "line",
            label: "Projected",
            borderColor: "rgb(255, 99, 132)",
            borderWidth: 2,
            fill: false,
            data: projected
        },
        {
            type: "bar",
            label: "Historical",
            borderColor: "white",
            borderWidth: 2,
            data: baseline
        }
    ],
};


const triggerTooltip = (chart) => {
    const tooltip = chart.tooltip;

    if (!tooltip) {
        return;
    }

    if (tooltip.getActiveElements().length > 0) {
        tooltip.setActiveElements([], {x: 0, y: 0});
    } else {
        const {chartArea} = chart;
        tooltip.setActiveElements(
            [
                {
                    datasetIndex: 0,
                    index: 2,
                },
                {
                    datasetIndex: 1,
                    index: 2,
                },
            ],
            {
                x: (chartArea.left + chartArea.right) / 2,
                y: (chartArea.top + chartArea.bottom) / 2,
            }
        );
    }

    chart.update();
}

export const SalesOverTime = () => {
    const chartRef = useRef(null);

    useEffect(() => {
        const chart = chartRef.current;
        triggerTooltip(chart);
    }, []);

    return <div className={"chart"}>
        <div className={"customer-spend"}>
            <Chart ref={chartRef} type="bar" options={options} data={data}/>
        </div>
        <div className={"chart-description"}>
            <h1>Sales Over Time</h1>
            <p>View and export your sales over time by product, category, sales representative, and customer.</p>
            <p>Make informed decisions as you plan for the future based on accurate predictions.</p>
        </div>
    </div>
}

export default SalesOverTime;
