import * as React from "react"
import { useState } from "react";
import SubmitButton from "../elements/SubmitButton.js";
import TextInput from "../elements/TextInput.js";
import { useDispatch, useSelector } from "react-redux";
import Dropdown from "../elements/Dropdown.js";
import { isNullOrUndefinedOrEmpty } from "../../utils.js";
import { Grid } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { createAlpineIQAccount, deleteAlpineIQAccount, updateAlpineIQAccount } from "../../redux/models/integrations/Action.js";
import { enqueueSnackbar } from '../../redux/Actions.js';
import InputAdornment from '@mui/material/InputAdornment/InputAdornment.js';
import IconButton from '@mui/material/IconButton/IconButton.js';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import { selectOrganization } from "../../redux/models/organizations/Action.js";
import { ADMIN_ROLE, SUPERADMIN_ROLE } from "../../utils/Constants.js";

const AlpineIQAccount = ({
    create = false,
    enableEdit = false,
    enableDelete = false,
    onCreate = (newAccount) => { },
    onUpdate = (updatedAccount) => { },
    onDelete = (accountId) => { },
    ...props
}) => {
    const organizationUsers = useSelector(state => state.userReducer.loggedInUser.organizationUsers);
    const organizations = useSelector(state => state.userReducer.loggedInUser.organizations);
    const selectedOrganization = useSelector(state => state.organizationReducer.selectedOrganization);

    const [showPasswords, setShowPasswords] = useState(false);
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [savingAccount, setSavingAccount] = useState(false);
    const [formState, setFormState] = useState({organization_id: selectedOrganization.id});
    const defaultErrors = {
        display_name: "",
        user_key: "",
        organization_id: "",
        user_external_id: ""
    };
    const [errors, setErrors] = useState(defaultErrors);


    if (organizations.length === 0) {
        return <h1>You must add an organization before you can add an AlpineIQ integration.</h1>
    }

    if (isNullOrUndefinedOrEmpty(formState.organization_id)) {
        setFormState({ ...formState, organization_id: organizations[0].id });
    }

    const _selectOrganization = (organizationId) => {
        const organization = organizations.find((org) => parseInt(org.id) === parseInt(organizationId));
        setFormState({ ...formState, organization_id: organizationId });
        dispatch(selectOrganization(organization));
        console.log("Selected organization", organization);
    }

    const getIntegrationOrganization = () => {
        const integrationOrganization = organizations.find(org => org.id === selectedOrganization.id);
        if (integrationOrganization) {
            return integrationOrganization.id;
        }
        return null;
    };

    const getAdministeredOrgOptions = () => {
        return organizations.filter(org => {
            const orgUser = organizationUsers.find(ou => ou.organization_id === org.id);
            if (isNullOrUndefinedOrEmpty(orgUser)) {
                return false;
            }
            return [SUPERADMIN_ROLE, ADMIN_ROLE].includes(orgUser.role);
        }).map((organization) => {
            return {
                label: organization.name,
                value: organization.id
            }
        });
    };

    const del = enableDelete ? <div id="delete-button" className="form-row submit">
        <SubmitButton onClick={(event) => {
            event.preventDefault();
            dispatch(deleteAlpineIQAccount(formState.id || props.id)).then((response) => {
                onDelete(props.id);
                navigate("/integrations/alpineiq/list");
            }).catch((errs) => {
                setErrors({ ...errors, ...errs });
            });
        }}>Delete</SubmitButton>
    </div> : "";

    const edit = enableEdit ? <div id="save-button" className="form-row submit">
        <SubmitButton onClick={(event) => {
            event.preventDefault();
            dispatch(updateAlpineIQAccount({ ...props, ...formState })).then((updatedAlpineIQAccount) => {
                onUpdate(updatedAlpineIQAccount);
                setErrors(defaultErrors);
            }).catch((errs) => {
                setErrors({ ...errors, ...errs });
            });
        }}>Save</SubmitButton>
    </div> : "";

    const passwordType = showPasswords ? "text" : "password";

    if (create) {
        return <>
            <div className="form-row">
                <TextInput
                    name="display_name"
                    autoComplete={"off"}
                    label="Display Name"
                    display_name={formState.display_name || props.display_name}
                    formState={formState}
                    errors={errors}
                    setFormState={setFormState}
                />
            </div>
            <div className="form-row">
                <TextInput
                    name="user_key"
                    label="User Key"
                    autoComplete={"off"}
                    formState={formState}
                    user_key={formState.user_key || props.user_key}
                    errors={errors}
                    setFormState={setFormState}
                    type={passwordType}
                    InputProps={{
                        endAdornment: <InputAdornment position="end">
                            <IconButton onClick={() => { setShowPasswords(!showPasswords) }}>
                                {showPasswords ? <Visibility /> : <VisibilityOff />}
                            </IconButton>
                        </InputAdornment>
                    }}
                />
            </div>
            <div className={"form-row"}>
                <TextInput
                    name={"user_external_id"}
                    label={"User External ID"}
                    formState={formState}
                    errors={errors}
                    setFormState={setFormState}
                    user_external_id={formState.user_external_id || props.user_external_id}
                    />
            </div>
            <div className="form-row">
                <Dropdown
                    id={"organization_id"}
                    label={"Which organization is this for?"}
                    onChange={_selectOrganization}
                    errors={errors}
                    value={getIntegrationOrganization()}
                    options={getAdministeredOrgOptions()} 
                />
            </div>
            <div className="form-row submit">
                {savingAccount ? <span>Saving your account...</span> : ""}
                <SubmitButton onClick={(click) => {
                    click.preventDefault();
                    setSavingAccount(true);
                    dispatch(createAlpineIQAccount({ 
                        ...props, 
                        ...formState, 
                        organization_id: selectedOrganization.id 
                    })).then((alpineIQAccount) => {
                        setErrors(defaultErrors);
                        setSavingAccount(false);
                        onCreate(alpineIQAccount);
                        setFormState({});
                    }).catch((errs) => {
                        console.log("ERRORS", errs);
                        setSavingAccount(false);
                        if (!isNullOrUndefinedOrEmpty(errs)) {
                            if (errs.status === 401 || errs.status === 403) {
                                dispatch(enqueueSnackbar({
                                    message: "You don't have permissions to create AlpineIQ accounts for this organization. Are you admin?",
                                    options: {
                                        variant: 'error',
                                    },
                                }));
                            } else {
                                dispatch(enqueueSnackbar({
                                    message: errs.error,
                                    options: {
                                        variant: 'error',
                                    },
                                }));
                            }
                        }
                        setErrors({ ...errors, ...errs });
                    });
                }}>Submit</SubmitButton>
            </div>
        </>
    } else {
        return <>
            <h2>{props.display_name}</h2>
            <div className="form-row">
                <TextInput
                    name="display_name"
                    label="Display Name"
                    formState={formState}
                    errors={errors}
                    disabled={!enableEdit}
                    setFormState={setFormState}
                    display_name={formState.display_name || props.display_name}
                />
            </div>
            <div className="form-row">&nbsp;</div>
            <div className="form-row">
                <TextInput
                    name="user_key"
                    label="User Key"
                    formState={formState}
                    setFormState={setFormState}
                    errors={errors}
                    disabled={!enableEdit}
                    type={passwordType}
                    user_key={formState.user_key || props.user_key}
                    InputProps={{
                        endAdornment: <InputAdornment position="end">
                            <IconButton onClick={() => {
                                setShowPasswords(!showPasswords)
                            }}>
                                {showPasswords ? <Visibility/> : <VisibilityOff/>}
                            </IconButton>
                        </InputAdornment>
                    }}
                />
            </div>
            <div className={"form-row"}>
                <TextInput
                    name={"user_external_id"}
                    label={"User External ID"}
                    formState={formState}
                    errors={errors}
                    setFormState={setFormState}
                    user_external_id={formState.user_external_id || props.user_external_id}
                />
            </div>
            <div className="form-row">
                <Dropdown
                    id={"organization_id"}
                    label={"Which organization is this for?"}
                    onChange={_selectOrganization}
                    errors={errors}
                    value={getIntegrationOrganization()}
                    options={getAdministeredOrgOptions()}
                />
            </div>
            <Grid container spacing={2}>
                <Grid item xs={12} className="actions">{enableDelete ? del : ""}{edit}</Grid>
            </Grid>
        </>
    }
}

export default AlpineIQAccount;