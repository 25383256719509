export const CLOSE_SNACKBAR = "CLOSE_SNACKBAR";
export const ENQUEUE_SNACKBAR = "ENQUEUE_SNACKBAR";
export const REMOVE_SNACKBAR = "REMOVE_SNACKBAR";


// Animations
export const USER_SCROLLED = "USER_SCROLLED";
export const SCROLL_EVENT_REGISTERED = "SCROLL_EVENT_REGISTERED";


// Users
export const USER_LOGGED_IN = "USER_LOGGED_IN";
export const SET_LOGO = "SET_LOGO";
export const SET_BANK_ACCOUNTS = "SET_BANK_ACCOUNTS";
export const SET_BANK_ACCOUNT_ADDRESS = "SET_BANK_ACCOUNT_ADDRESS";
export const SET_USER_PROFILE = "SET_USER_PROFILE";
export const SET_USER = "SET_USER";
export const SET_ORGANIZATION_USERS = "SET_ORGANIZATION_USERS";
export const SET_ARRIVED_VIA_INVITATION = "SET_ARRIVED_VIA_INVITATION";
export const LOGGED_IN_USER_IS_LOADING = "LOGGED_IN_USER_IS_LOADING";


// Integrations
export const SET_METRC_ACCOUNTS = "SET_METRC_ACCOUNTS";
export const SET_WHOLESALE_LOCATIONS = "SET_WHOLESALE_LOCATIONS";
export const SET_METRC_BACKFILLS = "SET_METRC_BACKFILLS";


// States
export const SET_STATES = "SET_STATES";


// Organizations
export const SELECT_ORGANIZATION = "SELECT_ORGANIZATION";


// Signin actions
export const SSO_SIGN_IN = "SSO_SIGN_IN";
export const SSO_ACCEPT_INVITATION = "SSO_ACCEPT_INVITATION";
export const SSO_OPT_OUT = "SSO_OPT_OUT";
export const SSO_RESET_PASSWORD = "SSO_RESET_PASSWORD";
export const SSO_VERIFY = "SSO_VERIFY";
export const SSO_ERROR = "SSO_ERROR";
export const SSO_DO_LOGOUT = "SSO_DO_LOGOUT";
export const SSO_DO_LOGIN = "SSO_DO_LOGIN";

export const SSO_ACTIONS = {
    0: SSO_SIGN_IN,
    1: SSO_ACCEPT_INVITATION,
    2: SSO_OPT_OUT,
    3: SSO_RESET_PASSWORD,
    4: SSO_VERIFY,
    5: SSO_ERROR,
    6: SSO_DO_LOGOUT,
    7: SSO_DO_LOGIN
}


// Notifications
export const HIDE_NOTIFICATION = "HIDE_NOTIFICATION";
export const POPUP_TYPE_INFORMATIONAL = "POPUP_TYPE_INFORMATIONAL";
export const POPUP_TYPE_RESET_PASSWORD = "POPUP_TYPE_RESET_PASSWORD";
export const POPUP_TYPE_CONFIRM = "POPUP_TYPE_CONFIRM";


// SetupWizard Component
export const INCREMENT_SETUP_WIZARD_REQUESTS_IN_PROGRESS = "INCREMENT_SETUP_WIZARD_REQUESTS_IN_PROGRESS";
export const DECREMENT_SETUP_WIZARD_REQUESTS_IN_PROGRESS = "DECREMENT_SETUP_WIZARD_REQUESTS_IN_PROGRESS";
export const UPDATE_ACCOUNT_STATUS = "UPDATE_ACCOUNT_STATUS";
export const ADD_ACCOUNT_STATUS = "ADD_ACCOUNT_STATUS";


// Organization Detail View
export const SET_ORGANIZATION_DETAIL_REQUEST_STATUS = "SET_ORGANIZATION_DETAIL_REQUEST_STATUS";


// Backfill statuses
export const NOT_STARTED = "NOT_STARTED";
export const PENDING = "PENDING";
export const COMPLETE = "COMPLETE";
export const ABORTED = "ABORTED";


// Sales Quote States
export const IN_PROCESS = "In Process";
export const RESERVED = "Reserved";
export const SOLD = "Sold";
export const READY_TO_FULFILL = "Ready to Fulfill";


// Cookie names
export const ORGANIZATION_COOKIE = "organizationId";
export const JWT_COOKIE = "sso";
