import React, { useState } from "react";
import ReactTable from "react-table-v6";
import { getTotalForField, isNullOrUndefinedOrEmpty, groupListByField, numberFormatter, currencyFormatter } from "../../../utils.js";
import {isEmpty} from "weed-js";


const SalesByRepCustomerAndProduct = ({salesReport}) => {

    const [sortOptions, setSortOptions] = useState([{ id: "total", desc: true }])

    const bySalesRepCustomerAndProductHeaders = [
        {
            Header: "Sales Rep",
            accessor: "sales_rep",
        },
        {
            Header: "Customer",
            accessor: "customer",
        },
        {
            Header: "Product",
            accessor: "product",
        },
        {
            Header: "Total Quantity",
            accessor: "quantity",

        },
        {
            Header: "Total Value",
            accessor: "total",
        }
    ]

    const salesBySalesRepAndCustomerAndProduct = (report) => {
        if (isNullOrUndefinedOrEmpty(report)) {
            return [];
        }
        let aggregate = [];
        for (let [salesRep, entriesBySalesRep] of Object.entries(groupListByField(report, "sales_rep"))) {
            for (let [customer, entriesByCustomer] of Object.entries(groupListByField(entriesBySalesRep, "customer"))) {
                for (let [product, entriesByProduct] of Object.entries(groupListByField(entriesByCustomer, "product"))) {
                    aggregate.push({
                        sales_rep: salesRep,
                        customer: customer,
                        product: product,
                        quantity: numberFormatter.format(getTotalForField(entriesByProduct, "quantity")),
                        total: currencyFormatter.format(getTotalForField(entriesByProduct, "total")),
                    });
                }
            }
        }
        return aggregate;
    };


    if (isEmpty(salesReport)) {
        return "";
    }

    const bySalesRepAndCustomerAndProduct = salesBySalesRepAndCustomerAndProduct(salesReport) || [];

    return <div>
        <h3>Units Sold by Sales Rep, Customer, and Product</h3>
        <ReactTable
            sorted={sortOptions}
            onSortedChange={(newSorted, column, shiftKey) => {
                setSortOptions(newSorted);
            }}
            showPaginationBottom={false}
            getProps={() => { return { id: "sales-report-table" } }}
            columns={bySalesRepCustomerAndProductHeaders}
            pageSize={bySalesRepAndCustomerAndProduct.length}
            className="-striped -highlight mb-3"
            data={bySalesRepAndCustomerAndProduct}
        />
    </div>

}

export default SalesByRepCustomerAndProduct;
