import React from 'react';
import { createOrgUserGroups, destroyOrgUserGroups } from '../../redux/user/Action.js';
import SubmitButton from "../elements/SubmitButton.js";
import DeleteButton from "../elements/DeleteButton.js";
import { Table } from 'reactstrap';
import { useSelector, useDispatch } from 'react-redux';
import { ADMIN_ROLE, INVITED_ROLE, MEMBER_ROLE, SUPERADMIN_ROLE, CREATE, DESTROY } from '../../utils/Constants.js';
import { memberIsAdmin, memberIsSuperAdmin } from '../../utils/Roles.js';
import "react-widgets/styles.css";
import { updateUserRole, deleteOrganizationUser } from "../../redux/user/Action.js";
import Chip from "@mui/material/Chip/Chip.js";
import Select from "@mui/material/Select/Select.js";
import MenuItem from "@mui/material/MenuItem/MenuItem.js";
import OutlinedInput from '@mui/material/OutlinedInput/OutlinedInput.js';
import Box from '@mui/material/Box/Box.js';
import { showConfirmationPopup } from "../../redux/notifications/Action.js";
import { getAlteredGroupdIdAndAction } from '../../utils.js';
import "./style.scss";


const MemberTable = ({ loggedInUser, members, groups, onMemberDelete, onMemberUpdate }) => {
    const dispatch = useDispatch();

    const loggedInMember = members.find(m => m.id === loggedInUser.id);
    const organizationUsers = useSelector(state => state.userReducer.loggedInUser.organizationUsers);
    const selectedOrganization = useSelector(state => state.organizationReducer.selectedOrganization);

    const isAdmin = memberIsAdmin(loggedInMember, organizationUsers, selectedOrganization);
    const isSuperAdmin = memberIsSuperAdmin(loggedInMember, organizationUsers, selectedOrganization);

    const abortController = new AbortController();

    const promoteNewSuperAdmin = (newSuperAdmin, oldSuperAdmin) => {
        const newSuperAdminThroughId = newSuperAdmin.throughId;
        const oldSuperAdminThroughId = members.find(member =>
            member.id === oldSuperAdmin.id
        ).throughId;
        dispatch(updateUserRole(newSuperAdminThroughId, SUPERADMIN_ROLE, 'Member promoted.')).then(() => {
            dispatch(updateUserRole(oldSuperAdminThroughId, ADMIN_ROLE, 'You have been demoted.')).then(() => {
                onMemberUpdate(newSuperAdmin.id, SUPERADMIN_ROLE);
                onMemberUpdate(oldSuperAdmin.id, ADMIN_ROLE);
            });
        });
    }

    const getUserButtons = (member) => {
        if (isAdmin) {
            switch (member.role) {
                case SUPERADMIN_ROLE:
                    return;
                case ADMIN_ROLE:
                    if (isSuperAdmin) {
                        return (<>
                            <DeleteButton
                                id={`demote-${member.id}`}
                                onDelete={() => {
                                    dispatch(updateUserRole(member.throughId, MEMBER_ROLE, 'Member demoted.')).then(() => {
                                        onMemberUpdate(member.id, MEMBER_ROLE);
                                    });
                                }}>Demote</DeleteButton>
                            <SubmitButton
                                id={`promote-${member.id}`}
                                onSubmit={() => {
                                    dispatch(showConfirmationPopup(
                                        "Promote New Superadmin",
                                        `Are you sure you want to promote ${member.email} to superadmin? You will then lose your superadmin status.`,
                                        () => promoteNewSuperAdmin(member, loggedInUser),
                                        () => { },
                                        "confirm-promote-superadmin"
                                    ));
                                }}>Promote</SubmitButton >
                        </>)
                    }
                    return;
                case MEMBER_ROLE:
                    return (<>
                        <DeleteButton
                            id={`delete-${member.id}`}
                            onDelete={() => {
                                dispatch(showConfirmationPopup(
                                    "Remove User",
                                    `Are you sure you want to remove ${member.email} for this organization?`,
                                    () => {
                                        dispatch(deleteOrganizationUser(member.throughId)).then(() => {
                                            onMemberDelete(member.id);
                                        })
                                    },
                                    () => { },
                                    "confirm-remove-user"
                                ));
                            }}>Remove</DeleteButton>
                        <SubmitButton
                            id={`promote-${member.id}`}
                            onSubmit={() => {
                                dispatch(updateUserRole(member.throughId, ADMIN_ROLE, 'Member promoted.')).then(() => {
                                    onMemberUpdate(member.id, ADMIN_ROLE);
                                });
                            }}>Promote</SubmitButton>
                    </>)
                case INVITED_ROLE:
                    return (<>
                        <DeleteButton
                            id={`cancel-invitation-for-${member.id}`}
                            onDelete={() => {
                                dispatch(deleteOrganizationUser(member.throughId)).then(() => {
                                    onMemberDelete(member.id);
                                });
                            }}>Cancel Invitation</DeleteButton>
                    </>)
                default:
                    return;
            }
        }
    }

    const handleWSPRoleChange = (member, event) => {
        let selectedGroupIds = event.target.value.map(gid => parseInt(gid));
        const memberGroupIds = member.groups.map(group => parseInt(group.id));

        const [alteredGroupId, action] = getAlteredGroupdIdAndAction(selectedGroupIds, memberGroupIds, 1);

        const userGroups = member.groups.filter(group => {
            return selectedGroupIds.find(id => parseInt(id) === parseInt(group.id))
        });

        if (action === CREATE) {
            dispatch(createOrgUserGroups(alteredGroupId, member.throughId, abortController)).then(resp => {
                userGroups.push({
                    ...groups.find(g => parseInt(g.id) === parseInt(resp.group_id)),
                    organizationUserGroupId: resp.id
                });
                onMemberUpdate(member.id, member.role, userGroups);
            });
        } else if (action === DESTROY) {
            const organizationUserGroupId = member.groups.find(oug => {
                return oug.id === alteredGroupId;
            }).organizationUserGroupId;
            dispatch(destroyOrgUserGroups(organizationUserGroupId, abortController)).then(resp => {
                onMemberUpdate(member.id, member.role, userGroups);
            });
        } else {
            console.error("Something went wrong while setting groups.")
        }
    };

    const sortMembersByRole = () => {
        const superAdmin = members.filter(member => { return member.role === SUPERADMIN_ROLE }).sort((a, b) => {
            return a.email.localeCompare(b.email);
        });
        const admins = members.filter(member => { return member.role === ADMIN_ROLE }).sort((a, b) => {
            return a.email.localeCompare(b.email);
        });
        const orgMembers = members.filter(member => { return member.role === MEMBER_ROLE }).sort((a, b) => {
            return a.email.localeCompare(b.email);
        });
        const inviteds = members.filter(member => { return member.role === INVITED_ROLE }).sort((a, b) => {
            return a.email.localeCompare(b.email);
        });
        const sortedMembers = [...superAdmin, ...admins, ...orgMembers, ...inviteds];
        return sortedMembers;
    };

    if (members && members.length > 0) {
        return (<Table striped bordered id={"member-table"}>
            <thead>
                <tr>
                    <th className="col-1">Email</th>
                    <th className="col-1">Role</th>
                    <th className="col-1">WSP Roles</th>
                </tr>
            </thead>
            <tbody>
                {Object.values(sortMembersByRole()).map((member, id) => {
                    return <tr key={`member-${member.id}`} id={`member-row-${member.id}`} className={id % 2 === 0 ? "even-stripe" : "odd-stripe"}>
                        <td className="col-1 font-bold text-center member-email">{member.email}</td>
                        <td className="col-1 font-bold member-role">
                            <div className="text-center mb-2">{member.role}</div>
                            <div className="text-center">{getUserButtons(member)}</div>
                        </td>
                        <td className="col-1 font-bold text-center user-buttons">
                            <Select
                                disabled={!(isAdmin || isSuperAdmin)}
                                labelId={member.username + "-roles-label"}
                                id={member.username + "-roles"}
                                multiple
                                value={member.groups.map(group => group.id.toString())}
                                onChange={value => handleWSPRoleChange(member, value)}
                                input={<OutlinedInput id={`select-roles-chip-member-${member.id}`} label="Chip" />}
                                defaultValue={member.groups.map(group => group.id.toString())}
                                renderValue={(selectedGroupIds) => {
                                    return <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
                                        {selectedGroupIds.map((groupId) => {
                                            // key here may be incorrect
                                            const group = groups.find(g => parseInt(g.id) === parseInt(groupId));
                                            return <Chip key={group.id.toString()} label={group.name} />
                                        })}
                                    </Box>
                                }}>
                                {(groups || []).map((group) => {
                                    return <MenuItem
                                        key={member.username + "-menuitem-" + group.id}
                                        value={group.id.toString()}>{group.name}</MenuItem>
                                })}
                            </Select>
                        </td>
                    </tr>
                })}
            </tbody>
        </Table>
        );
    } else {
        return (
            <h2 id='no-members' className="font-bold border-righ text-center mb-4">There are currently no members in this organization</h2>
        );
    }
}

export default MemberTable
