import React from "react";
import { useEffect, useState } from "react";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import MetrcAccount from "../../../components/forms/MetrcAccount.js";
import { Container, Grid, Tab, Tabs } from "@mui/material";
import { fetchMetrcAccounts, fetchMetrcBackfills } from "../../../redux/models/integrations/Action.js";
import { isNullOrUndefinedOrEmpty } from "../../../utils.js";
import { ABORTED } from "../../../redux/constants.js";
import { Backfills } from "./Backfills.js";
import Loading from "../../../components/elements/Loading.js";
import {
    BACKFILLS,
    ROUTES_INTEGRATIONS_METRC_DETAIL_BACKFILLS,
    AUTHENTICATION,
    ROUTES_INTEGRATIONS_METRC_DETAIL_AUTHENTICATION
} from "../../../utils/Constants.js";


const Detail = ({ tab }) => {
    const params = useParams();
    const dispatch = useDispatch();
    const metrcAccountId = parseInt(params.id)
    const [searchParams, setSearchParams] = useSearchParams();
    const navigate = useNavigate();

    const [metrcAccounts, setMetrcAccounts] = useState([]);

    const loggedInUser = useSelector(state => state.userReducer.loggedInUser.user);

    const TabPanel = ({ children, index }) => {
        if (index === selectedTab) {
            return <>{children}</>
        }
        return ""
    }

    useEffect(() => {
        const abortController = new AbortController();
        dispatch(fetchMetrcAccounts({}, abortController)).then((fetchedMetrcAccounts) => {
            setMetrcAccounts(fetchedMetrcAccounts);
        }).catch(err => {
            if (err !== ABORTED) {
                console.error(err);
            }
        });
        return () => {
            abortController.abort();
        }
    }, [loggedInUser.id]);

    const tabs = {
        null: 1,
        undefined: 1,
        [AUTHENTICATION]: 1,
        [BACKFILLS]: 2,
    }

    const whichTab = searchParams.get("tab") || tab;
    const metrcAccount = metrcAccounts.find(ma => parseInt(ma.id) === metrcAccountId);
    const [selectedTab, setSelectedTab] = useState(tabs[whichTab]);
    const selectTab = (event, newValue) => {
        setSelectedTab(newValue);
        if (newValue === tabs[BACKFILLS]) {
            navigate(ROUTES_INTEGRATIONS_METRC_DETAIL_BACKFILLS.replace(":id", metrcAccountId))
        } else if (newValue === tabs[AUTHENTICATION]) {
            navigate(ROUTES_INTEGRATIONS_METRC_DETAIL_AUTHENTICATION.replace(":id", metrcAccountId))
        }
    }

    if (isNullOrUndefinedOrEmpty(metrcAccount)) {
        return <Loading />;
    }

    return <Container className="metrc-account-detail workspace">
        <Grid container spacing={2}>
            <Grid item xs={12}>
                <h1>Metrc Account Details</h1>
                <h2>View/Edit</h2>
            </Grid>
        </Grid>
        <Tabs value={selectedTab} onChange={selectTab}>
            <Tab label="Authentication" value={1} tabIndex={1} />
            <Tab label="Backfills" value={2} tabIndex={2} />
        </Tabs>
        <TabPanel index={1}>
            <MetrcAccount
                create={false}
                enableDelete={true}
                enableEdit={true}
                {...metrcAccount}
            />
        </TabPanel>
        <TabPanel index={2} onClick={() => navigate(ROUTES_INTEGRATIONS_METRC_DETAIL_BACKFILLS.replace(":id", metrcAccountId))} >
            <Backfills metrcAccountId={metrcAccountId} />
        </TabPanel>
    </Container >
}


export default Detail;