import React from "react";
import { useEffect, useState } from "react";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import AlpineIQAccount from "../../../components/forms/AlpineIQAccount.js";
import { Container, Grid, Tab, Tabs } from "@mui/material";
import { fetchAlpineIQAccounts } from "../../../redux/models/integrations/Action.js";
import { isNullOrUndefinedOrEmpty } from "../../../utils.js";
import { ABORTED } from "../../../redux/constants.js";
import Loading from "../../../components/elements/Loading.js";
import {
    AUTHENTICATION,
    ROUTES_INTEGRATIONS_METRC_DETAIL_AUTHENTICATION
} from "../../../utils/Constants.js";


const Detail = ({ tab }) => {
    const params = useParams();
    const dispatch = useDispatch();
    const alpineIQAccountId = parseInt(params.id);
    const [searchParams, setSearchParams] = useSearchParams();
    const [alpineIQAccount, setAlpineIQAccount] = useState(null);
    
    const navigate = useNavigate();

    const loggedInUser = useSelector(state => state.userReducer.loggedInUser.user);

    const TabPanel = ({ children, index }) => {
        if (index === selectedTab) {
            return <>{children}</>;
        }
        return "";
    };

    useEffect(() => {
        const abortController = new AbortController();
        dispatch(fetchAlpineIQAccounts({id: alpineIQAccountId}, abortController)).then((fetchedAlpineIQAccounts) => {
            setAlpineIQAccount(fetchedAlpineIQAccounts[0]);
        }).catch(err => {
            if (err !== ABORTED) {
                console.error(err);
            }
        });
        return () => {
            abortController.abort();
        }
    }, [loggedInUser.id]);

    const tabs = {
        null: 1,
        undefined: 1,
        [AUTHENTICATION]: 1,
    };

    const whichTab = searchParams.get("tab") || tab;
    const [selectedTab, setSelectedTab] = useState(tabs[whichTab]);
    
    const selectTab = (event, newValue) => {
        setSelectedTab(newValue);
        if (newValue === tabs[AUTHENTICATION]) {
            navigate(ROUTES_INTEGRATIONS_METRC_DETAIL_AUTHENTICATION.replace(":id", alpineIQAccountId))
        }
    };

    if (isNullOrUndefinedOrEmpty(alpineIQAccount)) {
        return <Loading />;
    }

    return <Container className="alpineIQ-account-detail workspace">
        <Grid container spacing={2}>
            <Grid item xs={12}>
                <h1>AlpineIQ Account Details</h1>
                <h2>View/Edit</h2>
            </Grid>
        </Grid>
        <Tabs value={selectedTab} onChange={selectTab}>
        <Tab label="Authentication" value={1} tabIndex={1} />
        </Tabs>
        <TabPanel index={1}>
            <AlpineIQAccount
                create={false}
                enableDelete={true}
                enableEdit={true}
                {...alpineIQAccount}
            />
        </TabPanel>
    </Container >
};


export default Detail;