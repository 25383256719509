import {
    SET_METRC_ACCOUNTS,
    SET_METRC_BACKFILLS
} from "../../constants.js";

const defaultState = {
    "totalIntegrations": 0,
    "metrcAccounts": [],
    "metrcBackfills": []
};

const IntegrationReducer = (state = defaultState, action) => {
    switch (action.type) {
        case SET_METRC_ACCOUNTS:
            const previous = state.metrcAccounts.length;
            const current = action.accounts.length;
            return {
                ...state,
                totalIntegrations: state.totalIntegrations - previous + current,
                metrcAccounts: action.accounts
            };
        case SET_METRC_BACKFILLS:
            return {
                ...state,
                metrcBackfills: action.backfills
            };
        default:
            return state;
    }
};


export default IntegrationReducer;
