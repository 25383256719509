import {isEmpty} from "weed-js";

export class Filter {

    constructor(field, value, cast) {
        this.field = field;
        this.value =
            value === undefined || value === null ? null : field.cast(value);
    }

    shouldSkip(point) {
        const pointValue = this.field.run(point);
        const cast = pointValue === null || pointValue === undefined ? null :
                     this.field.cast(pointValue);
        const result = this.value === cast;
        if(pointValue && this.value && typeof pointValue !==
           typeof this.value) {
            console.warn("Point value", pointValue, "is not the same type as result", this.value, "for field", this.field);
        }
        return !result;
    }
}


export class Filters {
    constructor(fields) {
        this.fields = fields.filter(f => f.filterable);
        this.selectedFilters = [];
        this.callbacks = {}
        if(isEmpty(this.fields)) {
            throw new Error("No filterable fields were found in your report. You must pass the option {filterable: true} for at least one of them.")
        }
    }

    clearCallbacks() {
        this.callbacks = {};
    }

    addCallback(name, callback) {
        this.callbacks[name] = callback;
    }

    call(filters) {
        Object.values(this.callbacks).forEach(callback => callback(filters));
    }

    key() {
        const serializable = this.selectedFilters.map(f => `${f.field.displayName}:${f.value}`)
        serializable.sort()
        return serializable.join("::")
    }

    add(field, value) {
        this.selectedFilters.push(new Filter(field, value));
    }

    clear(field) {
        this.selectedFilters =
            this.selectedFilters.filter(f => f.field.displayName !==
                                             field.displayName);
    }

    set(filters) {
        this.selectedFilters = filters;
    }

    remove(field, value) {
        this.selectedFilters =
            this.selectedFilters.filter(f => f.field !== field && f.value !==
                                             value)
    }

    shouldSkip(point) {
        const trigger = this.selectedFilters.find(f => f.shouldSkip(point));
        return trigger !== null && trigger !== undefined;
    }
}