import React from "react";
import Button from "@mui/material/Button/Button.js";
import { isNullOrUndefinedOrEmpty } from "../../utils.js";

const SubmitButton = (props) => {
    return <Button
        className={`submit ${props.className}`}
        disabled={props.disabled}
        id={isNullOrUndefinedOrEmpty(props.id) ? "submit" : props.id}
        variant={props.variant || "contained"}
        type="submit"
        color={props.color || "primary"}
        onClick={props.onSubmit || props.onClick}
        >
        {props.children}
    </Button>
}

export default SubmitButton;