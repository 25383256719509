import React from "react";
import CreateAccountForm from "../../forms/CreateAccount.js";
import MemberTable from "../MemberTable.js";
import {useDispatch, useSelector} from "react-redux";
import {userLoggedIn} from "../../../redux/user/Action.js";


const Membership = ({organization, members, setMembers, groups}) => {

    const dispatch = useDispatch();
    const loggedInUser = useSelector(state => state.userReducer.loggedInUser.user);
    const organizations = useSelector(state => state.userReducer.loggedInUser.organizations);
    const organizationUsers = useSelector(state => state.userReducer.loggedInUser.organizationUsers);

    return <>
        <CreateAccountForm
            displayText="Add another user"
            organizationId={organization.id}
            onCreated={(createdUser, createdOrganizationUser) => {
                dispatch(userLoggedIn({
                    user: loggedInUser,
                    organizationUsers: [...organizationUsers, createdOrganizationUser],
                    organizations: organizations
                }));
                setMembers([...members, {
                    ...createdUser,
                    throughId: createdOrganizationUser.id,
                    role: createdOrganizationUser.role,
                    groups: []
                }])
            }}
        />
        <MemberTable
            loggedInUser={loggedInUser}
            members={members}
            groups={groups}
            onMemberDelete={(deletedUserId) => {
                setMembers(members.filter(member => member.id !== deletedUserId));
            }}
            onMemberUpdate={(updatedUserId, newRole, newGroups) => {
                if (updatedUserId === loggedInUser.id) {
                    loggedInUser.role = newRole;
                }
                setMembers(members.map(member => {
                    if (member.id === updatedUserId) {
                        member.role = newRole;
                        if (newGroups !== null && newGroups !== undefined) {
                            member.groups = newGroups;
                        }
                    }
                    return member;
                }));
                dispatch(userLoggedIn({
                    user: loggedInUser,
                    organizationUsers: organizationUsers,
                    organizations: organizations
                }));
            }}
        />
    </>
}

export default Membership;