import {Field} from "./Field.js";
import {isEmpty} from "weed-js";

export const Rollups = {
    HOURLY: 0, DAILY: 1, WEEKLY: 2, MONTHLY: 3, ANNUALLY: 4, YEARLY: 4, MICROSECOND: 5,
};


export class TimeRangeQuery {
    constructor(observationField, startDateTime, endDateTime, rollup, extraParams) {
        this.field = new Field("timestamp")
        this.timestampSuffix = "timestamp";
        this.observationField = observationField;
        this.startDateTime = startDateTime;
        this.endDateTime = endDateTime;
        this.rollup = rollup;
        this.extraParams = isEmpty(extraParams) ? {} : extraParams;
        this.lastUpdated = new Date();
    }

    timestampFieldName() {
        return `${this.observationField.name}__${this.timestampSuffix}`
    }

    getLastUpdated() {
        return this.lastUpdated;
    }

    toParams() {
        const params = {...this.extraParams};
        params[`${this.observationField.name}__rollup`] = this.rollup;
        params[`${this.timestampFieldName()}__gte`] = this.startDateTime.toISOString();
        params[`${this.timestampFieldName()}__lte`] = this.endDateTime.toISOString();
        return params;
    }
}

