import React from 'react';
import ResetPasswordForm from "../components/forms/ResetPassword.js";
import { Container } from '@material-ui/core';
import "./styles/ResetPassword.css";


const ResetPassword = () => {
    return <Container className="workspace">
        <h1 id="reset-password-header">Reset Password</h1>
        <ResetPasswordForm />
    </Container>
};

export default ResetPassword;
