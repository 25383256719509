import {Reference} from "./reference.js";
import {Segment} from "./segment.js";
import {isEmpty} from "./utils.js";


export class NoMatchingPathFound extends Error {

}

export class NoOverride {
    constructor(value) {
        this.value = value;
    }

    get(candidate) {
        if (candidate !== undefined) {
            return candidate;
        }
        return this.value;
    }
}


const shouldPrint = (pathValue) => {
    return pathValue.includes("bill");
}


export class JSONPath {

    constructor(value) {
        this.value = value;
    }

    addArraysAt(reference, segmentIndices) {
        return segmentIndices.reduce((reference, segmentIndex) => {
            if (reference.value() === undefined) {
                reference.set([])
            }
            while (reference.value().length <= segmentIndex) {
                reference.value().push(undefined);
            }
            return new Reference(reference.value(), segmentIndex, reference);
        }, reference);
    }

    addMapsAt(reference, key) {
        if (reference.value() === undefined) {
            reference.set({[key]: {}});
        } else if (reference.value()[key] === undefined) {
            reference.value()[key] = {}
        }
        return new Reference(reference.value(), key, reference);
    }

    buildPathToTerminus(reference, path) {
        const segments = Segment.fromPath(path);
        return segments.reduce((ref, segment) => {
            if (!isEmpty(segment.name) && segment.indices.length === 0) {
                return this.addMapsAt(ref, segment.name);
            } else if (!isEmpty(segment.name) && segment.indices.length > 0) {
                return this.addArraysAt(ref, [segment.name, ...segment.indices]);
            } else {
                return this.addArraysAt(ref, segment.indices);
            }
            console.warn("Unhandled case in buildPathToTerminus");
            return ref;
        }, reference);
    }

    concat(path) {
        if (this.value === undefined) {
            if (path instanceof JSONPath) {
                return path;
            }
            return new JSONPath(path);
        }
        if (path instanceof JSONPath) {
            if (path.value[0] === "[") {
                return new JSONPath([this.value, path.value].join(""));
            }
            return new JSONPath([this.value, path.value].join("."));
        }
        if (path[0] === "[") {
            return new JSONPath([this.value, path].join(""));
        }
        return new JSONPath([this.value, path].join("."));
    }

    static merge(obj, defaults) {
        if (defaults === undefined) {
            return obj;
        }

        if (obj instanceof Array && defaults instanceof Array) {
            defaults.map((defaultValue, index) => {
                if (index >= obj.length) {
                    obj.push(defaultValue);
                }
                if (obj[index] === undefined) {
                    obj[index] = defaultValue;
                }
            })
            return obj;
        }

        JSONPath.walk(defaults, (defaultValue, path) => {
            try {
                const observedValue = path.get(obj);
                if (observedValue === undefined && defaultValue !== undefined) {
                    path.set(obj, defaultValue, true);
                }
            } catch (error) {
                console.error(error)
                if (error instanceof NoMatchingPathFound) {
                    console.warn("No matching path found for ", path.value, " in ", obj, ".");
                    console.warn("When we find a value other than `undefined` we don't \n \
                       overwrite it or it's children with a default value. \n \
                       This is to prevent overwriting user input with defaults (which are perfectly valid to be null).");
                } else {
                    throw error
                }
            }
        });
        return obj;
    }

    static walk(obj, callback) {
        const walk = (obj, path) => {
            if (obj instanceof Array) {
                obj.forEach((value, index) => {
                    walk(value, path.concat(new JSONPath(`[${index}]`)));
                });
            } else if (obj instanceof Object) {
                Object.keys(obj).forEach(key => {
                    walk(obj[key], path.concat(new JSONPath(key)));
                });
            } else {
                callback(obj, path);
            }
        }
        walk(obj, new JSONPath());
    }

    static refWalk(reference, callback) {
        const walk = (ref, path) => {
            if (ref.value() instanceof Array) {
                ref.value().forEach((value, index) => {
                    walk(new Reference(ref.value(), index, ref), path.concat(new JSONPath(`[${index}]`)));
                });
            } else if (ref.value() instanceof Object) {
                Object.keys(ref.value()).forEach(key => {
                    walk(new Reference(ref.value(), key, ref), path.concat(new JSONPath(key)));
                });
            } else {
                callback(ref, path);
            }
        }
        walk(reference, new JSONPath());
    }

    get(obj, defaults) {
        if (obj === undefined) {
            console.warn(`You're attempting to retrieve a path ${this.value} from an object that is undefined. Defaults will be returned instead, but cannot be set (by e.g. reference).`)
            return defaults;
        }
        const found = Segment.fromPath(this).reduce((cursor, segment) => {
            if (cursor === undefined) {
                return undefined;
            }
            if (cursor === null) {
                throw new NoMatchingPathFound(`No matching path found for ${this.value}. We found a null value as the parent of at ${segment.name} that stopped further traversal.`);
            }
            cursor = cursor[segment.name];
            return segment.indices.reduce((_cursor, index) => {
                if (_cursor === undefined) {
                    return undefined;
                }
                return _cursor[index];
            }, cursor);
        }, obj)

        if (defaults === undefined) {
            return found;
        }
        if (found === undefined) {
            this.set(obj, defaults, true);
            return this.get(obj, defaults);
        }
        const merged = JSONPath.merge(found, defaults);
        //console.log("Merged with defaults at", this.value, "to get", JSON.stringify(found, null, 2), "+", JSON.stringify(defaults, null, 2), "=", merged);
        return merged;
    }

    set(obj, value, byReference = false) {
        if (byReference) {
            const root = new Reference({"$": obj}, "$", null)
            this.buildPathToTerminus(root, this).set(value);
            return root.value()
        }
        const root = (
            obj
            instanceof Array ?
                new Reference({"$": [...obj]}, "$", null) :
                new Reference({"$": {...obj}}, "$", null)
        )
        this.buildPathToTerminus(root, this).set(value);
        return root.value()
    }

    shift() {
        const segments = Segment.fromPath(this);
        segments.shift();
        return Segment.toPath(segments);
    }

    pop() {
        const segments = Segment.fromPath(this);
        segments.pop();
        return Segment.toPath(segments);
    }

    last() {
        const segments = Segment.fromPath(this);
        return segments[segments.length - 1];
    }

    segments() {
        return Segment.fromPath(this);
    }

    static fromSegments = (segments) => {
        return Segment.toPath(segments);
    }
}
