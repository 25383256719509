import {isEmpty} from "weed-js";


export class Timeseries {

    constructor(report, dataPoints) {
        this.dataPoints = dataPoints;
        this.lastUpdated = new Date();
        this.report = report;

        this._filtered = {}
        this.filter();

    }

    getFiltered() {
        if (!this._filtered.hasOwnProperty(this.report.getFilters().key())) {
            this.filter();
        }
        return this._filtered[this.report.getFilters().key()];
    }

    getXValues(type) {
        /*
        Returns the entire domain regardless of filtering. This keeps x-axis consistent.
         */
        if (this.report.isAggregate(type)) {
            const xValues = this.getFiltered().map(point => {
                return this.report.getGroups().key(point) || this.report.calculation.name;
            });
            const unique = new Set(xValues);
            return Array.from(unique).sort();
        }
        const domain = Array.from(this.dataPoints.reduce((domain, point) => {
            domain.add(this.report.getTimestampField().run(point));
            return domain;
        }, new Set()));

        domain.sort()
        return domain;
    }

    getYValues(points) {
        return points.map(ts => this.report.calculation.run(ts));
    }

    filter() {
        this._filtered[this.report.getFilters().key()] = this.dataPoints.filter(
            point => !this.report.getFilters().shouldSkip(point)
        )
        return this;
    }

    reset() {
        this._filtered = {}
        this.filter();
        return this;
    }

    group(type) {
        const groups = this.report.getGroups();
        const domain = this.getXValues(type);
        const dateToIndex = Object.fromEntries(domain.map((date, idx) => {
            return [date, idx];
        }));
        return this.getFiltered().reduce((grouped, point) => {
            const key = groups.key(point) || this.report.calculation.name;
            const date = this.report.getTimestampField().run(point);
            const idx = dateToIndex[date];

            if(!grouped.hasOwnProperty(key)) {
                grouped[key] = domain.map(date => this.report.calculation.getAggregate());
            }

            grouped[key][idx].run(point)
            return grouped;
        }, {})
    }

    aggregate(type) {
        const groups = this.report.getGroups();
        const xValues = this.getXValues(type);
        const grouped = this.getFiltered().reduce((grouped, point) => {
            const key = groups.key(point) || this.report.calculation.name;

            if(!grouped.hasOwnProperty(key)) {
                grouped[key] = this.report.calculation.getAggregate();
            }

            grouped[key].run(point)

            return grouped;
        }, {})
        return xValues.map(x => {
            return grouped[x];
        });
    }

    setCardinality() {
        this.report.fields().forEach(field => {field.cardinality = 0});
        return this.dataPoints.reduce((cardinality, point) => {
            return this.report.fields().reduce((card, field) => {
                if (!field.groupable) {
                    return card;
                }
                const value = field.run(point)
                if (!card.hasOwnProperty(field.displayName)) {
                    card[field.displayName] = new Set();
                }
                if (value !== null && value !== undefined) {
                    if (!card[field.displayName].has(value)) {
                        card[field.displayName].add(value);
                        field.cardinality += 1;
                    }
                }
                return card;
            }, cardinality);
        }, {});
    }

    update(dataPoints) {
        this.dataPoints = this.dataPoints.concat(dataPoints);
        this.reset();
        this.lastUpdated = new Date();
        try {
            this.setCardinality();
        } catch (e) {
            console.error(e);
        }
        return this;
    }
}

export default Timeseries;