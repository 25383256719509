import React, {useEffect, useState} from "react";
import {Autocomplete, Container, Grid} from "@mui/material";
import {DatePicker} from "@mui/x-date-pickers/DatePicker/DatePicker.js";
import {LocalizationProvider} from "@mui/x-date-pickers/LocalizationProvider/LocalizationProvider.js";
import {AdapterDayjs} from "@mui/x-date-pickers/AdapterDayjs/AdapterDayjs.js";
import Loading from "../../components/elements/Loading.js";
import dayjs from "dayjs";
import {TextField} from "@material-ui/core";
import {useDispatch, useSelector} from "react-redux";
import {fetchLatestPurchasesReport, fetchSalesReport} from "../../redux/Actions.js";
import {accountIsStillBeingSetUp, formatDate, isNullOrUndefinedOrEmpty} from "../../utils.js";

import Leaderboard from "../../components/reports/home/Leaderboard.js";
import SalesByRepAndCustomer from "../../components/reports/home/SalesByRepAndCustomer.js";
import SalesByRepCustomerAndProduct from "../../components/reports/home/SalesByRepCustomerAndProduct.js";
import SalesByRepAndCategory from "../../components/reports/home/SalesByRepAndCategory.js";
import LatestCustomerPurchases from "../../components/reports/home/LatestCustomerPurchases.js";
import AggregateChart from "../../components/reports/home/AggregateChart.js";
import {memberIsAdmin} from "../../utils/Roles.js";
import {showInformationalPopup} from "../../redux/notifications/Action.js";
import {selectOrganization} from "../../redux/models/organizations/Action.js";


export const Dashboard = () => {
    const organizations = useSelector(state => state.userReducer.loggedInUser.organizations);
    const currentUser = useSelector(state => state.userReducer.loggedInUser.user);
    const organizationUsers = useSelector(state => state.userReducer.loggedInUser.organizationUsers);
    const organizationOptions = organizations.map((org) => {
        return {"label": org.name, "value": org.id};
    })
    const [salesReport, setSalesReport] = useState([]);
    const [purchaseReport, setPurchaseReport] = useState([]);
    const selectedOrganization = useSelector(state => state.organizationReducer.selectedOrganization);
    const [loading, setLoading] = useState(false);

    const today = new Date();
    const firstOfCurrentMonth = new Date(today.getFullYear(), today.getMonth(), 1);
    const [endDate, setEndDate] = useState(dayjs(today));
    const [startDate, setStartDate] = useState(dayjs(firstOfCurrentMonth));
    const incompleteAccounts = useSelector(state => state.userReducer.loggedInUser.accountStatuses || []).filter((status) => {
        return accountIsStillBeingSetUp(status);
    });


    const dispatch = useDispatch();

    useEffect(() => {
        if (salesReport && salesReport.length > 0 || !selectedOrganization) {
            return;
        }
        const abortController = new AbortController();
        const organizationIds = [selectedOrganization.id];

        const params = {
            start_date: formatDate(startDate),
            end_date: formatDate(endDate),
            organization_ids: (organizationIds || []).join(","),
        };

        setLoading(true);
        Promise.all([
            dispatch(fetchSalesReport(params, abortController)),
            dispatch(fetchLatestPurchasesReport(abortController)),
        ]).then(([fetchedSalesReport, fetchedPurchaseReport]) => {
            setSalesReport(fetchedSalesReport);
            setPurchaseReport(fetchedPurchaseReport);
            setLoading(false);
        }).catch((error) => {
            console.error(error);
            setLoading(false);
        });

        return () => {
            abortController.abort();
        }
    }, [selectedOrganization, startDate, endDate]);


    const organizationChangeHandler = (event, selectedOption, reason, details) => {
        if (isEmpty(selectedOption)) {
            return;
        }
        const stillSettingUp = incompleteAccounts.find((account) => {
            return account.organization_id === selectedOption.value;
        })
        const userIsAdmin = memberIsAdmin(currentUser, organizationUsers, selectedOrganization);
        if (!isNullOrUndefinedOrEmpty(stillSettingUp)) {
            if (userIsAdmin) {
                dispatch(
                    showInformationalPopup(
                        "Account Setup Incomplete",
                        "You can't view sales reports until this organization's account is fully set up. Please complete the steps listed in the bottom left corner of the screen."
                    )
                );
            } else {
                dispatch(
                    showInformationalPopup(
                        "Account Setup Incomplete",
                        `You can't view sales reports for ${selectedOption.label} until this organization's account is fully set up. Talk to your account administrator to complete the setup.`
                    )
                );
            }
        }
        dispatch(selectOrganization(organizations.find(o => o.id === selectedOption.value)));
    };

    return (<Container className={"workspace dashboard"} id="sales-report">
        <Grid container spacing={12} className="graphs">
            <Grid item xs={12}>
                <p>Select a date range to view reports</p>
            </Grid>
            <Grid item xs={6}>
                <div className="control">
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DatePicker label="Start Date" value={dayjs(startDate)} onChange={(newDate) => {
                            setStartDate(dayjs(newDate))
                        }}/>
                    </LocalizationProvider>
                </div>
            </Grid>
            <Grid item xs={6}>
                <div className="control">
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DatePicker label="End Date" value={dayjs(endDate)} onChange={(newDate) => {
                            setEndDate(dayjs(newDate))
                        }}/>
                    </LocalizationProvider>
                </div>
            </Grid>
            <Grid item xs={12}>
                <AggregateChart salesReport={salesReport} currentUser={currentUser}/>
            </Grid>
            <Grid item xs={12}>
                <Leaderboard salesReport={salesReport}/>
            </Grid>
            <Grid item xs={12}>
                <SalesByRepAndCustomer salesReport={salesReport}/>
            </Grid>
            <Grid item xs={12}>
                <SalesByRepAndCategory salesReport={salesReport}/>
            </Grid>
            <Grid item xs={12}>
                <SalesByRepCustomerAndProduct salesReport={salesReport}/>
            </Grid>
            <Grid item xs={12}>
                <LatestCustomerPurchases purchaseReport={purchaseReport}/>
            </Grid>
        </Grid>
    </Container>);
}

export default Dashboard;