import {
  HIDE_NOTIFICATION,
  CLOSE_SNACKBAR,
  ENQUEUE_SNACKBAR,
  REMOVE_SNACKBAR,
  POPUP_TYPE_CONFIRM,
  POPUP_TYPE_INFORMATIONAL
} from '../constants.js';

export const enqueueSnackbar = (notification) => {
  const key = notification.options && notification.options.key;

  return {
    type: ENQUEUE_SNACKBAR,
    notification: {
      ...notification,
      key: key || new Date().getTime() + Math.random(),
    },
  };
};

export const closeSnackbar = key => ({
  type: CLOSE_SNACKBAR,
  dismissAll: !key, // dismiss all if no key has been defined
  key,
});

export const removeSnackbar = key => ({
  type: REMOVE_SNACKBAR,
  key,
});


export const showPopup = (type, message, id) => {
  return dispatch => {
    return dispatch({
      type: type,
      message: message,
      id: id
    });
  }
}

export const showConfirmationPopup = (title, message, onConfirm, onDismiss, id) => {
  return dispatch => {
    return dispatch({
      type: POPUP_TYPE_CONFIRM,
      title: title,
      message: message,
      onConfirm: onConfirm,
      onDismiss: onDismiss,
      id: id
    });
  }
}

export const showInformationalPopup = (title, message, onConfirm, onDismiss, id, action) => {
  return dispatch => {
    return dispatch({
      type: POPUP_TYPE_CONFIRM,
      title: title,
      message: message,
      onConfirm: onConfirm,
      onDismiss: onDismiss,
      id: id,
      action: action
    });
  }
}

export const hidePopup = () => {
  return dispatch => {
    return dispatch({
      type: HIDE_NOTIFICATION
    })
  }
}