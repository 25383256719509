import {
    SET_WHOLESALE_LOCATIONS
} from "../constants.js";

const defaultState = {
    "locations": []
};

const WholesaleReducer = (state = defaultState, action) => {
    switch (action.type) {
        case SET_WHOLESALE_LOCATIONS:
            return {
                ...state,
                locations: action.locations
            };
        default:
            return state;
    }
};


export default WholesaleReducer;
