import axios from "axios";
import { getCookie } from "./utils.js";


const buildBackend = (domain, prefix) => {
    const baseURL = getBaseUrl(domain, prefix);

    const dispatch = (f) => {
        return f(dispatch);
    }

    let instance = axios.create({
        baseURL: baseURL,
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
        },
    });

    instance.interceptors.response.use(function (response) {
        return response;
    }, function (error) {
        if (error.response === undefined) {
            return Promise.reject(error);
        }

        if (error.response.status === 401 || error.response.status === 403) {
            // Security: TODO: log people out when they 401.
            // services.api().get("/accounts/logout");
            return Promise.reject(error);
        } else {
            return Promise.reject(error);
        }

    });

    instance.defaults.xsrfHeaderName = "X-CSRFToken"
    instance.defaults.xsrfCookieName = 'sso.csrf'
    instance.defaults.withCredentials = true

    return instance;
}

export const getBaseUrl = (domain, apiPrefix) => {
    return `${process.env.REACT_APP_HTTP_SCHEME}://${domain}${apiPrefix}`;
}

class Backends {

    _getAPITLD() {
        return getCookie("TEST_SSO_TLD") || process.env.REACT_APP_API_TLD;
    }

    _getWholesaleTLD() {
        return getCookie("TEST_WHOLESALE_TLD") || process.env.REACT_APP_WHOLESALE_TLD;
    }

    dal() {
        return buildBackend(this._getAPITLD(), "/dal/v1");
    }

    api() {
        return buildBackend(this._getAPITLD(), "/api/v1");
    }

    wholesale_v1() {
        return buildBackend(this._getWholesaleTLD(), "/api/v1");
    }

    wholesale_v3() {
        return buildBackend(this._getWholesaleTLD(), "/api/v3");
    }

}

export default new Backends();
