import React, {useEffect, useRef, useState} from "react";
import {Chart} from "react-chartjs-2";
import {Chart as ChartJS, registerables} from "chart.js";
import {useDispatch} from "react-redux";
import {Autocomplete, Grid, TextField, Tooltip} from "@mui/material";
import {Loading} from "../../elements/Loading.js";
import {
    CloseFullscreenOutlined,
    FilterListOutlined,
    FullscreenOutlined,
    InfoOutlined,
    JoinFullOutlined
} from "@mui/icons-material";
import {
    showInformationalPopup, showPopup
} from "../../../redux/notifications/Action.js";
import {FILTER_BY, GROUP_BY} from "../../../views/Analytics/constants";

ChartJS.register(...registerables);

const GroupBy = ({report}) => {

    const options = [
        ...report.getGroups().fields.map(f => {
            return {value: f.displayName, label: f.displayName};
        }),
    ]
    const [groupedFields, setGroupedFields] = useState(report.getDefaultGrouping().map(f => {
        return {value: f.displayName, label: f.displayName};
    }));

    useEffect(() => {
        report.getGroups().chooseByDisplayNames(groupedFields.map(option => option.value));
        report.getGroups().call([...groupedFields]);
    }, [groupedFields, report.getDefaultGrouping()]);


    return <Grid container spacing={12}>
        <Grid item xs={12} className={"options"}>
            <p className={"group-by-label"}>Group By</p>
            <Autocomplete
                disabled={report.isEmpty()}
                className={"observer-chart-dropdown"}
                id={report.title + "-id"}
                key={report.title + "-groupby"}
                multiple
                fullWidth={true}
                autoComplete={true}
                filterSelectedOptions={true}
                isOptionEqualToValue={(option, value) => {
                    return option.value === value.value;
                }}
                disablePortal={true}
                options={options}
                value={report.getGroups().groupedFields.map(f => {
                    return {value: f.displayName, label: f.displayName};
                })}
                getOptionLabel={option => option.label}
                onChange={(evt, chosen, reason) => {
                    //report.groups.chooseByDisplayNames(chosen.map(option =>
                    // option.value));
                    setGroupedFields((prevState, props) => {
                        return chosen
                    });
                }}
                renderInput={(params) => {
                    return <TextField {...params}
                                      className={"observer-chart-dropdown"}
                                      fullWidth/>
                }}
            />
        </Grid>
    </Grid>;
}


const FilterByField = ({report, field}) => {
    const key = `${report.title}-filter-by-${field.displayName}`;
    const [filteredFields, setFilteredFields] = useState([]);
    const options = field.valuesOf(report.getTimeSeries()).filter(v => v !==
                                                                       null &&
                                                                       v !==
                                                                       undefined).map(value => {
        return {
            label: value.toString(), value: value.toString()
        }
    });

    useEffect(() => {
        report.getFilters().call(filteredFields);
    }, [filteredFields]);

    return <>
        <Autocomplete
            id={key + "-id"}
            key={key}
            disablePortal={true}
            className={"observer-chart-dropdown"}
            multiple
            autoComplete={true}
            filterSelectedOptions={true}
            isOptionEqualToValue={(option, value) => {
                return option.value.toString() === value.value.toString();
            }}
            options={options}
            value={filteredFields}
            getOptionLabel={option => option.label}
            onChange={(evt, chosen, reason) => {
                report.getFilters().clear(field);
                for(let i = 0; i < chosen.length; i++) {
                    const choice = chosen[i];
                    report.getFilters().add(field, choice.value);
                }
                setFilteredFields(chosen);
            }}
            renderInput={(params) => {
                return <TextField {...params} label={field.displayName}
                                  fullWidth/>
            }}
        />
    </>
}

const FilterBy = ({report}) => {


    useEffect(() => {
    }, [report.getFilters().selectedFilters]);

    return <Grid container spacing={12}>
        <Grid item xs={12} className={"options"}>
            <p className={"filter-by-label"}>Filter By</p>
            {report.getFilters().fields.map(f => {
                return <FilterByField
                    key={`filter-by-${f.displayName}-filterbyfield`}
                    field={f}
                    report={report}
                />
            })}
        </Grid>
    </Grid>
}

const ObserverChart = ({report, chartType}) => {
    /*

    TODO: Have aggregation endpoints return configuration upon request
     so we can build this automatically without frontend config/code.
     */
    const chartRef = useRef(null);
    const containerRef = useRef(null);
    const homeRef = useRef(null);
    const dispatch = useDispatch();
    const [filters, setFilters] = useState([]);
    const [fullScreen, setFullScreen] = useState(false);
    const [groups, setGroups] = useState([]);
    const [displayMenu, setDisplayMenu] = useState(GROUP_BY);
    const [progress, setProgress] = useState(0);
    const [chartState, setChartState] = useState({
                                                     type:    chartType,
                                                     ref:     chartRef,
                                                     options: report.getChartOptions(),
                                                     data:    {
                                                         labels:   [],
                                                         datasets: []
                                                     }
                                                 });

    report.getGroups().clearCallbacks();
    report.getGroups().addCallback(report.title + "-" + chartType +
                                   "-setGroups", setGroups);
    report.getFilters().clearCallbacks();
    report.getFilters().addCallback(report.title + "-" + chartType +
                                    "-setFilters", setFilters);

    useEffect(() => {
        if(!report.getQueryLastUpdatedAt() || report.isLoading()) {
            return;
        }
        report.fetch(setProgress).then(data => {
            if(!report.isErrored()) {
                dispatch(() => {
                    setChartState((prevState, props) => {
                        return {
                            ...prevState, options: report.getChartOptions(),
                            data:                  report.getChartData(chartType)
                        }
                    });
                })
            }
        }).catch(err => {
            console.error("Error fetching report", err);
        });
    }, [report.getQueryLastUpdatedAt()]);

    useEffect(() => {
        setChartState({
                          ...chartState, options: report.getChartOptions(),
                          data:                   report.getChartData(chartType)
                      });
    }, [groups, filters, report.getLastFetch()]);

    if(report.isLoading()) {
        return <Grid container spacing={12} className="graphs">
            <Grid item xs={12}>
                <Loading value={progress} fullscreen={false}/>
            </Grid>
        </Grid>
    }

    const toggleDisplayMenu = (menu) => {
        if(displayMenu === menu) {
            setDisplayMenu(null);
        } else {
            setDisplayMenu(menu);
        }
    }

    const openFullScreen = () => {
        setFullScreen(true)
    }

    const closeFullScreen = () => {
        setFullScreen(false)
    }

    return <>
        <div ref={homeRef} className={"observer-chart"}
             hidden={report.shouldHide()}>
            <div ref={containerRef}
                 className={(fullScreen ? "fullscreen" : "")}>
                <div className={"graphs"}>
                    <div className={"toolbar"}>
                        <h2 className={"report-title"}>{report.title}</h2>
                        <span>
                        <Tooltip title={"More information about this report"}>
                            <InfoOutlined onClick={() => {
                                dispatch(showInformationalPopup(report.title, report.description));
                            }}/>
                        </Tooltip>
                        </span>
                        <span>
                        <Tooltip title={"Filter by " +
                                        report.getFilters().fields.map(f => f.displayName).join(", ")}>
                            <FilterListOutlined onClick={() => {
                                if(report.isEmpty() || report.isErrored()) {
                                    dispatch(showInformationalPopup("Cannot Filter", "This report is empty and cannot be filtered"));
                                    return;
                                }
                                toggleDisplayMenu(FILTER_BY)
                            }}/>
                        </Tooltip>
                    </span>
                        <span>
                        <Tooltip title={"Group by " +
                                        report.getGroups().fields.map(f => f.displayName).join(", ")}>
                            <JoinFullOutlined onClick={() => {
                                if(report.isEmpty() || report.isErrored()) {
                                    dispatch(showInformationalPopup("Cannot Group", "This report is empty and cannot be grouped"));
                                    return;
                                }
                                toggleDisplayMenu(GROUP_BY)
                            }}/>
                        </Tooltip>
                    </span>
                        <span>
                            {fullScreen ? <Tooltip title={"Exit full screen"}>
                                            <CloseFullscreenOutlined
                                                onClick={() => {
                                                    if(report.isEmpty() ||
                                                       report.isErrored()) {
                                                        dispatch(showInformationalPopup("Cannot Fullscreen", "This report is empty. Nothing to show."));
                                                        return;
                                                    }
                                                    closeFullScreen()
                                                }}/>
                                        </Tooltip> :
                             <Tooltip title={"View this report in full screen"}>
                                 <FullscreenOutlined
                                     onClick={() => {
                                         if(report.isEmpty() ||
                                            report.isErrored()) {
                                             dispatch(showPopup("Cannot Fullscreen", "This report is empty. Nothing to show."));
                                             return;
                                         }
                                         openFullScreen();
                                     }}/>
                             </Tooltip>}
                    </span>
                    </div>
                    <Grid container spacing={12}>
                        <Grid item xs={12}>
                            <Grid container spacing={12}>
                                <Grid item xs={12}
                                      hidden={displayMenu !== GROUP_BY}>
                                    {report.isEmpty() || report.isErrored() ||
                                     !report.isGroupable() ? "" :
                                     <GroupBy report={report}/>}
                                </Grid>
                                <Grid item xs={12}
                                      hidden={displayMenu !== FILTER_BY}>
                                    {report.isEmpty() || report.isErrored() ?
                                     "" : <FilterBy report={report}/>}
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid className={"apparent-container"} item xs={12}>
                            {report.isErrored() ?
                             <p className={"error"}>{report.getError()}</p> :
                             ""}
                            {report.isEmpty() ?
                             <p className={"no-data"}>(No data to display)</p> :
                             <Chart {...report.getChartState(chartType, chartRef)}/>}
                        </Grid>
                    </Grid>
                </div>
            </div>
        </div>
    </>

}

export default ObserverChart;
