import React, {useRef} from "react";
import {Chart} from "react-chartjs-2";

import {Chart as ChartJS, registerables} from "chart.js";

import {isEmpty} from "weed-js";
import {IN_PROCESS, READY_TO_FULFILL, RESERVED, SOLD} from "../../../redux/constants.js"

const QUOTE_STATES = [RESERVED, IN_PROCESS, READY_TO_FULFILL, SOLD]


ChartJS.register(...registerables);


const AggregateChart = ({currentUser, salesReport}) => {

    const chartRef = useRef(null);

    if (isEmpty(salesReport)) {
        return "";
    }

    const aggregate = salesReport.reduce((currentTotal, entry) => {
        currentTotal[entry.status].quantity += entry.quantity
        currentTotal[entry.status].value += entry.total
        return currentTotal;
    }, {
        [IN_PROCESS]: {value: 0, quantity: 0},
        [RESERVED]: {value: 0, quantity: 0},
        [READY_TO_FULFILL]: {value: 0, quantity: 0},
        [SOLD]: {value: 0, quantity: 0}
    });


    const options = {
        responsive: true,
        plugins: {
            legend: {
                position: "top"
            },
            title: {
                display: true,
                text: "Sales Totals over the Selected Period by Sales Quote Status"
            },
        },
    };

    const data = {
        labels: QUOTE_STATES,
        datasets: [
            {
                type: "bar",
                label: "Total Units Sold",
                borderColor: "rgb(204, 79, 106)",
                backgroundColor: "rgb(255, 99, 132)",
                borderWidth: 2,
                data: QUOTE_STATES.map(state => aggregate[state].quantity)
            },
            {
                type: "bar",
                label: "Total Value (USD)",
                backgroundColor: "rgb(54, 162, 235)",
                borderColor: "rgb(43, 130, 188)",
                borderWidth: 2,
                data: QUOTE_STATES.map(state => aggregate[state].value)
            },
        ],
    };

    return <div className={"chart"}>
        <div className={"customer-spend"}>
            <Chart ref={chartRef} type="bar" options={options} data={data}/>
        </div>
        <div className={"chart-description"}>
            <h1>Sales Totals</h1>
        </div>
    </div>
}

export default AggregateChart;