import Cookies from "js-cookie";


export const hash = (str) => {
    let hash = 0;
    for (let i=0; i < str.length; i++) {
        hash += str.charCodeAt(i);
    }
    return hash;
}

export const getHashFunction = (modulo, a, b, c) => {
    return (str) => {
        const A = a || Math.random();
        const B = b || Math.random();
        const C = c || Math.random();
        const asInt = hash(str);
        const base = parseInt(A * asInt ** 2. + B * asInt + C);
        return parseInt(Math.max(base, -base)) % modulo
    }
}


export const r = getHashFunction(255, .9, .2, .7);
export const g = getHashFunction(255, .4, .5, .8);
export const b = getHashFunction(255, .3, .6, .5);

export const darker = (color, howMuch) => {
    return Math.max(0, color - howMuch);
}

export const stringToColor = (str) => {
    return [darker(r(str), 20), darker(g(str), 20), darker(b(str), 20)];
}

export const formatDate = (d) => {
    if (isEmpty(d)) {
        return ""
    }
    if (typeof d === "string") {
        return formatDate(new Date(d));
    }
    if (d instanceof Date) {
        const year = d.getFullYear().toString();
        const month = (d.getMonth() + 1).toString().padStart(2, "0")
        const day = d.getDate().toString().padStart(2, "0")
        return `${year}-${month}-${day}`;
    }

    if (!d instanceof Date) {
        console.warn("Expected a date, got", d, typeof d);
    }

    const year = d.year().toString();
    const month = (d.month() + 1).toString().padStart(2, "0")
    const day = d.date().toString().padStart(2, "0")
    return `${year}-${month}-${day}`;
};

export const dateFloor = (d) => {
    if (isEmpty(d)) {
        return null;
    }
    d.setHours(0);
    d.setMinutes(0);
    d.setSeconds(0);
    d.setMilliseconds(0);
    return d;
}

export const formatNumber = (unformatted, returnEmptyString) => {
    if (unformatted === "---" || unformatted === null || unformatted === undefined) {
        if (returnEmptyString) {
            return "";
        }
        return "---";
    }
    if (unformatted instanceof String) {
        unformatted = unformatted.replace(/[^0-9-.]+/g, "")
    }
    try {
        let formatted = parseFloat(unformatted)
        if (isNaN(formatted)) {
            return unformatted;
        }
        return formatted.toFixed(2);
    } catch (error) {
        console.error(error);
    }
    return unformatted;
};

export const isEmpty = (value) => {
    if (value === null || value === undefined) {
        return true;
    } else if (value instanceof Set) {
        return value.size === 0;
    } else if (typeof value === "string") {
        return value.length === 0;
    } else if (typeof value === "number") {
        return false;
    } else if (value instanceof Date) {
        return false;
    } else if (typeof value.length === "number") {  // Empty list?
        return value.length === 0;
    } else if (!isNaN(value.size)) {  // Empty set?
        return value.size === 0;
    }
    return Object.keys(value).length === 0;  // Empty object.
};

export const getCookieParams = () => {
    const params = {
        domain: process.env.REACT_APP_SSO_COOKIE_DOMAIN,
        secure: process.env.REACT_APP_SSO_COOKIE_SECURE === "True",
        sameSite: process.env.REACT_APP_SSO_COOKIE_SAMESITE,
        httpOnly: false,
        expires: 365,
    };
    return params;
}

export const setCookie = (name, value) => {
    return Cookies.set(
        name,
        value,
        getCookieParams()
    )
}

export const getCookie = (name) => {
    return Cookies.get(
        name,
        getCookieParams()
    )
}

export const removeCookie = (name) => {
    return Cookies.remove(name, getCookieParams());
}

export function uniqueById(lst) {
    const unique = {};
    for (let i = 0; i < lst.length; i++) {
        const e = lst[i];
        unique[e.id] = e;
    }
    return Object.values(unique);
}

export function uniqueByAttributes(arr, keyProps) {
    return Object.values(arr.reduce((uniqueMap, entry) => {
        const key = keyProps.map((k) => entry[k]).join("|");
        if (!(key in uniqueMap)) {
            uniqueMap[key] = entry;
        } else {
            uniqueMap[key] = {
                ...entry,
                "Quantity": parseFloat(uniqueMap[key]["Quantity"]) + parseFloat(entry["Quantity"]),
            };
        }
        return uniqueMap;
    }, {}));
}

export function uniqueByAttribute(lst, attr) {
    const unique = {};
    for (let i = 0; i < lst.length; i++) {
        const e = lst[i];
        unique[e[attr]] = e;
    }
    return Object.values(unique);
}

export function uniqueByValue(lst) {
    return Array.from(new Set(lst));
}

export const randomChoice = (lst) => {
    /**
     * @param {list} list from which to select a random item
     * Returns a list item
     */
    return lst[Math.ceil(Math.random() * lst.length) - 1];
};

export const currencyFormatter = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
});


export const wasCancelled = (error) => {
    return error.message === "canceled";
}

export const round = (value, decimals) => {
    const multiplier = Math.pow(10, decimals || 0);
    return Math.round(value * multiplier) / multiplier;
}

export const isLastIndexOf = (index, array) => index == array.length - 1;

export const toBatches = (array, batchSize, firstBatchSize=null) => {
    const batches = firstBatchSize ? [array.slice(0, firstBatchSize)] : [];
    const remainder = firstBatchSize ? array.slice(firstBatchSize) : array;
    
    if (remainder.length > 0) {
      const chunkCount =  Math.ceil(remainder.length / batchSize);
      for (let i = 0; i < chunkCount; i++) {
        batches.push(remainder.slice(i*batchSize, (i+1) * batchSize));
      }
    }
    return batches;
  };
