import React, {useEffect, useState} from "react";

import {Chart as ChartJS, registerables} from "chart.js";
import {Client, isEmpty} from "weed-js";
import {Report} from "../observers/classes/Report.js";
import {Calculation, Extract, Sum} from "../observers/classes/Calculation.js";
import {Field} from "../observers/classes/Field.js";
import {RelatedModel} from "../observers/classes/RelatedModel.js";
import {Rollups} from "../observers/classes/TimeRangeQuery.js";

import ObserverChart from "../observers/ObserverChart.js";
import {useDispatch} from "react-redux";
import {fetchAlpineIQAccounts} from "../../../redux/models/integrations/Action";

ChartJS.register(...registerables);

const client = new Client();

const REDEMPTION = "redemption";
const SALE = "sale";
const ADJUSTMENT = "adjustment";
const EXPIRATION = "expiration";

const timeline_event_type = new RelatedModel(
    client.alpineiq.dal.v1(),
    "/timeline-event-types/",
    "type_id",
    [
        new Field("name", {
            displayName: "Redemption Type",
            filterable:  true,
            groupable:   true
        }),
    ]);


const calculation = new Calculation(
    "Redemption Rate Over Time",
    new Extract((item) => {
        const event_type = timeline_event_type.instancesById[item.type_id];
        if (!event_type) {
            return 0;
        }
        if (event_type && event_type.name === REDEMPTION) {
            return parseFloat(item.value);
        } else if (event_type.name === SALE) {
            return parseFloat(item.sale_amount);
        }
        console.warn("Unknown event type", event_type);
        return 0;
    }),
    Sum
);


const alpineIQRedemptionReport = new Report(
    client.alpineiq.api.v1(),
    "/kpis/timeline/redemption/",
    "Redemption Rate Over Time",
    [
        new Field("timestamp", {
            displayName: "Redemption Date", cast: (d) => {
                const date = new Date(d);
                const year = date.getFullYear();
                const month = String(date.getMonth() + 1).padStart(2, '0');
                const day = String(date.getDate()).padStart(2, '0');
                return `${year}-${month}-${day}`;
            }, timestamp: true, observations: true
        }),
        new Field(
        "value", {
            displayName: "Redemption Value",
            cast:        (o) => {
                return o;
            },
            filterable:  false,
            groupable:   false
        }),
        new Field("sale_amount", {
            displayName: "Sale Amount", cast: (o) => {
                return o;
            },
            filterable:  false,
            groupable:   false
        })
    ], [
        timeline_event_type
    ],
    calculation,
    "This report gives the percent of redemptions over time. It answers what percent of the redemptions in this time window occurred on a given date.",
)


const Redemption = ({organizationId, startDate, endDate}) => {

    const dispatch = useDispatch();
    const [selectedAlpineIQAccounts, setSelectedAlpineIQAccounts] = useState([]);
    const [rollup, setRollup] = useState(Rollups.DAILY);
    const [loadingAlpineIQAccounts, setLoadingAlpineIQAccounts] = useState(false);

    useEffect(() => {
        if(loadingAlpineIQAccounts) {
            return;
        }
        dispatch(fetchAlpineIQAccounts({organization_id: organizationId})).then(fetchedAccounts => {
            setSelectedAlpineIQAccounts(fetchedAccounts)
            alpineIQRedemptionReport.query(startDate, endDate, null, {"alpineiq_account_id__in": fetchedAccounts.map(a => a.id).join(",")});
        });
    }, [organizationId]);


    useEffect(() => {
        if(loadingAlpineIQAccounts || isEmpty(selectedAlpineIQAccounts) ||
           alpineIQRedemptionReport.isLoading()) {
            return;
        }
        // TODO: When the organization changes we need to clear the previous
        // results!
        alpineIQRedemptionReport.query(startDate, endDate, null, {
            "alpineiq_account_id__in": selectedAlpineIQAccounts.map(a => a.id).join(",")
        });
    }, [
                  alpineIQRedemptionReport.isLoading(), organizationId,
                  startDate, endDate, rollup,
                  selectedAlpineIQAccounts.map(a => a.id).join(",")
              ]);


    console.log("Report", alpineIQRedemptionReport.getTimeSeries());

    console.log("---------------------Will render <ObserverChart>---------------------");
    return <div>
        <ObserverChart
            report={alpineIQRedemptionReport}
            chartType={"bar"}/>
    </div>
}

export default Redemption;
