import {
    SET_STATES
} from "../../constants.js";
import { groupByAttribute } from "../../../utils.js";

const defaultState = {
    "states": []
};

export const StateReducer = (state = defaultState, action) => {
    switch (action.type) {
        case SET_STATES:
            let fetchedStates = groupByAttribute(action.states, "id");
            return {
                ...state,
                states: Object.values(fetchedStates)
            };
        default:
            return state;
    }
};


export default StateReducer;
