import React, { useState } from "react";
import ReactTable from "react-table-v6";
import { getTotalForField, isNullOrUndefinedOrEmpty, groupListByField, numberFormatter, currencyFormatter } from "../../../utils.js";
import {isEmpty} from "weed-js";


const SalesByRepAndCategory = ({salesReport}) => {

    const [sortOptions, setSortOptions] = useState([{ id: "total", desc: true }])
    const title = <h3>Units Sold by Sales Rep and Category</h3>;

    const bySalesRepAndCategoryHeaders = [
        {
            Header: "Sales Rep",
            accessor: "sales_rep",
        },
        {
            Header: "Category",
            accessor: "category",
        },
        {
            Header: "Total Quantity",
            accessor: "quantity",

        },
        {
            Header: "Total Value",
            accessor: "total",
            id: "total"
        },
    ];


    if (isEmpty(salesReport)) {
        return "";
    }

    const salesBySalesRepAndCategory = (report) => {
        if (isNullOrUndefinedOrEmpty(report)) {
            return [];
        }
        let aggregate = [];
        for (let [salesRep, entriesBySalesRep] of Object.entries(groupListByField(report, "sales_rep"))) {
            for (let [category, entriesByCategory] of Object.entries(groupListByField(entriesBySalesRep, "category"))) {
                aggregate.push({
                    sales_rep: salesRep,
                    category: category,
                    quantity: numberFormatter.format(getTotalForField(entriesByCategory, "quantity")),
                    total: currencyFormatter.format(getTotalForField(entriesByCategory, "total")),
                });
            }
        }
        return aggregate;
    };

    const bySalesRepAndCategory = salesBySalesRepAndCategory(salesReport) || [];

    return <div>
        {title}
        <ReactTable
            sorted={sortOptions}
            onSortedChange={(newSorted, column, shiftKey) => {
                setSortOptions(newSorted);
            }}
            showPaginationBottom={false}
            getProps={() => { return { id: "sales-report-table" } }}
            columns={bySalesRepAndCategoryHeaders}
            pageSize={bySalesRepAndCategory.length}
            className="-striped -highlight mb-3"
            data={bySalesRepAndCategory}
        />
    </div>
}

export default SalesByRepAndCategory;
