import React from "react";
import {useEffect} from "react";

import {registerScrollEvent, onScroll} from "../../redux/animations/Action.js";
import {useDispatch, useSelector} from "react-redux";
import {userIsLoggedIn} from "../../utils.js";
import {Container, Grid, Tab, Tabs} from "@mui/material";
import {Advertiser} from "./Advertiser.js"
import {Dashboard} from "./Dashboard.js"


const youreOnTheWaitlist = () => {
    return <Container className={"workspace"}>
        <Grid container spacing={2}>
            <div className="card card-body">
                <h1 className="text-center">Congratulations! You're on the waitlist.</h1>
                <p className="text-center">Thanks for signing up. We'll send an email soon so you can start using the public beta. We can't wait to show you everything we have to offer.</p>
            </div>
        </Grid>
    </Container>
}

const Waitlist = () => {
    const loggedInUser = useSelector(state => state.userReducer.loggedInUser.user);

    userIsLoggedIn(loggedInUser);

    const dispatch = useDispatch();

    useEffect(() => {
        const script = document.createElement("script");
        script.src = "https://js.stripe.com/v3/pricing-table.js";
        script.async = true;

        document.body.appendChild(script);
        dispatch(registerScrollEvent());

        window.addEventListener("scroll", onScroll);

        return () => {
            document.body.removeChild(script);
            window.removeEventListener("scroll", onScroll);
        }
    }, [true]);

    return (<>
        {userIsLoggedIn(loggedInUser)
            ? youreOnTheWaitlist()
            : <Advertiser/>
        }
    </>);
}

export default Waitlist;
