import {isEmpty} from "weed-js";

export class Group {

    constructor(fields) {
        this.fields = fields.filter(f => f.groupable);
        this.groupedFields = [];
        this.callbacks = {}
        // if (isEmpty(this.fields)) {
        //     throw new Error("No groupable fields were found in your report.
        // You must pass the option {groupable: true} for at least one of
        // them.") }
    }

    call(group) {
        Object.values(this.callbacks).forEach(callback => callback(group));
    }

    addCallback(name, callback) {
        this.callbacks[name] = callback;
    }

    clearCallbacks() {
        this.callbacks = {};
    }

    add(field) {
        this.groupedFields.push(field)
    }

    remove(field) {
        this.groupedFields = this.groupedFields.filter(f => f !== field);
    }

    key(point) {
        return this.groupedFields.map(f => f.run(point)).join("/ ")
    }

    chooseByDisplayNames(displayNames) {
        if(isEmpty(displayNames)) {
            this.groupedFields = []
        } else {
            const chosen = new Set(displayNames);
            this.groupedFields =
                this.fields.filter(f => chosen.has(f.displayName));
        }
    }
}
