
export const SUPERADMIN_ROLE = 'superadmin';
export const ADMIN_ROLE = 'admin';
export const MEMBER_ROLE = 'member';
export const INVITED_ROLE = 'invited';

export const ROUTES_FORGOT_PASSWORD = "/forgot-password";
export const ROUTES_ADMIN = "/admin/";
export const ROUTES_ORGANIZATIONS_LIST = "/organizations/list";

export const MANAGER_GROUP_ID = 1;
export const SALES_REPRESENTATIVE_GROUP_ID = 2;
export const FULFILLMENT_ASSOCIATE_GROUP_ID = 3;
export const PRODUCT_MASTER_EDITOR_GROUP_ID = 4;
export const FINANCE_GROUP_ID = 5;

export const LOGO = "logo";
export const PROFILE = "profile";
export const BANK = "accounts";
export const ROLES = "roles";
export const ROUTES_ORGANIZATIONS_DETAIL = "/organizations/:id/detail";
export const ROUTES_ORGANIZATIONS_DETAIL_LOGO = `/organizations/:id/detail/${LOGO}`;
export const ROUTES_ORGANIZATIONS_DETAIL_USERS_AND_ROLES = `/organizations/:id/detail/${ROLES}`;
export const ROUTES_ORGANIZATIONS_DETAIL_BANK_ACCOUNTS = `/organizations/:id/detail/${BANK}`;





export const BACKFILLS = "backfills";
export const AUTHENTICATION = "authentication";
export const ROUTES_INTEGRATIONS = "/integrations";
export const ROUTES_INTEGRATIONS_METRC_SETUP = "/integrations/metrc/setup";
export const ROUTES_INTEGRATIONS_METRC_LIST = "/integrations/metrc/list";
export const ROUTES_INTEGRATIONS_METRC_DETAIL = "/integrations/metrc/:id/detail";
export const ROUTES_INTEGRATIONS_METRC_DETAIL_BACKFILLS = `/integrations/metrc/:id/detail/${BACKFILLS}`;
export const ROUTES_INTEGRATIONS_METRC_DETAIL_AUTHENTICATION = `/integrations/metrc/:id/detail/${AUTHENTICATION}`;
export const ROUTES_INTEGRATIONS_ALPINEIQ_SETUP = "/integrations/alpineiq/setup";
export const ROUTES_INTEGRATIONS_ALPINEIQ_LIST = "/integrations/alpineiq/list";
export const ROUTES_INTEGRATIONS_ALPINEIQ_DETAIL = "/integrations/alpineiq/:id/detail";
export const ROUTES_INTEGRATIONS_DATABASE_SETUP = "/integrations/database/setup";
export const ROUTES_INTEGRATIONS_DATABASE_LIST = "/integrations/database/list";
export const ROUTES_INTEGRATIONS_DATABASE_DETAIL = "/integrations/database/:id/detail";

export const ROUTES_INTEGRATIONS_LEAFLOGIX_SETUP = "/integrations/leaflogix/setup";
export const ROUTES_INTEGRATIONS_LEAFLOGIX_LIST = "/integrations/leaflogix/list";
export const ROUTES_INTEGRATIONS_LEAFLOGIX_DETAIL = "/integrations/leaflogix/:id/detail/";
export const ROUTES_INTEGRATIONS_LEAFLOGIX_DETAIL_AUTHENTICATION = `/integrations/leaflogix/:id/detail/${AUTHENTICATION}`;

export const ROUTES_INTEGRATIONS_IDSCAN_SETUP = "/integrations/idscan/setup";
export const ROUTES_INTEGRATIONS_IDSCAN_LIST = "/integrations/idscan/list";
export const ROUTES_INTEGRATIONS_IDSCAN_DETAIL = "/integrations/idscan/:id/detail/";
export const ROUTES_INTEGRATIONS_IDSCAN_DETAIL_AUTHENTICATION = `/integrations/idscan/:id/detail/${AUTHENTICATION}`;

export const ROUTES_MY_ACCOUNT = "/my-account/*";
export const ROUTES_RESET_PASSWORD = "/reset-password";
export const ROUTES_HOME = "/";

export const PENDING = "PENDING";
export const NOT_STARTED = "NOT_STARTED";
export const COMPLETE = "COMPLETE";

export const NO_LOGO = "No Logo"
export const CREATE = "create";
export const DESTROY = "destroy";

export const ALPINEIQ_DAL_URL = "/alpine-iqaccounts/";

export const FEATURE_ANALYTICS_FRONTEND = "analytics_frontend";