import React from 'react';
import ForgotPasswordForm from "../components/forms/ForgotPassword.js";
import "./styles/ForgotPassword.css";
import {Container} from "@mui/material";


const ForgotPassword = () => {
    return <Container className="workspace">
        <h1 id="forgot-password-header">Forgot Password</h1>
        <ForgotPasswordForm />
    </Container>
};

export default ForgotPassword;
