export class Reference {

    constructor(obj, address, parent) {
        this.obj = obj;
        this.address = address;
        this.parent = parent;
    }

    value() {
        return this.obj[this.address];
    }

    set(value) {
        this.obj[this.address] = value;
        return this;
    }
}

