import React from "react";
import { TextField } from "@material-ui/core";


const EmailInput = ({name, label, errors, formState, setFormState, setErrors, ...props}) => {
    console.log("Errors in email input", errors);
    const error = !!errors[name];
    const helperText = error ? errors[name] : "";
    return (
        <TextField
            {...props}
            fullWidth={true}
            margin="dense"
            variant="filled"
            label={label}
            id={name}
            value={formState[name]}
            onChange={(e) => {
                setFormState({
                    ...formState, 
                    username: e.target.value,
                    email: e.target.value,
                });
            }}
            error={error}
            helperText={helperText}
        />
    );
}


export default EmailInput;