import React, { useState, useEffect} from "react";
import {Container, Grid} from "@mui/material";
import AggregateChart from "../../../../components/reports/home/AggregateChart";
import Leaderboard from "../../../../components/reports/home/Leaderboard";
import SalesByRepAndCustomer from "../../../../components/reports/home/SalesByRepAndCustomer";
import SalesByRepAndCategory from "../../../../components/reports/home/SalesByRepAndCategory";
import SalesByRepCustomerAndProduct from "../../../../components/reports/home/SalesByRepCustomerAndProduct";
import LatestCustomerPurchases from "../../../../components/reports/home/LatestCustomerPurchases";
import {useDispatch, useSelector} from "react-redux";
import dayjs from "dayjs";
import {accountIsStillBeingSetUp, formatDate} from "../../../../utils";
import {fetchLatestPurchasesReport, fetchSalesReport} from "../../../../redux/Actions";
import {isEmpty} from "weed-js";

const Wholesale = () => {
    const organizations = useSelector(state => state.userReducer.loggedInUser.organizations);
    const currentUser = useSelector(state => state.userReducer.loggedInUser.user);
    const organizationUsers = useSelector(state => state.userReducer.loggedInUser.organizationUsers);
    const organizationOptions = organizations.map((org) => {
        return {"label": org.name, "value": org.id};
    })
    const [salesReport, setSalesReport] = useState([]);
    const [purchaseReport, setPurchaseReport] = useState([]);
    const selectedOrganization = useSelector(state => state.organizationReducer.selectedOrganization);
    const [loading, setLoading] = useState(false);

    const today = new Date();
    const firstOfCurrentMonth = new Date(today.getFullYear(), today.getMonth(), 1);
    const [endDate, setEndDate] = useState(dayjs(today));
    const [startDate, setStartDate] = useState(dayjs(firstOfCurrentMonth));
    const incompleteAccounts = useSelector(state => state.userReducer.loggedInUser.accountStatuses || []).filter((status) => {
        return accountIsStillBeingSetUp(status);
    });


    const dispatch = useDispatch();

    useEffect(() => {
        if (salesReport && salesReport.length > 0 || !selectedOrganization) {
            return;
        }
        const abortController = new AbortController();
        const organizationIds = [selectedOrganization.id];

        const params = {
            start_date: formatDate(startDate),
            end_date: formatDate(endDate),
            organization_ids: (organizationIds || []).join(","),
        };

        setLoading(true);
        Promise.all([
            dispatch(fetchSalesReport(params, abortController)),
            dispatch(fetchLatestPurchasesReport(abortController)),
        ]).then(([fetchedSalesReport, fetchedPurchaseReport]) => {
            setSalesReport(fetchedSalesReport);
            setPurchaseReport(fetchedPurchaseReport);
            setLoading(false);
        }).catch((error) => {
            console.error(error);
            setLoading(false);
        });

        return () => {
            abortController.abort();
        }
    }, [selectedOrganization, startDate, endDate]);

    if (isEmpty(salesReport)) {
        return (
            <Grid container spacing={12}>
                <Grid item xs={12}>
                    You must set up a wholesale account to see this dashboard. Contact us to get started.
                </Grid>
            </Grid>
        )
    }

    return (
        <Grid container spacing={12}>
            <Grid item xs={12}>
                <AggregateChart salesReport={salesReport} currentUser={currentUser}/>
            </Grid>
            <Grid item xs={12}>
                <Leaderboard salesReport={salesReport}/>
            </Grid>
            <Grid item xs={12}>
                <SalesByRepAndCustomer salesReport={salesReport}/>
            </Grid>
            <Grid item xs={12}>
                <SalesByRepAndCategory salesReport={salesReport}/>
            </Grid>
            <Grid item xs={12}>
                <SalesByRepCustomerAndProduct salesReport={salesReport}/>
            </Grid>
            <Grid item xs={12}>
                <LatestCustomerPurchases purchaseReport={purchaseReport}/>
            </Grid>
        </Grid>
    )
}

export default Wholesale;