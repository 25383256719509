import {
    USER_SCROLLED, SCROLL_EVENT_REGISTERED
} from "../constants.js";

const defaultState = {
    "window": {
        upperFold: null,
        lowerFold: null
    },
    callback: null
};

const AnimationReducer = (state = defaultState, action) => {
    switch (action.type) {
        case USER_SCROLLED:
            return {
                ...state,
                "window": {
                    ...state.window,
                    upperFold: action.upperFold,
                    lowerFold: action.lowerFold
                }
            };
        case SCROLL_EVENT_REGISTERED:
            if (state.callback === null) {
                window.onscroll = action.callback;
                state.callback = action.callback
                return {
                    ...state,
                    callback: action.callback
                }
            }
            return state;
        default:
            return state;
    }
};


export default AnimationReducer;
