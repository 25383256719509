import * as React from 'react';
import {Alert} from "@mui/material";
import Button from '@mui/material/Button/Button.js';
import Dialog from '@mui/material/Dialog/Dialog.js';
import DialogActions from '@mui/material/DialogActions/DialogActions.js';
import DialogContent from '@mui/material/DialogContent/DialogContent.js';
import DialogTitle from '@mui/material/DialogTitle/DialogTitle.js';

export default function ErrorPopup({title, message, onClose}) {

    return (
        <div>
            <Dialog
                open={message && message !== ""}
                onClose={onClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">
                    {title}
                </DialogTitle>
                <DialogContent>
                    <Alert severity="error">{message}</Alert>
                </DialogContent>
                <DialogActions>
                    <Button onClick={onClose} autoFocus>
                        Ok
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}