import React, { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import BankAccount from "../BankAccount.js";
import Dropdown from "../../elements/Dropdown.js"
import { fetchBankAccounts, setBankAccounts } from "../../../redux/user/Action.js";
import { isNullOrUndefinedOrEmpty } from "../../../utils.js";
import { memberIsAdmin } from "../../../utils/Roles.js";
import { fetchStates, setStates } from "../../../redux/models/states/Action.js";
import SubmitButton from "../../elements/SubmitButton.js";
import Loading from "../../elements/Loading.js";

const BankAccounts = ({ organization }) => {
    const dispatch = useDispatch();
    const [errors, setErrors] = useState({});
    const [loading, setLoading] = useState(false);

    const loggedInUser = useSelector(state => state.userReducer.loggedInUser.user);
    const organizations = useSelector(state => state.userReducer.loggedInUser.organizations);
    const organizationUsers = useSelector(state => state.userReducer.loggedInUser.organizationUsers);
    const selectedOrganization = useSelector(state => state.organizationReducer.selectedOrganization);
    const bankAccounts = isNullOrUndefinedOrEmpty(organizations) ? null : organizations.find(o => o.id === organization.id).bankAccounts || null;
    const states = useSelector(state => state.stateReducer.states);
    const userIsAdmin = memberIsAdmin(loggedInUser, organizationUsers, selectedOrganization); 

    const [selectedBankAccount, setSelectedBankAccount] = useState(isNullOrUndefinedOrEmpty(bankAccounts) ? null : bankAccounts[0]);


    useEffect(() => {
        const abortController = new AbortController();

        if ((bankAccounts === null || states === null) && !loading) {
            setLoading(true);
            Promise.all([
                dispatch(fetchBankAccounts(organization.id, abortController)),
                dispatch(fetchStates(abortController))
            ]).then((result) => {
                const [fetchedBankAccounts, fetchedStates] = result;
                dispatch(setBankAccounts(organization, fetchedBankAccounts || []));
                dispatch(setStates(fetchedStates || []));
                setLoading(false)
            }).catch((error) => {
                setLoading(false);
                console.error(error);
            });
        }

        return () => {
            abortController.abort()
        }
    }, []);

    if (loading) {
        return <Loading />
    }
    if (bankAccounts === null || states === null) {
        return "Failed to find states or bank accounts"
    }

    const selectOptions = bankAccounts.sort((a, b) => {
        return a.label.toLowerCase() > b.label.toLowerCase();
    }).map((bankAccount) => {
        return { "label": bankAccount.label, "value": bankAccount.id };
    });

    return (
        <form>
            <h2 id="organization-administration">Bank Accounts</h2>
            <p>You can add bank accounts here to appear on sales quotes sent to your clients through the wholesale platform.</p>
            <div style={{ display: "flex" }}>
                {isNullOrUndefinedOrEmpty(bankAccounts) ? "" : <>
                    <Dropdown
                        id={"select-bank-accounts"}
                        className={isNullOrUndefinedOrEmpty(bankAccounts) ? "hidden" : ""}
                        label={"Select a bank account to edit"}
                        options={selectOptions}
                        onChange={(bankAccountId) => {
                            const account = bankAccounts.find(acct => acct.id === parseInt(bankAccountId)) || {};
                            setSelectedBankAccount(account);
                        }}
                        errors={errors}
                    />
                    {userIsAdmin && <>
                        <div>&nbsp; or &nbsp;</div>
                        <SubmitButton
                            id="add-new-bank-account-button"
                            onClick={(e) => {
                                e.preventDefault();
                                setSelectedBankAccount({});
                            }}
                        >
                            Create New
                        </SubmitButton>
                    </>}
                </>}
            </div>
            <br />
            {!userIsAdmin && isNullOrUndefinedOrEmpty(bankAccounts) && 
                <p>No bank accounts to display. Your organization admin needs to create one.</p>
            }
            {(selectedBankAccount || (userIsAdmin && isNullOrUndefinedOrEmpty(bankAccounts))) && <BankAccount
                bankAccount={selectedBankAccount || {}}
                organization={organization}
                setSelectedBankAccount={setSelectedBankAccount}
                userIsAdmin={userIsAdmin} />}
        </form>
    )
}


export default BankAccounts;