import {isEmpty} from "weed-js";

export class Field {
    constructor(name, options) {
        this.name = name;
        this.model = null;
        this.options = options || {};
        this.displayName = this.options.displayName || name;
        this.filterable = this.options.filterable || false;
        this.groupable = this.options.groupable || false;
        this.timestamp = this.options.timestamp || false;
        this.observations = this.options.observations || false;
        this.cardinality = 0;

        if(isEmpty(this.name)) {
            throw new Error("Every field must have a name, which should be the accessor for the field relative to the model being observed as if you were to pass it to the Django .values() function. (joins are supported).");
        }
        if(isEmpty(this.displayName)) {
            throw new Error("Every field must have a display name, which must be unique for a given report.")
        }
    }

    clone() {
        return new Field(this.name, this.options);
    }

    cast(value) {
        if(this.options.cast) {
            return this.options.cast(value);
        }
        return value;
    }

    run(item) {
        if(!isEmpty(this.model)) {
            const pk = item[this.model.foreignKeyName];
            const model = this.model.instancesById[pk];
            if(isEmpty(model)) {  // Edge case. `item` is already an instance of model.
                return this.cast(item[this.name]);
            }
            if(!model.hasOwnProperty(this.name)) {
                console.log("Model has no ", this.name);
                throw new Error(`${this.name} (${this.displayName}) not found on the model ${JSON.stringify(model)}`);
            }
            return this.cast(model[this.name]);
        }
        if(!item.hasOwnProperty(this.name)) {
            throw new Error(`${this.name} (${this.displayName}) not found on the point ${JSON.stringify(item)}`);
        }
        return this.cast(item[this.name]);
    }

    valuesOfRelatedField() {
        const instances = Object.values(this.model.instancesById) || [];
        const values = Array.from(instances.reduce((agg, instance) => {
            const value = this.run(instance); // This might have to be the fk
                                              // name?
            if(value !== null && value !== undefined) {
                agg.add(value)
            }
            return agg;
        }, new Set()));
        values.sort()
        return values;
    }

    valuesOf(timeseries) {
        if(this.model !== null) { // Foreign Key
            return this.valuesOfRelatedField();
        }

        const points = timeseries.dataPoints || [];
        const values = Array.from(points.reduce((agg, point) => {
            const value = this.run(point);
            if(value !== null && value !== undefined) {
                agg.add(value);
            }
            return agg
        }, new Set()));

        values.sort()
        return values;
    }
}

