import React, { useState } from 'react';
import { useDispatch } from "react-redux";
import { enqueueSnackbar, generatePasswordToken } from "../../redux/Actions.js";
import SubmitButton from "../elements/SubmitButton.js";
import EmailInput from "../elements/EmailInput.js";


const ForgotPasswordForm = () => {
    const [formState, setFormState] = useState({
        email: "",
    });
    const [errors, setErrors] = useState({
        email: "",
    })
    const [submitSuccess, setSubmitSuccess] = useState(false);

    const dispatch = useDispatch();

    return (
        <div>
            {!submitSuccess
                ? <form className="form">
                    <div>
                        Enter your email address below. If you are registered in our system you will receive an email with a link to reset your password.
                    </div>
                    <div className="form-row">
                        <EmailInput
                            name="email"
                            label="Email Address"
                            formState={formState}
                            errors={errors}
                            setFormState={setFormState}
                            setErrors={setErrors} />
                    </div>
                    <div className="form-row submit">
                        <SubmitButton onSubmit={(click) => {
                            click.preventDefault();
                            dispatch(generatePasswordToken(formState.email)).then(() => {
                                setSubmitSuccess(true);
                            }).catch((errs) => {
                                dispatch(enqueueSnackbar({
                                    message: "Email not found. Try again.",
                                    options: {
                                        variant: "error",
                                    },
                                }));
                                setErrors({ ...errors, ...errs });
                            });
                        }}>Send Email</SubmitButton>
                    </div>
                </form>
                : <div>Password reset link sent! Check your email.</div>
            }
        </div>
    )
}

export default ForgotPasswordForm;
