import React from 'react';
import {useState} from "react";
import SubmitButton from "../elements/SubmitButton.js";
import TextInput from "../elements/TextInput.js";
import {useDispatch, useSelector} from "react-redux";
import ErrorPopup from "../notifications/ErrorPopup.js";
import {updateLeaflogixAccount, createLeaflogixAccount, deleteLeaflogixAccount} from "../../redux/models/integrations/Action.js";
import {isEmpty} from "weed-js";
import Dropdown from "../elements/Dropdown";
import {selectOrganization} from "../../redux/models/organizations/Action";
import {isNullOrUndefinedOrEmpty} from "../../utils";
import {ADMIN_ROLE, SUPERADMIN_ROLE} from "../../utils/Constants";


const LeaflogixAccount = ({
                              create = false, enableEdit = false, enableDelete = false, onCreate = (newAccount) => {
    }, onUpdate = (updatedAccount) => {
    }, onDelete = (accountId) => {
    }, ...leaflogixAccount
                          }) => {
    const dispatch = useDispatch();
    const organizationUsers = useSelector(state => state.userReducer.loggedInUser.organizationUsers);
    const organizations = useSelector(state => state.userReducer.loggedInUser.organizations);
    const selectedOrganization = useSelector(state => state.organizationReducer.selectedOrganization);
    const accountStatuses = useSelector(state => state.userReducer.loggedInUser.accountStatuses);
    const [showPasswords, setShowPasswords] = useState(false);
    const accountStatus = accountStatuses.find(accountStatus => {
        if (isNullOrUndefinedOrEmpty(selectedOrganization)) {
            return !isNullOrUndefinedOrEmpty(organizationUsers.find(ou => ou.organization_id === accountStatus.organization_id && (ou.role === ADMIN_ROLE || ou.role === SUPERADMIN_ROLE)));
        }
        return accountStatus.organization_id === selectedOrganization.id
    });

    const getIntegrationOrganization = () => {
        const integrationOrganization = organizations.find(org => org.id === selectedOrganization.id);
        if (integrationOrganization) {
            return integrationOrganization.id;
        }
        return null;
    };

    const getAdministeredOrgOptions = () => {
        return organizations.filter(org => {
            const orgUser = organizationUsers.find(ou => ou.organization_id === org.id);
            console.log("Searching for org id", org.id, "found orgUser", orgUser, "organizations", organizations, "organizationUsers", organizationUsers);
            if (isEmpty(orgUser)) {
                return false;
            }
            return [SUPERADMIN_ROLE, ADMIN_ROLE].includes(orgUser.role);
        }).map((organization) => {
            return {
                label: organization.name,
                value: organization.id
            }
        });
    };
    const _selectOrganization = (organizationId) => {
        const organization = organizations.find((org) => parseInt(org.id) === parseInt(organizationId));
        dispatch(selectOrganization(organization));
    }

    const defaultErrors = {
        _message: "", display_name: "", token: "", frontend_username: "", frontend_password: ""
    }
    const defaultFormState = {}

    const [errors, setErrors] = useState({...defaultErrors});
    const [formState, setFormState] = useState({...defaultFormState});
    const disabled = !create && !enableEdit;

    return <>
        {errors._message !== "" ? <ErrorPopup onClose={() => {
            setErrors({...errors, _message: ""});
        }} message={errors._message}/> : ""}

        {leaflogixAccount && leaflogixAccount.display_name ? <h2>{leaflogixAccount.display_name}</h2> : ""}

        <div className="form-row">
            <TextInput
                disabled={disabled}
                display_name={leaflogixAccount.display_name}
                errors={errors}
                formState={formState}
                label="Display Name"
                name="display_name"
                setFormState={setFormState}
            />
        </div>
        <div className="form-row">
            <TextInput
                disabled={disabled}
                errors={errors}
                formState={formState}
                label="API Token -or- Auth Header (starts with 'Basic')"
                name="token"
                setFormState={setFormState}
                token={leaflogixAccount.token}
                type={showPasswords ? "text" : "password"}
            />
        </div>
        <div className="form-row">
            <TextInput
                disabled={disabled}
                errors={errors}
                formState={formState}
                frontend_username={leaflogixAccount.frontend_username}
                label="Frontend Username (optional)"
                name="frontend_username"
                setFormState={setFormState}
            />
            <TextInput
                disabled={disabled}
                errors={errors}
                formState={formState}
                frontend_password={leaflogixAccount.frontend_password}
                label="Frontend Password (optional)"
                name="frontend_password"
                setFormState={setFormState}
            />
        </div>
        <div className="form-row">
            <Dropdown
                id={"organization_id"}
                label={"Which organization is this for?"}
                onChange={_selectOrganization}
                errors={errors}
                value={getIntegrationOrganization()}
                options={getAdministeredOrgOptions()}
            />
        </div>
        <div className="form-row submit">
            {enableDelete ? <SubmitButton
                variant={"outlined"}
                color={"error"}
                disabled={(disabled || (isEmpty(formState) && isEmpty(leaflogixAccount)))}
                onSubmit={(event) => {
                    event.preventDefault();
                    dispatch(deleteLeaflogixAccount(leaflogixAccount.id)).then((response) => {
                        onDelete(leaflogixAccount.id);
                        setFormState({...defaultFormState})
                        navigate(ROUTES_INTEGRATIONS_LEAFLOGIX_LIST);
                    }).catch((errs) => {
                        setErrors({...errors, ...errs});
                    });
                }}>Delete</SubmitButton> : ""}
            <SubmitButton
                disabled={disabled}
                onClick={(event) => {
                    event.preventDefault();
                    setErrors({...defaultErrors});
                    const action = create ? createLeaflogixAccount : updateLeaflogixAccount;
                    return dispatch(action({
                        ...leaflogixAccount,
                        ...formState,
                        organization_id: selectedOrganization.id
                    })).then((response) => {
                        onCreate(response);
                        setFormState({...response});
                    }).catch((errs) => {
                        setErrors({...errors, ...errs});
                    });

                }}>Save</SubmitButton>
        </div>
    </>
}

export default LeaflogixAccount;
