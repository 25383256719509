import React from 'react'

const Manufacturing = () => {
    return (
        <div>
            Manufacturing
        </div>
    )
}

export default Manufacturing
