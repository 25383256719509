import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from "react-redux";
import {
    createUser,
} from "../../redux/Actions.js";
import EmailInput from '../elements/EmailInput.js';
import SubmitButton from "../elements/SubmitButton.js";
import TextInput from "../elements/TextInput.js";
import { updateUser } from "../../redux/user/Action.js";
import { isNullOrUndefinedOrEmpty } from "../../utils.js";
import Loading from '../elements/Loading.js';


export const MyAccount = ({
    onCreate,
    onUpdate,
    ...userAccount
}) => {
    const dispatch = useDispatch();

    const [formState, setFormState] = useState({
        id: "",
        password: "",
        confirm_password: "",
        first_name: "",
        last_name: "",
        email: "",
        username: "",
    });

    const errorDefaults = {
        id: "",
        password: "",
        confirm_password: "",
        first_name: "",
        last_name: "",
        email: "",
        username: "",
    };
    const [errors, setErrors] = useState(errorDefaults);

    useEffect(() => {
        setFormState({ ...userAccount });
    }, [userAccount.id]);

    if (isNullOrUndefinedOrEmpty(userAccount)) {
        return <Loading />
    }

    return (
        <>
            <form className={"form"}>
                <input name="id" hidden defaultValue={formState.id} />
                <div className="form-row">
                    <EmailInput
                        name="email"
                        label="Email Address"
                        formState={formState}
                        errors={errors}
                        setErrors={setErrors}
                        setFormState={setFormState}
                        disabled={true}
                    />
                    <TextInput
                        name="first_name"
                        label="First Name"
                        formState={formState}
                        errors={errors}
                        setErrors={setErrors}
                        setFormState={setFormState}
                    />
                    <TextInput
                        name="last_name"
                        label="Last Name"
                        formState={formState}
                        errors={errors}
                        setErrors={setErrors}
                        setFormState={setFormState}
                    />
                </div>
                <div className="form-row">
                    {userAccount.id && <TextInput 
                        type="password" 
                        name="password" 
                        label="Password" 
                        formState={formState} 
                        errors={errors}
                        setErrors={setErrors}
                        setFormState={setFormState}
                    />}
                </div>
                <div className="form-row">
                    {userAccount.id && <TextInput 
                        type="password" 
                        name="confirm_password" 
                        label="Confirm Password" 
                        formState={formState} 
                        errors={errors}
                        setErrors={setErrors}
                        setFormState={setFormState}
                    />}
                </div>
                <div className="form-row submit">
                    <SubmitButton onSubmit={(event) => {
                        event.preventDefault();
                        if (formState.password !== formState.confirm_password) {
                            setErrors({ ...errors, confirm_password: "Passwords don't match." });
                            return;
                        } else {
                            const partial = {
                                id: userAccount.id,
                                first_name: formState.first_name,
                                last_name: formState.last_name,
                                password: formState.password,
                            };
                            if (partial.id) {
                                dispatch(updateUser(partial)).then((response) => {
                                    onUpdate(response);
                                    setFormState((state) => {
                                        return {...state, ...response};
                                    });
                                    setErrors(errorDefaults);
                                }).catch((errs) => {
                                    console.error(errs);
                                    setErrors({...errors, ...errs});
                                });
                            } else {
                                dispatch(createUser(partial)).then((response) => {
                                    onCreate(response);
                                    setFormState(response);
                                    setErrors(errorDefaults);
                                }).catch((errs) => {
                                    console.error(errs);
                                    setErrors({...errors, ...errs});
                                });
                            }
                    }}}>
                        {userAccount.id ? "Save" : "Create Account"}
                    </SubmitButton>
                </div>
            </form>
        </>
    );
};

export default MyAccount;
