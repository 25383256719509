import React, { useEffect, useState } from "react";
import { Container, Grid } from "@mui/material";
import { useNavigate } from "react-router-dom";
import DatabaseAccount from "../../../components/forms/DatabaseAccount.js";
import { useDispatch, useSelector } from "react-redux";
import { fetchDatabaseAccounts } from "../../../redux/models/integrations/Action.js";
import { ABORTED } from "../../../redux/constants.js";
import {isEmpty} from "weed-js"
import Loading from "../../../components/elements/Loading.js";


const Setup = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const [loading, setLoading] = useState(true);
    const selectedOrganization = useSelector(state => state.organizationReducer.selectedOrganization);
    const loggedInUser = useSelector(state => state.userReducer.loggedInUser.user);
    const [databaseAccounts, setDatabaseAccounts] = useState([]);

    useEffect(() => {
        const abortController = new AbortController();
        setLoading(true);
        dispatch(fetchDatabaseAccounts({}, abortController)).then((fetchedDatabaseAccounts) => {
            setLoading(false);
            setDatabaseAccounts(fetchedDatabaseAccounts);
        }).catch(err => {
            setLoading(false);
            if (err !== ABORTED) {
                console.error(err);
            }
        });
        return () => {
            abortController.abort()
        }
    }, [loggedInUser.id])

    if (loading) {
        return <Loading />
    }

    if (isEmpty(selectedOrganization)) {
        return <Container className={"workspace"}>
            <Grid container spacing={2}>
                <Grid item xs={6} className={"left-part"}>
                    You need to join an organization before you can set up a database.
                </Grid>
            </Grid>
        </Container>
    }

    return <>
        <Container className={"workspace"}>
            <Grid container spacing={2}>
                <Grid item xs={6} className={"left-part"}>
                    <Grid container spacing={3}>
                        <Grid item xs={12}>
                            <h1 className="catchline">Enter your Database account details</h1>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={6} className="right-part">
                    <Grid container spacing={2} className="database-form">
                        <Grid item xs={12}>
                            <DatabaseAccount
                                create={true}
                                onCreate={(newAccount) => {
                                    setDatabaseAccounts([...databaseAccounts, newAccount]);
                                    navigate(`/integrations/database/${newAccount.id}/detail`);
                                }}
                                onUpdate={(updatedAccount) => {
                                    setDatabaseAccounts(databaseAccounts.map((account) => {
                                        if (account.id === updatedAccount.id) {
                                            return updatedAccount;
                                        }
                                        return account;
                                    }));
                                }}
                                onDelete={(accountId) => {
                                    setDatabaseAccounts(databaseAccounts.filter((account) => {
                                        return account.id !== accountId;
                                    }));
                                    navigate("/integrations/database/list");
                                }}
                            />
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </Container>
    </>
}


export default Setup;
