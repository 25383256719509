import React, {useEffect, useState} from "react";
import {Autocomplete, Container, Grid, Tab, Tabs} from "@mui/material";
import {DatePicker} from "@mui/x-date-pickers/DatePicker/DatePicker.js";
import {LocalizationProvider} from "@mui/x-date-pickers/LocalizationProvider/LocalizationProvider.js";
import {AdapterDayjs} from "@mui/x-date-pickers/AdapterDayjs/AdapterDayjs.js";
import {TextField} from "@material-ui/core";
import dayjs from "dayjs";
import RetailCostAndMargin from "../../components/reports/home/RetailCostAndMargin.js";
import {accountIsStillBeingSetUp, formatDate} from "../../utils.js";
import {memberIsAdmin} from "../../utils/Roles.js";
import {showInformationalPopup} from "../../redux/notifications/Action.js";
import {selectOrganization} from "../../redux/models/organizations/Action.js";
import {useDispatch, useSelector} from "react-redux";
import Cultivation from "./verticals/cultivation/Cultivation.js";
import Manufacturing from "./verticals/manufacturing/Manufacturing.js";
import Retail from "./verticals/retail/Retail.js";
import Wholesale from "./verticals/wholesale/Wholesale.js";
import {
    RETAIL,
    WHOLESALE,
    CULTIVATION,
    MANUFACTURING,
    ROUTES_ANALYTICS_DETAIL_WHOLESALE,
    ROUTES_ANALYTICS,
    ROUTES_ANALYTICS_DETAIL_RETAIL,
    TABS, ROUTES, ORGANIZATION_ID_COOKIE
} from "./constants.js";

import "./style.scss";
import {isEmpty, getCookie} from "weed-js";
import {useNavigate, useParams, useSearchParams} from "react-router-dom";

export const Analytics = ({tab}) => {
    const [searchParams, setSearchParams] = useSearchParams();
    const organizations = useSelector(state => state.userReducer.loggedInUser.organizations);
    const currentUser = useSelector(state => state.userReducer.loggedInUser.user);
    const organizationUsers = useSelector(state => state.userReducer.loggedInUser.organizationUsers);
    const organizationOptions = organizations.map((org) => {
        return {"label": org.name, "value": org.id};
    })
    const selectedOrganization = useSelector(state => state.organizationReducer.selectedOrganization);

    const today = new Date();
    const firstOfCurrentMonth = new Date(today.getFullYear(), today.getMonth(), 1);
    const [endDate, setEndDate] = useState(dayjs(today));
    const [startDate, setStartDate] = useState(dayjs(firstOfCurrentMonth));
    const incompleteAccounts = useSelector(state => state.userReducer.loggedInUser.accountStatuses || []).filter((status) => {
        return accountIsStillBeingSetUp(status);
    });
    const {id: _organizationId} = useParams();
    const organizationId = parseInt(_organizationId) || getCookie(ORGANIZATION_ID_COOKIE)


    const whichTab = searchParams.get("tab");
    const [selectedTab, setSelectedTab] = useState(TABS[isEmpty(tab) ? whichTab : tab]);


    const dispatch = useDispatch();
    const navigate = useNavigate();

    const TabPanel = ({children, index}) => {
        if (index === selectedTab) {
            return <>{children}</>
        }
        return ""
    }

    useEffect(() => {
        if (isEmpty(selectedOrganization)) {
            return;
        }
        const organizationIds = [selectedOrganization.id];

        const params = {
            start_date: formatDate(startDate),
            end_date: formatDate(endDate),
            organization_ids: (organizationIds || []).join(","),
        };

    }, [selectedOrganization, startDate, endDate]);

    const selectTab = (event, newValue) => {
        setSelectedTab(newValue);
        navigate(ROUTES[parseInt(newValue)].replace(":id", organizationId));
    }

    const organizationChangeHandler = (event, selectedOption, reason, details) => {
        const stillSettingUp = incompleteAccounts.find((account) => {
            return account.organization_id === selectedOption.value;
        })
        const userIsAdmin = memberIsAdmin(currentUser, organizationUsers, selectedOrganization);
        if (!isEmpty(stillSettingUp)) {
            if (userIsAdmin) {
                dispatch(
                    showInformationalPopup(
                        "Account Setup Incomplete",
                        "You can't view reports until this organization's account is fully set up. Please complete the steps listed in the bottom left corner of the screen."
                    )
                );
            } else {
                dispatch(
                    showInformationalPopup(
                        "Account Setup Incomplete",
                        `You can't view reports for ${selectedOption.label} until this organization's account is fully set up. Talk to your account administrator to complete the setup.`
                    )
                );
            }
        }
        dispatch(selectOrganization(organizations.find(o => o.id === selectedOption.value)));
    };

    /*
    <Grid item xs={12}>
        <MetrcTransactionReport startDate={startDate} endDate={endDate}/>
    </Grid>

     */

    return (<Container className={"workspace dashboard"} id="analytics-main">
        <Grid container spacing={12}>
            <Grid item xs={12}>
                <Autocomplete
                    filterSelectedOptions
                    id="select-organizations"
                    name="select-organizations"
                    options={organizationOptions}
                    isOptionEqualToValue={(option, value) => {
                        return option.value === value.value
                    }}
                    onChange={organizationChangeHandler}
                    value={selectedOrganization ? {
                        label: selectedOrganization.name,
                        value: selectedOrganization.id
                    } : null}
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            label="Choose an organization to view sales reports"
                            placeholder="Choose an organization to view sales reports"
                        />
                    )}
                />
                <br/>
            </Grid>
            <Grid item xs={6}>
                <div className="control">
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DatePicker label="Start Date" value={dayjs(startDate)} onChange={(newDate) => {
                            setStartDate(dayjs(newDate))
                        }}/>
                    </LocalizationProvider>
                </div>
            </Grid>
            <Grid item xs={6}>
                <div className="control">
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DatePicker label="End Date" value={dayjs(endDate)} onChange={(newDate) => {
                            setEndDate(dayjs(newDate))
                        }}/>
                    </LocalizationProvider>
                </div>
            </Grid>
            <Grid item xs={12}>
                <Tabs value={selectedTab} onChange={selectTab}>
                    <Tab label={RETAIL} value={TABS[RETAIL]} tabIndex={TABS[RETAIL]} id={"retail-analytics-tab"}/>
                    <Tab disabled={true} label={CULTIVATION} value={TABS[CULTIVATION]} tabIndex={TABS[CULTIVATION]} id={"cultivation-analytics-tab"}/>
                    <Tab disabled={true} label={MANUFACTURING} value={TABS[MANUFACTURING]} tabIndex={TABS[MANUFACTURING]} id={"manufacturing-analytics-tab"}/>
                    <Tab label={WHOLESALE} value={TABS[WHOLESALE]} tabIndex={TABS[WHOLESALE]} id={"wholesale-analytics-tab"}/>
                </Tabs>
                <TabPanel index={TABS[RETAIL]}>
                    <Retail startDate={startDate} endDate={endDate}/>
                </TabPanel>
                <TabPanel index={TABS[CULTIVATION]}>
                    <Cultivation startDate={startDate} endDate={endDate}/>
                </TabPanel>
                <TabPanel index={TABS[MANUFACTURING]}>
                    <Manufacturing startDate={startDate} endDate={endDate}/>
                </TabPanel>
                <TabPanel index={TABS[WHOLESALE]}>
                    <Wholesale startDate={startDate} endDate={endDate}/>
                </TabPanel>
            </Grid>
        </Grid>
    </Container>);
}

export default Analytics;