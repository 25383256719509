import React, {useState} from 'react';
import {useDispatch, useSelector} from "react-redux";
import SubmitButton from "../elements/SubmitButton.js";
import {Link, useSearchParams} from "react-router-dom";
import TextInput from "../elements/TextInput.js";
import "../../views/styles/MainPage.css";
import {
    ROUTES_ORGANIZATIONS_LIST,
    ROUTES_INTEGRATIONS_METRC_SETUP
} from '../../utils/Constants.js';
import {Button, Dialog, DialogActions, DialogContent, DialogTitle} from "@mui/material";
import {AddCircleOutline as AddCircleOutlineIcon} from "@mui/icons-material";
import {useNavigate} from "react-router-dom";
import {hasPendingInvitations, setCookie} from '../../utils.js';
import {showInformationalPopup} from '../../redux/notifications/Action.js';
import {
    userLoggedIn,
    createOrganization,
    addAccountStatus,
    fetchOrganizationUsers,
    fetchOrganizationUserGroupsForUser,
} from "../../redux/user/Action.js";
import Loading from '../elements/Loading.js';

const CreateNewOrganization = ({
                                   displayText = "Create an organization",
                                   alreadyOpen = false
                               }) => {

    const [params] = useSearchParams();
    const navigate = useNavigate();

    const loggedInUser = useSelector(state => state.userReducer.loggedInUser.user);
    const organizations = useSelector(state => state.userReducer.loggedInUser.organizations);
    const organizationUsers = useSelector(state => state.userReducer.loggedInUser.organizationUsers);
    const organizationUserGroups = useSelector(state => state.userReducer.loggedInUser.organizationUserGroups);
    const accountStatuses = useSelector(state => state.userReducer.loggedInUser.accountStatuses);
    const loggedInUserIsLoading = useSelector(state => state.userReducer.loading);
    const dispatch = useDispatch();

    const [open, setOpen] = React.useState(alreadyOpen || params.get("open") === 1);
    const [organizationFormErrors, setOrganizationFormErrors] = useState({
        "name": "",
    })
    const [organizationFormState, setOrganizationFormState] = useState({
        "name": "",
    });

    const [organizationUserFormState, setOrganizationUserFormState] = useState({
        "user_id": loggedInUser.id,
        "organization_id": "",
        "role": "superadmin"
    });
    const [organizationUserFormErrors, setOrganizationUserFormErrors] = useState({
        "user_id": "",
        "organization_id": "",
        "role": "superadmin"
    });

    if (loggedInUserIsLoading) {
        return <Loading/>
    }

    const handleClickOpen = () => {
        setOpen(true);
    };
    const handleClose = () => {
        setOpen(false);
    };

    const pendingInvitatonMessage = hasPendingInvitations(organizationUsers) ?
        <p>NOTE: You have pending invitations to other organizations. You can accept them <Link
            to={ROUTES_ORGANIZATIONS_LIST}>here</Link>.</p> : null;

    return (
        <div className="create-organization-form">
            <Button onClick={handleClickOpen} className="secondary" id="create-organization-button">
                <AddCircleOutlineIcon className="pr-1"/>{displayText}
            </Button>
            <Dialog open={open} onClose={handleClose}>
                <DialogTitle>{displayText}</DialogTitle>
                <DialogContent>
                    <p>An organization is required to manage your integrations (e.g. metrc, dutchie). Just choose a name
                        and we can get started.</p>
                    {pendingInvitatonMessage}
                    <TextInput
                        name="name"
                        label="Enter your organization name."
                        formState={organizationFormState}
                        setFormState={setOrganizationFormState}
                        errors={organizationFormErrors}
                        setErrors={setOrganizationFormErrors}
                    />
                </DialogContent>
                <DialogActions>
                    <SubmitButton
                        id="create-organization-submit"
                        onSubmit={(event) => {
                            event.preventDefault();
                            dispatch(createOrganization(organizationFormState.name, loggedInUser)).then((createdOrganization) => {
                                setCookie('organizationId', createdOrganization.id);
                                dispatch(fetchOrganizationUsers(createdOrganization.id, loggedInUser.id)).then((fetchedOrganizationUsers) => {
                                    dispatch(fetchOrganizationUserGroupsForUser(loggedInUser)).then((fetchedOrganizationUserGroups) => {
                                        dispatch(userLoggedIn({
                                            user: loggedInUser,
                                            organizationUsers: fetchedOrganizationUsers,
                                            organizationUserGroups: fetchedOrganizationUserGroups,
                                            organizations: [...organizations, createdOrganization]
                                        }));
                                    });
                                });
                                if (accountStatuses.length === 0) {  // User's first organization (for which they are admin)!
                                    dispatch(addAccountStatus({
                                        organization_id: createdOrganization.id,
                                        has_integration: false,
                                    }));
                                    dispatch(showInformationalPopup(
                                        "You've created your first organization!",
                                        <><p>You can always check back in the bottom left of the page to see what you
                                            have left to do to get set up.</p><p>Next step is to add an integration.
                                            Just click "ok" and we'll get it going.</p></>,
                                        () => navigate(ROUTES_INTEGRATIONS_METRC_SETUP),
                                        () => navigate(ROUTES_INTEGRATIONS_METRC_SETUP),
                                        "acknowledge-first-organization"
                                    ));
                                }
                            }).catch((errs) => {
                                console.log("GOT ERRORS", errs);
                                if (errs.status === 403 || errs.status === 401) {
                                    setOrganizationFormErrors({
                                        ...organizationFormErrors,
                                        name: "An organization with this name already exists"
                                    })
                                } else {
                                    setOrganizationFormErrors({...organizationFormErrors, ...errs});
                                }
                            });
                        }
                        }>Create</SubmitButton>
                </DialogActions>
            </Dialog>
        </div>
    )
}

export default CreateNewOrganization;
