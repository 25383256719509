import { SELECT_ORGANIZATION } from "../../constants.js";

export const selectOrganization = (organization) => {
    return dispatch => {
        return dispatch({
            type: SELECT_ORGANIZATION,
            organization: organization
        });
    }
}


