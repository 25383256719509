import {USER_SCROLLED, SCROLL_EVENT_REGISTERED} from "../constants.js";

export const onScroll = (event) => {
    return dispatch => {
        const body = document.body.getBoundingClientRect();
        const viewableHeight = window.innerHeight;
        const lowerFold = body.top - viewableHeight;
        const upperFold = body.top;

        return dispatch({
            type: USER_SCROLLED,
            upperFold: upperFold,
            lowerFold: lowerFold
        });
    }
}


export const registerScrollEvent = () => {
    return dispatch => {
        return dispatch({
            type: SCROLL_EVENT_REGISTERED,
            callback: () => {
                return dispatch(onScroll())
            }
        });
    }
}