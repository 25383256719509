import {JSONPath} from "weed-js";

export const ORGANIZATION_ID_COOKIE = "organizationId";
export const STATE_PATH = new JSONPath("$.views.Analytics");

export const OBSERVER_CHART_STATE_PATH = STATE_PATH.concat("$.observerCharts");

export const ALPINEIQ = "AlpineIQ";
export const WHOLESALE = "wholesale";
export const RETAIL = "retail";
export const RETAIL_PROCUREMENT = "retail procurement";
export const RETAIL_VENDORS = "retail vendors";
export const RETAIL_EMPLOYEES = "retail employees";
export const RETAIL_COST_AND_MARGIN = "retail costs and margin";
export const RETAIL_PROMOTIONS = "retail promotions";
export const RETAIL_RISK = "retail risk";



export const CULTIVATION = "cultivation";
export const MANUFACTURING = "manufacturing";

// Advanced Analytics Dashboard Routes
export const ROUTES_ANALYTICS = "/analytics";
export const ROUTES_ANALYTICS_DETAIL_WHOLESALE= `/analytics/organizations/:id/${WHOLESALE}`;
export const ROUTES_ANALYTICS_DETAIL_RETAIL= `/analytics/organizations/:id/${RETAIL}`;
export const ROUTES_ANALYTICS_DETAIL_RETAIL_PROCUREMENT= `/analytics/organizations/:id/${RETAIL_PROCUREMENT}`;
export const ROUTES_ANALYTICS_DETAIL_RETAIL_VENDORS= `/analytics/organizations/:id/${RETAIL_VENDORS}`;
export const ROUTES_ANALYTICS_DETAIL_RETAIL_EMPLOYEES= `/analytics/organizations/:id/${RETAIL_EMPLOYEES}`;
export const ROUTES_ANALYTICS_DETAIL_RETAIL_COST_AND_MARGIN= `/analytics/organizations/:id/${RETAIL_COST_AND_MARGIN}`;
export const ROUTES_ANALYTICS_DETAIL_RETAIL_PROMOTIONS= `/analytics/organizations/:id/${RETAIL_PROMOTIONS}`;
export const ROUTES_ANALYTICS_DETAIL_RETAIL_RISK= `/analytics/organizations/:id/${RETAIL_RISK}`;
export const ROUTES_ANALYTICS_DETAIL_MANUFACTURING = `/analytics/organizations/:id/${MANUFACTURING}`;
export const ROUTES_ANALYTICS_DETAIL_CULTIVATION = `/analytics/organizations/:id/${CULTIVATION}`;

export const TABS = {
    null: 2,
    undefined: 2,
    [WHOLESALE]: 1,
    [RETAIL]: 2,
    [CULTIVATION]: 3,
    [MANUFACTURING]: 4,
}

export const RETAIL_TABS = {
    null:  5,
    undefined: 5,
    [ALPINEIQ]: 1,
    [RETAIL_PROCUREMENT]: 2,
    [RETAIL_VENDORS]: 3,
    [RETAIL_EMPLOYEES]: 4,
    [RETAIL_COST_AND_MARGIN]: 5,
    [RETAIL_PROMOTIONS]: 6,
    [RETAIL_RISK]: 7,
}

export const ROUTES = {
    0: ROUTES_ANALYTICS,
    1: ROUTES_ANALYTICS_DETAIL_WHOLESALE,
    2: ROUTES_ANALYTICS_DETAIL_RETAIL,
    3: ROUTES_ANALYTICS_DETAIL_CULTIVATION,
    4: ROUTES_ANALYTICS_DETAIL_MANUFACTURING,
}

export const GROUP_BY = "group by";
export const FILTER_BY = "filter by";
export const FULL_SCREEN = "full screen";
