import React, { useState } from "react";
import ReactTable from "react-table-v6";
import { numberFormatter, isNullOrUndefinedOrEmpty } from "../../../utils.js";
import {isEmpty} from "weed-js";


const LatestCustomerPurchases = ({ purchaseReport }) => {

    const [sortOptions, setSortOptions] = useState([{ id: "duration", desc: false }])
    const title = <h3>Latest Customer Purchases</h3>;

    const purchaseReportHeaders = [
        {
            Header: "Customer",
            accessor: "customer",
        },
        {
            Header: "Sales Rep",
            accessor: "sales_rep",
        },
        {
            id: "last_purchase",
            Header: "Last Purchase",
            accessor: (row) => {
                return new Date(row.last_purchase).toLocaleDateString();
            }
        },
        {
            id: "duration",
            Header: "Elapsed Time",
            accessor: (row) => {
                return `${numberFormatter.format(Math.round(((new Date()) - (new Date(row.last_purchase))) / (1000 * 60 * 60 * 24), 0))} days`;
            }
        }
    ];


    if (isEmpty(purchaseReport) || purchaseReport.detail) {
        return "";
    } else {
        console.log("Latest customer purchases:", purchaseReport);
    }

    return <div>
        {title}
        <ReactTable
            sorted={sortOptions}
            onSortedChange={(newSorted, column, shiftKey) => {
                setSortOptions(newSorted);
            }}
            showPaginationBottom={false}
            getProps={() => { return { id: "sales-report-table" } }}
            columns={purchaseReportHeaders}
            pageSize={purchaseReport.length}
            className="-striped -highlight mb-3"
            data={purchaseReport}
        />
    </div>
}

export default LatestCustomerPurchases;
