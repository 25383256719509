import { SET_METRC_ACCOUNTS, SET_METRC_BACKFILLS } from "../../constants.js";
import { ALPINEIQ_DAL_URL } from "../../../utils/Constants.js";
import { destroyFromDal, enqueueSnackbar, getFromApi, getFromDal, patchToDal, postToApi, postToDal } from "../../Actions.js";
import {getCookie, isEmpty} from "weed-js";

// Metrc
export const setMetrcAccounts = (integrations) => {
    return dispatch => {
        return dispatch({
            type: SET_METRC_ACCOUNTS,
            accounts: integrations
        });
    }
}

export const createMetrcAccount = (metrcAccount) => {
    return dispatch => dispatch(postToDal("/metrc-accounts/", metrcAccount, "Metrc account created!"));
};

export const deleteMetrcAccount = (pk) => {
    return dispatch => dispatch(destroyFromDal("/metrc-accounts/", pk));
};

export const fetchMetrcAccounts = (params = {}, abortController = undefined) => {
    return dispatch => dispatch(getFromDal("/metrc-accounts/", params, abortController)).then((response) => {
        return response;
    });
};

export const createDatabaseAccount = (database) => {
    return dispatch => dispatch(postToDal("/databases/", database, "Database account created!"));
};

export const deleteDatabaseAccount = (pk) => {
    return dispatch => dispatch(destroyFromDal("/databases/", pk));
};

export const fetchDatabaseAccounts = (params = {}, abortController = undefined) => {
    return dispatch => dispatch(getFromDal("/databases/", params, abortController)).then((response) => {
        return response;
    });
};

export const updateDatabaseAccount = (partial) => {
    return dispatch => {
        const databaseAccountId = partial.id;
        return dispatch(patchToDal(`/databases/${databaseAccountId}/`, partial, 'Database account updated.')).catch(err => {
            dispatch(enqueueSnackbar({
                message: "Could not update database account.",
                options: {
                    variant: "error",
                },
            }));
            return Promise.reject(err);
        });
    }
}

export const fetchMetrcBackfills = (params, abortController) => {
    return dispatch => dispatch(getFromApi("/metrc-backfills/", params, abortController)).then((response) => {
        return response;
    });
}
export const kickoffBackfills = (payload, abortController) => {
    return dispatch => dispatch(postToApi("/metrc-backfills/", payload, "Backfill started! Check back here to keep up with the progress.", abortController));
}

export const kickoffMigrations= (databaseId, abortController) => {
    return dispatch => dispatch(postToApi(`/databases/${databaseId}/migrate/`, {}, "Migrations started! They should be completed shortly.", abortController));
}

export const setMetrcBackfills = (backfills) => {
    return dispatch => {
        return dispatch({
            type: SET_METRC_BACKFILLS,
            backfills: backfills
        })
    }
}

export const updateMetrcAccount = (partial) => {
    return dispatch => {
        const metrcAccountId = partial.id;
        return dispatch(patchToDal(`/metrc-accounts/${metrcAccountId}/`, partial, 'Metrc account updated.')).catch(err => {
            dispatch(enqueueSnackbar({
                message: "Could not update metrc account.",
                options: {
                    variant: "error",
                },
            }));
            return Promise.reject(err);
        });
    }
}


// Leaflogix
export const createLeaflogixAccount = (leaflogixAccount) => {
    return dispatch => dispatch(postToDal("/leaflogix-accounts/", leaflogixAccount));
};
export const deleteLeaflogixAccount = (pk) => {
    return dispatch => dispatch(destroyFromDal("/leaflogix-accounts/", pk));
};
export const fetchLeaflogixAccounts = (params) => {
    return dispatch => dispatch(getFromDal("/leaflogix-accounts/", params)).then((response) => {
        return response;
    });
};
export const updateLeaflogixAccount = (partial) => {
    return dispatch => {
        const leaflogixAccountId = partial.id;
        return dispatch(patchToDal(`/leaflogix-accounts/${leaflogixAccountId}/`, partial, 'Leaflogix account updated.')).catch(err => {
            dispatch(enqueueSnackbar({
                message: "Could not update leaflogix account.",
                options: {
                    variant: "error",
                },
            }));
            return Promise.reject(err);
        });
    }
}

// AlpineIQ
export const createAlpineIQAccount = (alpineiqAccount) => {
    return dispatch => dispatch(postToDal(ALPINEIQ_DAL_URL, alpineiqAccount));
};
export const updateAlpineIQAccount = (partial) => {
    return dispatch => {
        const alpineIQAccountId = partial.id;
        return dispatch(patchToDal(`${ALPINEIQ_DAL_URL}${alpineIQAccountId}/`, partial, 'AlpineIQ account updated.')).catch(err => {
            dispatch(enqueueSnackbar({
                message: "Could not update AlpineIQ account.",
                options: {
                    variant: "error",
                },
            }));
            return Promise.reject(err);
        });
    }
};
export const deleteAlpineIQAccount = (pk) => {
    return dispatch => dispatch(destroyFromDal(ALPINEIQ_DAL_URL, pk));
};
export const fetchAlpineIQAccounts = (params) => {
    return dispatch => dispatch(getFromDal(ALPINEIQ_DAL_URL, params)).then((response) => {
        return response;
    });
};

export const fetchAccountStatuses = (abortController) => {
    const organizationId = getCookie("organizationId");
    const params = isEmpty(organizationId) ? {} : { organization_id: organizationId };
    return dispatch => dispatch(getFromApi("/users/setup-steps/", params, abortController)).then(response => {
        return response;
    }).catch(err => {
        console.error(err);
        return [];
    });
}

// IdScan
export const createIDScanAccount = (idScanAccount) => {
    return dispatch => dispatch(postToDal("/idscan-accounts/", idScanAccount));
};
export const deleteIDScanAccount = (pk) => {
    return dispatch => dispatch(destroyFromDal("/idscan-accounts/", pk));
};
export const fetchIDScanAccounts = (params) => {
    return dispatch => dispatch(getFromDal("/idscan-accounts/", params)).then((response) => {
        return response;
    });
};
export const updateIDScanAccount = (partial) => {
    return dispatch => {
        const idScanAccountId = partial.id;
        return dispatch(patchToDal(`/idscan-accounts/${idScanAccountId}/`, partial, 'IdScan account updated.')).catch(err => {
            dispatch(enqueueSnackbar({
                message: "Could not update IdScan account.",
                options: {
                    variant: "error",
                },
            }));
            return Promise.reject(err);
        });
    }
}