import React, { useState } from 'react';
import { useDispatch } from "react-redux";
import { enqueueSnackbar, resetPassword } from "../../redux/Actions.js";
import SubmitButton from "../elements/SubmitButton.js";
import TextInput from "../elements/TextInput.js";


const ResetPassword = () => {
    const search = window.location.search;
    const params = new URLSearchParams(search);
    const token = params.get('token');

    const [formState, setFormState] = useState({
        password: "",
        verify_password: ""
    });
    const [errors, setErrors] = useState({
        password: "",
        verify_password: ""
    })

    const [success, setSuccess] = useState(false);
    const dispatch = useDispatch();

    if (success) {
        return <div>
            Password Reset Successfully.
        </div>
    }

    return <form className="form">
        <div className="form-row">
            <TextInput
                type="password"
                name="password"
                label="New Password"
                formState={formState}
                errors={errors}
                setFormState={setFormState}
                setErrors={setErrors} />
        </div>
        <div className="form-row">
            <TextInput
                type="password"
                name="verify_password"
                label="Verify Password"
                formState={formState}
                errors={errors}
                setFormState={setFormState} />
        </div>
        <div className="form-row submit">
            <SubmitButton onSubmit={(click) => {
                click.preventDefault();
                if (formState.password !== formState.verify_password) {
                    dispatch(enqueueSnackbar({
                        message: "Password Fields Don't Match",
                        options: {
                            variant: "error",
                        },
                    }));
                } else {
                    dispatch(resetPassword(formState.password, token)).then((user) => {
                        setSuccess(true);
                    }).catch((errs) => {
                        dispatch(enqueueSnackbar({
                            message: "Password Reset Failed",
                            options: {
                                variant: "error",
                            },
                        }));
                        setErrors({ ...errors, ...errs });
                    });
                }
            }}>Submit</SubmitButton>
        </div>
    </form>
}

export default ResetPassword;
