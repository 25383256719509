export class BinaryOperation {
    constructor(first, second) {
        this.first = first;
        this.second = second;
    }

    _shouldRun(item) {
        if (item instanceof BinaryOperation) {
            return true;
        } else if (item instanceof F) {
            return true;
        }
        return false;
    }

    _preprocess(item) {
        return [
            this._shouldRun(this.first) ? this.first.run(item) : this.first,
            this._shouldRun(this.second) ? this.second.run(item) : this.second
        ]
    }

    run(item) {
        throw new Error("Not implemented")
    }
}

export class Add extends BinaryOperation {
    run(item) {
        const [first, second] = this._preprocess(item);
        return first + second;
    }
}

export class Subtract extends BinaryOperation {
    run(item) {
        const [first, second] = this._preprocess(item);
        return first - second;
    }
}

export class Multiply extends BinaryOperation {
    run(item) {
        const [first, second] = this._preprocess(item);
        return first * second;
    }
}

export class Divide extends BinaryOperation {
    run(item) {
        const [first, second] = this._preprocess(item);
        return first / second;
    }
}

export class Aggregate {

    constructor(computeIncrement) {
        /*
        Takes a Calculation. This will be run when a new datapoint is added to the aggregate.

         */
        this.computeIncrement = computeIncrement;
        this.state = this.getInitialState();
    }

    getInitialState() {
        return {}
    }

    run(item) {
        this.state = this.update(item);
    }

    update(point) {
        throw new Error("Not implemented");
    }

    observe() {
        throw new Error("Not implemented");
    }
}


export class Average extends Aggregate {

    getInitialState() {
        return [0., 0.];
    }

    update(point) {
        const [total, count] = this.state;
        const increment = this.computeIncrement.run(point)
        return [total + increment, count + 1];
    }

    observe() {
        if (this.state[1] === 0) {
            return 0;
        }
        return this.state[0] / this.state[1];
    }
}

export class Sum extends Aggregate {

    getInitialState() {
        return [0.];
    }

    update(point) {
        const [total] = this.state;
        const increment = this.computeIncrement.run(point)
        return [total + increment];
    }

    observe() {
        return this.state[0];
    }
}

export class Derivative {
    constructor(x1, y1, x2, y2) {
        this.x1 = x1;
        this.y1 = y1;
        this.x2 = x2;
        this.y2 = y2;
    }

    run(item) {
        const x1 = this.x1 instanceof BinaryOperation ? this.x1.run(item) : this.x1;
        const y1 = this.y1 instanceof BinaryOperation ? this.y1.run(item) : this.y1;
        const x2 = this.x2 instanceof BinaryOperation ? this.x2.run(item) : this.x2;
        const y2 = this.y2 instanceof BinaryOperation ? this.y2.run(item) : this.y2;
        return (y2 - y1) / (x2 - x1);
    }
}

export class F {
    constructor(attribute) {
        this.attribute = attribute;
    }

    run(item) {
        return item[this.attribute] ? parseFloat(item[this.attribute]) : 0;
    }
}

export class Calculation {

    constructor(name, formula, aggregator) {
        this.name = name
        this.formula = formula;
        this.aggregator = aggregator;
    }

    run(point) {
        return this.formula.run(point)
    }

    getAggregate() {
        return new this.aggregator(this.formula);
    }
}