import {
    SELECT_ORGANIZATION
} from "../../constants.js";
import {setCookie, isEmpty} from "weed-js";

const defaultState = {
    selectedOrganization: null
};

const OrganizationReducer = (state = defaultState, action) => {
    switch (action.type) {
        case SELECT_ORGANIZATION:
            if (isEmpty(action.organization)) {
                return state;
            }
            setCookie("organizationId", action.organization.id);
            return {
                ...state,
                selectedOrganization: action.organization
            };
        default:
            return state;
    }
};


export default OrganizationReducer;
